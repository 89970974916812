import {
  CHANGE_MODAL,
  HIDE_MODAL,
  EDIT_CONFIG,
  EDIT_STATE,
  EDIT_PROPERTY,
  EDIT_COUNTRY,
  EDIT_CITY,
  EDIT_COUNTRY_INDEX,
  EDIT_PROFILE_ACCESS_KEYS
} from '../constants/action-types'

const initialState = {
  currentModal: 'loginMenuShow', // loginMenuShow
  editProfileId: '',
  editConfig: {},
  editState: {},
  editProperty: {},
  editCity: {},
  editCountry: {},
  editCountryIndex: {},
  simulatorGroup: null
}

function manageModal(state = initialState, action) {
  if (action.type === CHANGE_MODAL) {
    return {
      ...state,
      currentModal: action.payload.currentModal,
      simulatorGroup: action.payload.simulatorGroup
    }
  }
  if (action.type === EDIT_CONFIG) {
    return {
      ...state,
      editConfig: action.payload.editConfig
    }
  }
  if (action.type === EDIT_STATE) {
    return {
      ...state,
      editState: action.payload.editState
    }
  }
  if (action.type === EDIT_PROPERTY) {
    return {
      ...state,
      editProperty: action.payload.editProperty
    }
  }
  if (action.type === EDIT_COUNTRY) {
    return {
      ...state,
      editCountry: action.payload.editCountry
    }
  }
  if (action.type === EDIT_COUNTRY_INDEX) {
    return {
      ...state,
      editCountryIndex: action.payload.editCountryIndex
    }
  }
  if (action.type === EDIT_CITY) {
    return {
      ...state,
      editCity: action.payload.editCity
    }
  }
  if (action.type === EDIT_PROFILE_ACCESS_KEYS) {
    return {
      ...state,
      editProfileId: action.payload.editProfileId
    }
  }
  if (action.type === HIDE_MODAL) {
    return {
      ...initialState,
      currentModal: ''
    }
  }

  return state
}

export default manageModal
