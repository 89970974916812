import { FILTER_COLUMN, FILTER_QUERY } from "../constants/action-types";

const initialState = {
    query: '',
    filterColumn: 'Config Key'
};

function filterData (state = initialState, action) {
    if (action.type === FILTER_COLUMN) {
        return {
            ...state,
            filterColumn: action.payload
        };
    }
    if (action.type === FILTER_QUERY) {
        return {
            ...state,
            query: action.payload
        };
    }
    return state;
};

export default filterData;
