import {
  LOGIN_ATTEMPT,
  CONFIRM_ADMIN,
  LOG_OFF,
  DATABASE_LOADED,
} from "../constants/action-types"

const initialState = {
  database: {},
  successfulLogin: false,
  PROFILE_ID: -1,
  isAdmin: false,
  USER: {},
  SCOPE_ACCESS: "",
  STATE_ACCESS: "",
  PROPERTIES_ACCESS: "",
  REGION_ACCESS: "",
}

function accountLogin(state = initialState, action) {
  if (action.type === LOGIN_ATTEMPT) {
    let accessKeys = action.payload.USER_ID.TABLE_ACCESS
    let STATE_ACCESS = ""
    let PROPERTIES_ACCESS = ""
    let REGION_ACCESS = ""
    let SCOPE_ACCESS = ""
    if (!!accessKeys) {
      accessKeys = JSON.parse(accessKeys)
      SCOPE_ACCESS = accessKeys.SCOPE_ACCESS
      STATE_ACCESS = accessKeys.STATE_ACCESS
      PROPERTIES_ACCESS = accessKeys.PROPERTIES_ACCESS
      REGION_ACCESS = accessKeys.REGION_ACCESS
    }
    return {
      ...state,
      successfulLogin: true,
      PROFILE_ID: action.payload.PROFILE_ID,
      USER: action.payload.USER_ID,
      SCOPE_ACCESS: SCOPE_ACCESS,
      STATE_ACCESS: STATE_ACCESS,
      PROPERTIES_ACCESS: PROPERTIES_ACCESS,
      REGION_ACCESS: REGION_ACCESS,
    }
  }
  if (action.type === CONFIRM_ADMIN) {
    return {
      ...state,
      isAdmin: true,
    }
  }
  if (action.type === DATABASE_LOADED) {
    return {
      ...state,
      database: {
        database: action.payload.database,
        sql: action.payload.sql,
        hostname: action.payload.hostname,
      },
    }
  }
  if (action.type === LOG_OFF) {
    return initialState
  }
  return state
}

export default accountLogin
