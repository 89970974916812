import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postConfigValue } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class ConfigurationMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      CONFIG_KEY: '',
      SCOPE_NAME: '',
      VALUE: '',
      GROUP_ID: '',
      COMMENTS: '',
      PROFILE_ID: ''
    }
  }

  handleConfigKey = event => {
    const configKey = event.target.value
    this.setState({
      CONFIG_KEY: configKey,
      PROFILE_ID: this.props.PROFILE_ID,
      isAdmin: this.props.isAdmin
    })
  }

  handleScope = event => {
    const scope = Array.from(event.target.selectedOptions, option => option.value)
    console.log(scope)
    this.setState({
      SCOPE_NAME: scope
    })
  }

  handleValue = event => {
    const value = event.target.value
    this.setState({
      VALUE: value
    })
  }

  handleGroup = event => {
    const groupId = event.target.value
    this.setState({
      GROUP_ID: groupId
    })
  }

  handleDescription = event => {
    const description = event.target.value
    this.setState({
      COMMENTS: description
    })
  }

  addConfig = () => {
    console.log(this.state)
    this.props.postConfigValue({ ...this.state })
    console.log('CONFIG ADDED')
    this.props.closeModal()
  }

  configExists = () => {
    for (let config of this.props.configs) {
      if (
        config.CONFIG_KEY === this.state.CONFIG_KEY &&
        this.state.SCOPE_NAME.includes(config.SCOPE_NAME) &&
        config.GROUP_ID.toString() === this.state.GROUP_ID
      )
        return true
    }
    return false
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="success" onClick={this.addConfig} disabled>
          Add
        </Button>
      </Modal.Footer>
    )
    if (
      this.state.CONFIG_KEY.length > 0 &&
      this.state.SCOPE_NAME.length > 0 &&
      this.state.GROUP_ID.length > 0 &&
      this.state.VALUE.length > 0 &&
      !this.configExists()
    ) {
      footer = (
        <Modal.Footer>
          <Button variant="success" onClick={this.addConfig}>
            Add
          </Button>
        </Modal.Footer>
      )
    }
    const {PROFILE_ID, closeModal, postConfigValue, isAdmin, ...props} = this.props

    return (
      <Modal {...props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextConfigKey">
              <Form.Label column sm="2">
                Config Key<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="10">
                <Form.Control type="CONFIG_KEY" onChange={this.handleConfigKey} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScopes">
              <Form.Label column sm="2">
                Scopes<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="10">
                <Form.Control as="select" multiple onChange={this.handleScope}>
                  {this.props.scopes.map((scope, index) => {
                    return <option key={this.props.database + index}>{scope.SCOPE_NAME}</option>
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextValue">
              <Form.Label column sm="2">
                Value<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="10">
                <Form.Control as="textarea" rows="3" onChange={this.handleValue} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextGroup">
              <Form.Label column sm="2">
                {' '}
                Group<p className="asterisk"> * </p>:{' '}
              </Form.Label>
              <Col sm="10">
                <Form.Control as="select" onChange={this.handleGroup}>
                  <option key="null" value=""></option>
                  {this.props.groups.map(group => {
                    return (
                      <option key={this.props.database + group.GROUP_ID} value={group.GROUP_ID}>
                        {group.GROUP_NAME}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextDescription">
              <Form.Label column sm="2">
                {' '}
                Description:{' '}
              </Form.Label>
              <Col sm="10">
                <Form.Control as="textarea" rows="3" onChange={this.handleDescription} />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.configs,
    groups: state.loadData.groups,
    scopes: state.loadData.scopes,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postConfigValue, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationMenu)
