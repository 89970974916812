import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postStateDesc } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'
import { by639_1 } from 'iso-language-codes'

class StateDefMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      STATE_ID: '',
      LANGUAGE_CODE: '',
      SHORT_DESC: '',
      KAC_MSG: '',
      STAMPING_MSG: '',
      SCREEN_MSG: ''
    }
    this.baseState = this.state
    this.handleStateId = this.handleStateId.bind(this)
    this.handleLangCode = this.handleLangCode.bind(this)
    this.handleShortDesc = this.handleShortDesc.bind(this)
    this.handleKacMsg = this.handleKacMsg.bind(this)
    this.handleStampingMsg = this.handleStampingMsg.bind(this)
    this.handleScreenMsg = this.handleScreenMsg.bind(this)
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleStateId = event => {
    let value = this.state.STATE_ID
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        STATE_ID: value.toUpperCase()
      })
    }
  }

  handleLangCode = event => {
    const value = event.target.value.toUpperCase()
    console.log(value)
    this.setState({
      LANGUAGE_CODE: value
    })
  }

  handleShortDesc = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      SHORT_DESC: value
    })
  }

  handleKacMsg = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      KAC_MSG: value
    })
  }

  handleStampingMsg = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      STAMPING_MSG: value
    })
  }

  handleScreenMsg = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      SCREEN_MSG: value
    })
  }

  addConfig = () => {
    let counter = 1
    const sortedStateDesc = this.props.stateDesc.sort((a, b) => a.state_desc_id - b.state_desc_id)
    console.log(sortedStateDesc)
    for (let id of sortedStateDesc) {
      console.log(id.state_desc_id, counter)
      if (id.state_desc_id === counter) {
        counter++
      } else {
        break
      }
    }
    const req = { ...this.state, state_desc_id: counter }
    console.log(req)
    this.props.postStateDesc(req)
    console.log('STATE ADDED')
    this.resetForm()
    this.props.closeModal()
  }

  configExists = () => {
    let stateDescExists = []
    for (let configDef of this.props.configs) {
      stateDescExists = this.props.stateDesc.filter(e => e.STATE_ID === configDef.STATE_ID)
      if (stateDescExists.length > 0) {
        for (let configDesc of stateDescExists) {
          if (configDesc.LANGUAGE_CODE !== this.state.LANGUAGE_CODE && configDef.STATE_ID === this.state.STATE_ID) {
            return true
          }
        }
      } else {
        if (configDef.STATE_ID === this.state.STATE_ID) {
          return true
        }
      }
    }
    return false
  }

  getUniqueList = category => {
    let list = []
    if (category === 'LANGUAGE_CODE') {
      list = Object.keys(by639_1).map(elem => {
        return elem.toUpperCase()
      })
    } else if (category === 'STATE_ID') {
      list = this.props.configs.map(elem => {
        return elem.STATE_ID
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="success" onClick={this.addConfig} disabled>
          Add
        </Button>
      </Modal.Footer>
    )
    if (this.state.STATE_ID.length > 0 && this.state.LANGUAGE_CODE.length > 0 && this.configExists()) {
      footer = (
        <Modal.Footer>
          <Button variant="success" onClick={this.addConfig}>
            Add
          </Button>
        </Modal.Footer>
      )
    }
    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextConfigKey">
              <Form.Label column sm="4">
                State ID<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('STATE_ID')}
                  type="STATE_ID"
                  id="STATE_ID"
                  value={this.state.STATE_ID}
                  onChange={this.handleStateId}
                  onInputChange={this.handleStateId}
                  highlightOnlyResult
                  selectHintOnEnter
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Language Code<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control as="select" onChange={this.handleLangCode} value={this.state.LANGUAGE_CODE}>
                  <option key="defaultValueLangCodeCity" value=""></option>
                  {this.getUniqueList('LANGUAGE_CODE').map(e => {
                    return (
                      <option key={this.props.database + e} value={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Short Description:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="SHORT_DESC" value={this.state.SHORT_DESC} onChange={this.handleShortDesc} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                KAC Message:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="KAC_MSG" value={this.state.KAC_MSG} onChange={this.handleKacMsg} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Stamping Message:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="STAMPING_MSG" value={this.state.STAMPING_MSG} onChange={this.handleStampingMsg} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Screen Message:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="SCREEN_MSG"
                  as="textarea"
                  rows="3"
                  value={this.state.SCREEN_MSG}
                  onChange={this.handleScreenMsg}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.stateDef,
    stateDesc: state.loadData.stateDesc,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postStateDesc, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StateDefMenu)
