function authentication() {
    const serverURL = window.config.serverURL

    const signIn = payload => {
        return fetch(serverURL+'/auth/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(async data => {
            await removeToken()
            await setToken(data.token)
            return data
        }).catch(err => {
            console.log(err)
            // throw err
            return (err)
            // props.setError({type: 'Server Connection', message: err, isFatal: true, errno: '0', code: 'ERROR_SIGNING_IN'})
        })
    }

    const removeToken = async () => {
        sessionStorage.removeItem('jwtToken')
    }
    const setToken = async token => {
        await sessionStorage.setItem('jwtToken', token)
    }

    const getToken = () => {
        return sessionStorage.getItem('jwtToken')
    }

    return {
        signIn,
        token: getToken(),
        removeToken
    }
}

export default authentication
