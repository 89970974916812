import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { postCity, saveCity, deleteCity } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { by639_1 } from 'iso-language-codes'
class CityMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      CITY_CODE: '',
      CITY_NAME: '',
      COUNTRY_CODE2: '',
      TIME_ZONE: '',
      DAYLIGHT_SAVING: false,
      DAYLIGHT_SAVING_MINUTES: '',
      LANGUAGE_CODE: '',
      cityCodeExists: false,
      cityNameList: [],
      error: null
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState({ ...this.baseState, cityNameList: [] })
  }

  handleCityCode = event => {
    let value = this.state.CITY_CODE
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase().substr(0, 3)
      console.log(value)

      let cityList = this.props.cities.filter(e => {
        return e.CITY_CODE === value
      })
      if (cityList.length > 0) {
        this.setState({
          ...cityList.shift(),
          cityCodeExists: true,
          cityNameList: cityList
        })
      } else {
        let baseState = this.state
        if (this.state.cityCodeExists) {
          baseState = this.baseState
        }
        this.setState({
          ...baseState,
          CITY_CODE: value,
          cityCodeExists: false
        })
      }
    }
  }

  handleCityName = event => {
    let value = event.target.value
    console.log(value)
    this.setState({
      CITY_NAME: value
    })
  }

  handleCountryCode2 = event => {
    let value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_CODE2: value.toUpperCase()
    })
  }

  handleTimeZone = event => {
    let value = this.state.TIME_ZONE
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        TIME_ZONE: value
      })
    }
  }

  handleDaylightSaving = () => {
    console.log(!this.state.DAYLIGHT_SAVING)
    this.setState({
      DAYLIGHT_SAVING: !this.state.DAYLIGHT_SAVING
    })
  }

  handleDaylightSavingMin = event => {
    const re = /^[0-9\b]+$/
    let value = this.state.DAYLIGHT_SAVING_MINUTES
    if (event.target.value === '' || re.test(event.target.value)) value = event.target.value
    console.log(value)
    this.setState({
      DAYLIGHT_SAVING_MINUTES: value
    })
  }

  handleLangCode = event => {
    const value = event.target.value.toUpperCase()
    console.log(value)
    this.setState({
      LANGUAGE_CODE: value,
      error: null
    })
  }

  handleCityNameRow = data => {
    console.log(data)
    let cityNameList = this.state.cityNameList
    if (data === '+') {
      cityNameList.push({ CITY_NAME: '', LANGUAGE_CODE: '' })
    } else {
      cityNameList.splice(data, 1)
    }
    console.log(cityNameList)
    this.setState({
      cityNameList: cityNameList
    })
  }

  handleCityNameList = (event, i) => {
    console.log(event.target.value, i)
    let cityNameList = this.state.cityNameList
    let editCityName = { CITY_NAME: event.target.value, LANGUAGE_CODE: cityNameList[i].LANGUAGE_CODE }
    cityNameList[i] = editCityName
    this.setState({
      cityNameList: cityNameList
    })
  }

  handleCityLangList = (event, i) => {
    console.log(event.target.value, i)
    let cityNameList = this.state.cityNameList
    let editCityName = { CITY_NAME: cityNameList[i].CITY_NAME, LANGUAGE_CODE: event.target.value }
    cityNameList[i] = editCityName
    this.setState({
      cityNameList: cityNameList,
      error: null
    })
  }

  getUniqueList = category => {
    let list = []
    if (category === 'CITY_CODE') {
      list = this.props.cities.map(elem => {
        return elem.CITY_CODE
      })
    } else if (category === 'COUNTRY_CODE2') {
      list = this.props.countries.map(elem => {
        return JSON.stringify({ COUNTRY_CODE2: elem.COUNTRY_CODE2, COUNTRY_CODE3: elem.COUNTRY_CODE3 })
      })
    } else if (category === 'TIME_ZONE') {
      list = this.props.cities.map(elem => {
        return elem.TIME_ZONE
      })
    } else if (category === 'LANGUAGE_CODE') {
      list = Object.keys(by639_1).map(elem => {
        return elem.toUpperCase()
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]
    return listUnique
  }

  hasDuplicates = () => {
    console.log(this.state)
    const langArray = [
      this.state.LANGUAGE_CODE,
      ...this.state.cityNameList.map(e => {
        return e.LANGUAGE_CODE
      })
    ]
    console.log(langArray)
    if (new Set(langArray).size !== langArray.length) {
      this.setState({
        error: 'Duplicate language codes are not allowed'
      })
      return true
    }
    return false
  }

  saveCity = () => {
    if (!this.hasDuplicates()) {
      let daylightSaving = 'N'
      if (this.state.DAYLIGHT_SAVING) {
        daylightSaving = 'Y'
      }
      let daylightSavingMins = this.state.DAYLIGHT_SAVING_MINUTES
      if (daylightSavingMins.length < 1) {
        daylightSavingMins = '60'
      }
      // let cityList = this.props.cities.filter(e => {return e.CITY_CODE === this.state.CITY_CODE})
      const req = {
        ...this.state,
        DAYLIGHT_SAVING: daylightSaving,
        DAYLIGHT_SAVING_MINUTES: daylightSavingMins,
        type: 'MENU'
      }
      console.log(req)
      this.props.saveCity(req)
      this.resetForm()
      this.props.closeModal()
    }
  }

  deleteCity = () => {
    const onlyCity =
      this.props.cities.filter(e => {
        return e.CITY_CODE === this.state.CITY_CODE
      }).length === 1
    const req = { ...this.state, onlyCity: onlyCity }
    console.log(req)
    this.props.deleteCity(req)
    this.resetForm()
    this.props.closeModal()
  }

  postCity = () => {
    if (!this.hasDuplicates()) {
      let daylightSaving = 'N'
      if (this.state.DAYLIGHT_SAVING) {
        daylightSaving = 'Y'
      }
      let daylightSavingMins = this.state.DAYLIGHT_SAVING_MINUTES
      if (daylightSavingMins.length < 1) {
        daylightSavingMins = '60'
      }

      const req = { ...this.state, DAYLIGHT_SAVING: daylightSaving, DAYLIGHT_SAVING_MINUTES: daylightSavingMins }
      console.log(req)
      this.props.postCity(req)
      this.resetForm()
      this.props.closeModal()
    }
  }

  render() {
    const isSave =
      this.state.CITY_NAME.length > 0 &&
      this.state.cityCodeExists &&
      this.state.TIME_ZONE.length > 0 &&
      this.state.COUNTRY_CODE2.length > 0 &&
      this.state.LANGUAGE_CODE.length > 0 &&
      !this.state.cityNameList.some(e => e.CITY_NAME.length < 1 || e.LANGUAGE_CODE.length < 1)
    const isDelete = this.state.cityCodeExists
    const isAdd =
      this.state.CITY_NAME.length > 0 &&
      this.state.CITY_CODE.length > 2 &&
      this.state.TIME_ZONE.length > 0 &&
      this.state.COUNTRY_CODE2.length > 0 &&
      this.state.LANGUAGE_CODE.length > 0 &&
      !this.state.cityCodeExists &&
      !this.state.cityNameList.some(e => e.CITY_NAME.length < 1 || e.LANGUAGE_CODE.length < 1)

    let footer = (
      <Modal.Footer>
        <Button variant="dark" onClick={this.saveCity} disabled={!isSave}>
          Save
        </Button>
        <Button variant="danger" onClick={this.deleteCity} disabled={!isDelete}>
          Delete
        </Button>
        <Button variant="success" onClick={this.postCity} disabled={!isAdd}>
          Add
        </Button>
      </Modal.Footer>
    )
    if (!!this.state.error) {
      footer = (
        <Modal.Footer>
          <p className="error">{this.state.error}</p>
          <Button variant="dark" onClick={this.saveCity} disabled={!isSave}>
            Save
          </Button>
          <Button variant="danger" onClick={this.deleteCity} disabled={!isDelete}>
            Delete
          </Button>
          <Button variant="success" onClick={this.postCity} disabled={!isAdd}>
            Add
          </Button>
        </Modal.Footer>
      )
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                City Code<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('CITY_CODE')}
                  type="CITY_CODE"
                  id="CITY_CODE"
                  selected={[this.state.CITY_CODE]}
                  onChange={this.handleCityCode}
                  onInputChange={this.handleCityCode}
                  emptyLabel={false}
                  highlightOnlyResult
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                City Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="5">
                <Form.Control type="CITY_NAME" value={this.state.CITY_NAME} onChange={this.handleCityName} />
              </Col>
              <Col sm="3">
                <Form.Control as="select" onChange={this.handleLangCode} value={this.state.LANGUAGE_CODE}>
                  {!this.state.cityCodeExists && (
                    <option key="defaultValueLangCodeCity" value="">
                      Language Code
                    </option>
                  )}
                  {this.state.cityCodeExists && (
                    <option key="defaultValueLangCodeCity" value="">
                      {this.state.LANGUAGE_CODE}
                    </option>
                  )}
                  {this.getUniqueList('LANGUAGE_CODE').map(e => {
                    return (
                      <option key={this.props.database + e} value={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
              <Col sm="1">
                <Button
                  className="plusRow"
                  variant="outline-primary"
                  size="sm"
                  onClick={() => this.handleCityNameRow('+')}
                >
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </Col>
            </Form.Group>

            {this.state.cityNameList.map((elem, i) => {
              return (
                <Form.Group as={Row} controlId="formPlaintextScope">
                  <Form.Label column sm="3"></Form.Label>
                  <Col sm="5">
                    <Form.Control
                      type="CITY_NAME"
                      value={elem.CITY_NAME}
                      onChange={e => this.handleCityNameList(e, i)}
                    />
                  </Col>
                  <Col sm="3">
                    <Form.Control as="select" value={elem.LANGUAGE_CODE} onChange={e => this.handleCityLangList(e, i)}>
                      <option key="defaultValueLangCodeCity" value="">
                        Language Code
                      </option>
                      {this.getUniqueList('LANGUAGE_CODE').map(e => {
                        return (
                          <option key={this.props.database + e} value={e}>
                            {e}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Col>
                  <Col sm="1">
                    <Button
                      className="minusRow"
                      variant="outline-danger"
                      size="sm"
                      onClick={() => this.handleCityNameRow(i)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                  </Col>
                </Form.Group>
              )
            })}

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Country Code<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control as="select" onChange={this.handleCountryCode2} value={this.state.COUNTRY_CODE2}>
                  {!this.state.cityCodeExists && <option key="HandleCountryCodeDefaultValue" value=""></option>}
                  {this.getUniqueList('COUNTRY_CODE2').map(e => {
                    return (
                      <option key={this.props.database + e} value={JSON.parse(e).COUNTRY_CODE2}>
                        {JSON.parse(e).COUNTRY_CODE2 + '/' + JSON.parse(e).COUNTRY_CODE3}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Time Zone<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('TIME_ZONE')}
                  type="TIME_ZONE"
                  id="TIME_ZONE"
                  selected={[this.state.TIME_ZONE]}
                  onChange={this.handleTimeZone}
                  onInputChange={this.handleTimeZone}
                  emptyLabel={false}
                  highlightOnlyResult
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Daylight Saving:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch4"
                  label=""
                  checked={this.state.DAYLIGHT_SAVING}
                  onClick={this.handleDaylightSaving}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Daylight Saving Minutes:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="DAYLIGHT_SAVING_MINUTES"
                  value={this.state.DAYLIGHT_SAVING_MINUTES}
                  onChange={this.handleDaylightSavingMin}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    cities: state.loadData.cities,
    countries: state.loadData.countries,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveCity, postCity, deleteCity, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CityMenu)
