const updateResponses = (type, obj) => {
  switch (type) {
    case 'UPDATED_DATA':
      return {
        type: 'UPDATED_DATA',
        data: obj
      }
    case 'RESET_UPDATED_DATA':
      return {
        type: 'RESET_UPDATED_DATA'
      }
    default:
  }
}

export default updateResponses
