export const CONFIGDATA_LOADED = "CONFIGDATA_LOADED";
export const CONFIGGROUPS_LOADED = "CONFIGGROUPS_LOADED";
export const CONFIGSCOPES_LOADED = "CONFIGSCOPES_LOADED";
export const STATE_DEF_LOADED = "STATE_DEF_LOADED";
export const STATE_DESC_LOADED = "STATE_DESC_LOADED";
export const PROPERTIES_LOADED = "PROPERTIES_LOADED";
export const PROFILES_LOADED = "PROFILES_LOADED";
export const ACCESS_KEYS_LOADED = "ACCESS_KEYS_LOADED";
export const USERS_LOADED = "UERS_LOADED";
export const FILTER_COLUMN = "FILTER_COLUMN";
export const FILTER_QUERY = "FILTER_QUERY";
export const CHANGE_TABLE = "CHANGE_TABLE";
export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const CHANGE_MODAL = "CHANGE_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const POST_CONFIG_VALUE = "POST_CONFIG_VALUE";
export const GRANT_ACCESS = "GRANT_ACCESS";
export const REMOVE_ACCESS = "REMOVE_ACCESS";
export const COMMIT_GRANT_ACCESS = "COMMIT_GRANT_ACCESS";
export const COMMIT_REMOVE_ACCESS = "COMMIT_REMOVE_ACCESS";
export const CLEAR_ACCESS = "CLEAR_ACCESS";
export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const EDIT_CONFIG = "EDIT_CONFIG";
export const CONFIRM_ADMIN = "CONFIRM_ADMIN";
export const LOG_OFF = "LOG_OFF";
export const CITIES_LOADED = "CITIES_LOADED"
export const COUNTRIES_LOADED = "COUNTRIES_LOADED";
export const COUNTRY_INDEXES_LOADED = "COUNTRY_INDEXES_LOADED";
export const DATABASE_LOADED = "DATABASE_LOADED";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const EDIT_STATE = "EDIT_STATE";
export const EDIT_PROPERTY = "EDIT_PROPERTY";
export const EDIT_CITY = "EDIT_CITY";
export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const EDIT_COUNTRY_INDEX = "EDIT_COUNTRY_INDEX";
export const EDIT_PROFILE_ACCESS_KEYS = "EDIT_PROFILE_ACCESS_KEYS";
export const POST_REQUEST_RESPONSE = "POST_REQUEST_RESPONSE";
export const RESET_ERROR = "RESET_ERROR";
export const SET_ERROR = "SET_ERROR";
export const CLOSE_NOTIF = "CLOSE_NOTIF";
