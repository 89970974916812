import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { setDatabase } from "redux/actions/loginInfo"
import { changeFilterColumn } from "redux/actions/searchFilter"
import {
  fetchConfigValues,
  fetchConfigGroups,
  fetchConfigScopes,
  fetchProperties,
  fetchProfiles,
  fetchUsers,
  fetchAccessKeys,
  fetchStateDef,
  fetchStateDesc,
  fetchCity,
  fetchCountry,
  fetchCountryIndex,
} from "redux/actions/getData"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-bootstrap-typeahead/css/Typeahead.css"
import "./App.css"
import "./css/table.css"
import "./css/embross.css"
import ConfigTable from "./components/tables/ConfigTable"
import StateTable from "./components/tables/StateTable"
import RegionalTable from "./components/tables/RegionalTable"
import PropertiesTable from "./components/tables/PropertiesTable"
import Header from "./components/header/Header"
import PostNotification from "./components/toasts/PostNotification"
// import Footer from './components/footer/Footer'
import queryString from "query-string"
import ScrollUpButton from "react-scroll-up-button"
import { library } from "@fortawesome/fontawesome-svg-core"
import Dashboard from "Dashboard"
import { Route, Redirect, withRouter } from "react-router-dom"

import {
  faCheckSquare,
  faSignOutAlt,
  faLock,
  faPlus,
  faMinus,
  faPlane,
  faChevronDown,
  faUserAlt,
  faChild,
  faBaby,
  faSuitcase,
  faTag,
  faArrowRight,
  faAddressCard,
  faCreditCard,
  faIdCard,
  faLuggageCart,
  faClipboardCheck,
  faCheckCircle,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons"
library.add(
  faCheckSquare,
  faSignOutAlt,
  faLock,
  faPlus,
  faMinus,
  faPlane,
  faChevronDown,
  faUserAlt,
  faChild,
  faBaby,
  faSuitcase,
  faTag,
  faArrowRight,
  faAddressCard,
  faCreditCard,
  faIdCard,
  faLuggageCart,
  faClipboardCheck,
  faCheckCircle,
  faCalendarCheck
)

class App extends Component {
  constructor(props) {
    super(props)
    this.dbConnected = false
  }
  initiateDBConnection = () => {
    const db = this.parseDatabase()
    // TODO: moved to proper component and make fetch ONLY if needed
    if (
      !(this.props.currentModal === "configAccessMenuShow") &&
      Object.keys(db).length !== 0 &&
      db.database !== undefined &&
      db.sql !== undefined &&
      this.props.successfulLogin &&
      !this.props.error
    ) {
      this.props.fetchConfigScopes()
      this.props.fetchConfigValues()
      this.props.fetchConfigGroups()
      this.props.fetchProfiles()
      this.props.fetchUsers()
      this.props.fetchProperties()
      this.props.fetchAccessKeys()
      this.props.fetchStateDef()
      this.props.fetchStateDesc()
      this.props.fetchCity()
      this.props.fetchCountry()
      this.props.fetchCountryIndex()
    }
    // const fetchTimeout = 2000
    // this.interval = setInterval(() => {
    //   if (
    //     !(this.props.currentModal === 'configAccessMenuShow') &&
    //     Object.keys(db).length !== 0 &&
    //     db.database !== undefined &&
    //     db.sql !== undefined &&
    //     this.props.successfulLogin &&
    //     !this.props.error
    //   ) {
    //     fetchConfigScopes().then(res => {
    //       console.log(res)
    //     })
    //     this.props.fetchConfigValues()
    //     this.props.fetchConfigGroups()
    //     this.props.fetchProfiles()
    //     this.props.fetchUsers()
    //     this.props.fetchProperties()
    //     this.props.fetchAccessKeys()
    //     this.props.fetchStateDef()
    //     this.props.fetchStateDesc()
    //     this.props.fetchCity()
    //     this.props.fetchCountry()
    //     this.props.fetchCountryIndex()
    //   }
    // }, fetchTimeout)
  }

  componentDidMount() {
    this.initiateDBConnection()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.initiateDBConnection()
  }

  parseDatabase() {
    let db = queryString.parse(window.location.search)
    if (Object.keys(db).length === 0) {
      let dbPath = window.location.pathname
      let dbAlias = dbPath.substring(dbPath.lastIndexOf("/") + 1)
      let mapDb = window.config.alias.find((e) => e.alias === dbAlias)
      if (!!mapDb) {
        db = mapDb
      }
      console.log("parseDatabase(): ", db)
    } else {
      console.log("parseDatabase() else: ", db)
    }
    this.props.setDatabase(db)
    return db
  }

  // componentWillUnmount() {
  //   clearInterval(this.interval)
  // }

  render() {
    let configTable = null
    let stateDefTable = null
    let regionalTable = null
    let propertiesTable = null

    if (this.props.successfulLogin) {
      if (this.props.configTableShow) {
        const filters = this.props.categories
        if (!filters.includes(this.props.category)) {
          this.props.changeFilterColumn(filters[0])
        }
        configTable = (
          <ConfigTable fetchConfigValues={this.props.fetchConfigValues} />
        )
      }

      if (this.props.stateDefTableShow) {
        const filters = this.props.categories
        if (!filters.includes(this.props.category)) {
          this.props.changeFilterColumn(filters[0])
        }
        stateDefTable = <StateTable />
      }

      if (this.props.propertiesTableShow) {
        const filters = this.props.categories
        if (!filters.includes(this.props.category)) {
          this.props.changeFilterColumn(filters[0])
        }
        propertiesTable = <PropertiesTable />
      }

      if (this.props.regionalTableShow) {
        const filters = this.props.categories
        if (!filters.includes(this.props.category)) {
          this.props.changeFilterColumn(filters[0])
        }
        regionalTable = <RegionalTable />
      }
    }

    const isDashboard = window.location.pathname.includes("dashboard")
    const header = isDashboard ? "" : <Header history={this.props.history} />

    return (
      <div className="App">
        <Route path="/dashboard" component={Dashboard} />
        <Route exact path="/">
          <Redirect to={"/dashboard"} />
        </Route>
        <ScrollUpButton />
        {header}
        <br />
        {configTable}
        {stateDefTable}
        {regionalTable}
        {propertiesTable}
        <PostNotification />
        {/* {this.props.successfulLogin && <Footer />} */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    configTableShow: state.changeTable.configTableShow,
    stateDefTableShow: state.changeTable.stateDefTableShow,
    regionalTableShow: state.changeTable.regionalTableShow,
    propertiesTableShow: state.changeTable.propertiesTableShow,
    categories: state.changeTable.categories,
    currentModal: state.manageModal.currentModal,
    successfulLogin: state.accountLogin.successfulLogin,
    userLogin: state.accountLogin.USER,
    category: state.filterData.filterColumn,
    error: state.loadData.error,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchConfigValues,
      fetchConfigGroups,
      fetchConfigScopes,
      fetchProfiles,
      fetchProperties,
      fetchUsers,
      fetchAccessKeys,
      fetchStateDef,
      fetchStateDesc,
      fetchCity,
      fetchCountry,
      fetchCountryIndex,
      changeFilterColumn,
      setDatabase,
    },
    dispatch
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
