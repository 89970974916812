import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeModal } from 'redux/actions/manageModal'
import { commitGrantAccess, commitRemoveAccess, clearAccess } from 'redux/actions/editConfigAccess'
import ProfileAccessTable from '../../tables/ProfileAccessTable'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

class ConfigAccessMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      CONFIG_KEY: '',
      filterColumn: 'Configuration Key',
      query: ''
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleSearchChange = event => {
    const query = event.target.value
    this.setState({
      query: query
    })
  }

  handleCategoryChange = event => {
    const category = event.target.textContent
    this.setState({
      filterColumn: category
    })
  }

  grantAccess = () => {
    this.props.commitGrantAccess(this.props.GRANT_ACCESS)
  }

  removeAccess = () => {
    this.props.commitRemoveAccess(this.props.REMOVE_ACCESS)
  }

  render() {
    return (
      <Modal {...this.props} size="xl">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <div className="offset-sm-4">
            <Form inline>
              <Form.Control
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                value={this.state.query}
                onChange={this.handleSearchChange}
              />
              <DropdownButton id="dropdown-filter" variant="dark" title={this.state.filterColumn}>
                <Dropdown.Item onClick={this.handleCategoryChange}>Configuration Key</Dropdown.Item>
                <Dropdown.Item onClick={this.handleCategoryChange}>Scope Name</Dropdown.Item>
                <Dropdown.Item onClick={this.handleCategoryChange}>Group Name</Dropdown.Item>
                <Dropdown.Item onClick={this.handleCategoryChange}>Any</Dropdown.Item>
              </DropdownButton>
            </Form>
          </div>
          <Form.Group as={Col} controlId="profileHasAccessTable">
            <Form.Label row sm="2">
              Has Access:
            </Form.Label>
            <Col sm="12">
              <ProfileAccessTable
                PROFILE_ID={this.props.editProfileId}
                query={this.state.query}
                category={this.state.filterColumn}
                hasAccess={true}
                canEdit={true}
              />
            </Col>
            <Form.Label row sm="2">
              No Access:
            </Form.Label>
            <Col sm="12">
              <ProfileAccessTable
                PROFILE_ID={this.props.editProfileId}
                query={this.state.query}
                category={this.state.filterColumn}
                hasAccess={false}
                canEdit={true}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <div class="modal-footer justify-content-between">
          <div>
            <button
              type="button"
              class="btn btn-danger mr-1"
              onClick={this.removeAccess}
              disabled={!this.props.REMOVE_ACCESS.length > 0}
            >
              Remove Access
            </button>
            <button
              type="button"
              class="btn btn-success mr-1"
              onClick={this.grantAccess}
              disabled={!this.props.GRANT_ACCESS.length > 0}
            >
              Grant Access
            </button>
            <button type="button" class="btn btn-light mr-1" onClick={this.props.clearAccess}>
              Clear
            </button>
          </div>
          <Button variant="dark" onClick={this.props.onHide}>
            OK
          </Button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    accessKeys: state.loadData.accessKeys,
    editProfileId: state.manageModal.editProfileId,
    GRANT_ACCESS: state.editConfigAccess.grant,
    REMOVE_ACCESS: state.editConfigAccess.remove
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal, commitGrantAccess, commitRemoveAccess, clearAccess }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigAccessMenu)
