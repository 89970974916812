import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { manageModal, loadCity } from '../../redux/actions/manageModal'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CityTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      COLUMN_SORT: 'City Name',
      desc: true,
      EDIT_CONFIG: {}
    }
  }

  filteredValues = () => {
    const category = this.props.category
    const query = this.props.query
    let cityCountry = []
    for (let city of this.props.cities) {
      if (city.CITY_CODE !== null) {
        if (!city.CITY_NAME) city.CITY_NAME = ''
        let flag = false
        for (let country of this.props.countries) {
          if (city.COUNTRY_CODE2 === country.COUNTRY_CODE2) {
            if (city.LANGUAGE_CODE === country.LANGUAGE_CODE) {
              flag = true
              cityCountry.push({ ...city, ...country })
            }
          }
        }
        if (!flag) {
          const country = this.props.countries.find(e => city.COUNTRY_CODE2 === e.COUNTRY_CODE2)
          if (!country) cityCountry.push({ ...city, COUNTRY_NAME: '', COUNTRY_CODE3: '', COUNTRY_NUMBER: '' })
          else cityCountry.push({ ...city, ...country, LANGUAGE_CODE: city.LANGUAGE_CODE })
        }
      }
    }
    const filteredData = cityCountry.filter(element => {
      element.CITY_CODE = !!element.CITY_CODE ? element.CITY_CODE : ''
      element.COUNTRY_NAME = !!element.COUNTRY_NAME ? element.COUNTRY_NAME : ''
      element.COUNTRY_CODE2 = !!element.COUNTRY_CODE2 ? element.COUNTRY_CODE2 : ''
      element.COUNTRY_CODE3 = !!element.COUNTRY_CODE3 ? element.COUNTRY_CODE3 : ''
      element.COUNTRY_NUMBER = element.COUNTRY_NUMBER !== null ? element.COUNTRY_NUMBER : ''
      element.TIME_ZONE = element.TIME_ZONE !== null ? element.TIME_ZONE : ''
      element.DAYLIGHT_SAVING = element.DAYLIGHT_SAVING !== null ? element.DAYLIGHT_SAVING : ''
      element.DAYLIGHT_SAVING_MINUTES = element.DAYLIGHT_SAVING_MINUTES !== null ? element.DAYLIGHT_SAVING_MINUTES : ''
      element.LANGUAGE_CODE = !!element.LANGUAGE_CODE ? element.LANGUAGE_CODE : ''
      if (category === 'City Code') return element.CITY_CODE.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Name') return element.COUNTRY_NAME.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Code 2') return element.COUNTRY_CODE2.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Code 3') return element.COUNTRY_CODE3.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Number') return element.COUNTRY_NUMBER.toString().includes(query)
      else if (category === 'Time Zone') return element.TIME_ZONE.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Daylight Saving')
        return element.DAYLIGHT_SAVING.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Daylight Saving Minutes') return element.DAYLIGHT_SAVING_MINUTES.toString().includes(query)
      else if (category === 'Language Code') return element.LANGUAGE_CODE.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Any')
        return (
          element.CITY_NAME.toLowerCase().includes(this.props.query.toLowerCase()) ||
          element.CITY_CODE.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_NAME.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_CODE2.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_CODE3.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_NUMBER.toString().includes(query) ||
          element.TIME_ZONE.toLowerCase().includes(query.toLowerCase()) ||
          element.DAYLIGHT_SAVING.toLowerCase().includes(query.toLowerCase()) ||
          element.DAYLIGHT_SAVING_MINUTES.toString().includes(query) ||
          element.LANGUAGE_CODE.toLowerCase().includes(query.toLowerCase())
        )
      else return element.CITY_NAME.toLowerCase().includes(this.props.query.toLowerCase())
    })

    if (this.state.COLUMN_SORT === 'City Name') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.CITY_NAME.toLowerCase() > b.CITY_NAME.toLowerCase()
            ? 1
            : b.CITY_NAME.toLowerCase() > a.CITY_NAME.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.CITY_NAME.toLowerCase() > b.CITY_NAME.toLowerCase()
            ? -1
            : b.CITY_NAME.toLowerCase() > a.CITY_NAME.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'City Code') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.CITY_CODE.toLowerCase() > b.CITY_CODE.toLowerCase()
            ? 1
            : b.CITY_CODE.toLowerCase() > a.CITY_CODE.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.CITY_CODE.toLowerCase() > b.CITY_CODE.toLowerCase()
            ? -1
            : b.CITY_CODE.toLowerCase() > a.CITY_CODE.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Name') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NAME > b.COUNTRY_NAME ? 1 : b.COUNTRY_NAME > a.COUNTRY_NAME ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NAME > b.COUNTRY_NAME ? -1 : b.COUNTRY_NAME > a.COUNTRY_NAME ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Code 2') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE2 > b.COUNTRY_CODE2 ? 1 : b.COUNTRY_CODE2 > a.COUNTRY_CODE2 ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE2 > b.COUNTRY_CODE2 ? -1 : b.COUNTRY_CODE2 > a.COUNTRY_CODE2 ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Code 3') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE3 > b.COUNTRY_CODE3 ? 1 : b.COUNTRY_CODE3 > a.COUNTRY_CODE3 ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE3 > b.COUNTRY_CODE3 ? -1 : b.COUNTRY_CODE3 > a.COUNTRY_CODE3 ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Number') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NUMBER > b.COUNTRY_NUMBER ? 1 : b.COUNTRY_NUMBER > a.COUNTRY_NUMBER ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NUMBER > b.COUNTRY_NUMBER ? -1 : b.COUNTRY_NUMBER > a.COUNTRY_NUMBER ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Time Zone') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.TIME_ZONE.toLowerCase() > b.TIME_ZONE.toLowerCase()
            ? 1
            : b.TIME_ZONE.toLowerCase() > a.TIME_ZONE.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.TIME_ZONE.toLowerCase() > b.TIME_ZONE.toLowerCase()
            ? -1
            : b.TIME_ZONE.toLowerCase() > a.TIME_ZONE.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Daylight Saving') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.DAYLIGHT_SAVING.toLowerCase() > b.DAYLIGHT_SAVING.toLowerCase()
            ? 1
            : b.DAYLIGHT_SAVING.toLowerCase() > a.DAYLIGHT_SAVING.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.DAYLIGHT_SAVING.toLowerCase() > b.DAYLIGHT_SAVING.toLowerCase()
            ? -1
            : b.DAYLIGHT_SAVING.toLowerCase() > a.DAYLIGHT_SAVING.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Daylight Saving Minutes') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.DAYLIGHT_SAVING_MINUTES > b.DAYLIGHT_SAVING_MINUTES
            ? 1
            : b.DAYLIGHT_SAVING_MINUTES > a.DAYLIGHT_SAVING_MINUTES
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.DAYLIGHT_SAVING_MINUTES > b.DAYLIGHT_SAVING_MINUTES
            ? -1
            : b.DAYLIGHT_SAVING_MINUTES > a.DAYLIGHT_SAVING_MINUTES
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Language Code') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.LANGUAGE_CODE.toLowerCase() > b.LANGUAGE_CODE.toLowerCase()
            ? 1
            : b.LANGUAGE_CODE.toLowerCase() > a.LANGUAGE_CODE.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.LANGUAGE_CODE.toLowerCase() > b.LANGUAGE_CODE.toLowerCase()
            ? -1
            : b.LANGUAGE_CODE.toLowerCase() > a.LANGUAGE_CODE.toLowerCase()
            ? 1
            : 0
        })
    }
    return filteredData
  }

  handleRowClick = data => {
    console.log(data)
    if (JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG)) {
      this.props.loadCity({ editCity: this.state.EDIT_CONFIG })
      this.props.manageModal({ currentModal: 'editCityMenuShow' })
    } else {
      this.setState({
        EDIT_CONFIG: data
      })
    }
  }

  handleHeaderClick = column => {
    if (column === this.state.COLUMN_SORT) {
      this.setState({
        desc: !this.state.desc
      })
    } else {
      this.setState({
        COLUMN_SORT: column,
        desc: true
      })
    }
  }

  handleColumnSelects = data => {
    if (data === this.state.COLUMN_SORT) {
      if (this.state.desc) return { borderTop: '2px solid #3F4766' }
      else return { borderBottom: '2px solid #3F4766' }
    } else {
      return null
    }
  }

  handleRowSelects = data => {
    return JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG) ? { background: '#C8E3EF' } : null
  }

  render() {
    return (
      <div className="Table">
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th style={this.handleColumnSelects('City Name')} onClick={() => this.handleHeaderClick('City Name')}>
                City Name
              </th>
              <th style={this.handleColumnSelects('City Code')} onClick={() => this.handleHeaderClick('City Code')}>
                <FontAwesomeIcon icon="lock" /> City Code
              </th>
              <th
                style={this.handleColumnSelects('Country Name')}
                onClick={() => this.handleHeaderClick('Country Name')}
              >
                <FontAwesomeIcon icon="lock" /> Country Name
              </th>
              <th
                style={this.handleColumnSelects('Country Code 2')}
                onClick={() => this.handleHeaderClick('Country Code 2')}
              >
                Country Code 2
              </th>
              <th
                style={this.handleColumnSelects('Country Code 3')}
                onClick={() => this.handleHeaderClick('Country Code 3')}
              >
                Country Code 3
              </th>
              {/* <th style = {this.handleColumnSelects("Country Number")} onClick={() => this.handleHeaderClick("Country Number")}>Country Number</th> */}
              <th style={this.handleColumnSelects('Time Zone')} onClick={() => this.handleHeaderClick('Time Zone')}>
                Time Zone
              </th>
              <th
                style={this.handleColumnSelects('Daylight Saving')}
                onClick={() => this.handleHeaderClick('Daylight Saving')}
              >
                Daylight Saving
              </th>
              <th
                style={this.handleColumnSelects('Daylight Saving Minutes')}
                onClick={() => this.handleHeaderClick('Daylight Saving Minutes')}
              >
                Daylight Saving Minutes
              </th>
              <th
                style={this.handleColumnSelects('Language Code')}
                onClick={() => this.handleHeaderClick('Language Code')}
              >
                <FontAwesomeIcon icon="lock" /> Language Code
              </th>
            </tr>
          </thead>
          <tbody>
            {this.filteredValues().map((configuration, index) => {
              return (
                <tr
                  key={this.props.database + index}
                  style={this.handleRowSelects(configuration)}
                  onClick={() => this.handleRowClick(configuration)}
                >
                  <td> {configuration.CITY_NAME} </td>
                  <td> {configuration.CITY_CODE} </td>
                  <td> {configuration.COUNTRY_NAME} </td>
                  <td> {configuration.COUNTRY_CODE2} </td>
                  <td> {configuration.COUNTRY_CODE3} </td>
                  {/* <td> {configuration.COUNTRY_NUMBER} </td> */}
                  <td> {configuration.TIME_ZONE} </td>
                  <td> {configuration.DAYLIGHT_SAVING} </td>
                  <td> {configuration.DAYLIGHT_SAVING_MINUTES} </td>
                  <td> {configuration.LANGUAGE_CODE} </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    cities: state.loadData.cities,
    countries: state.loadData.countries,
    query: state.filterData.query,
    category: state.filterData.filterColumn,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ manageModal, loadCity }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CityTable)
