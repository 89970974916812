import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { changeFilterQuery, changeFilterColumn } from 'redux/actions/searchFilter'
import { changeTable } from 'redux/actions/changeTable'
import { logout } from 'redux/actions/loginInfo'
import authentication from 'services/authentication'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Nav from 'react-bootstrap/Nav'
import logo from '../../assets/embross.png'
import Buttons from './Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Header extends React.Component {
  handleSearchChange = event => {
    const query = event.target.value
    this.props.changeFilterQuery(query)
  }

  handleCategoryChange = event => {
    const category = event.target.textContent
    this.props.changeFilterColumn(category)
  }

  handleTableChange = event => {
    const table = event.target.textContent
    console.log(table)
    this.props.changeTable(table)
  }

  logOff = async () => {
    const { removeToken } = authentication()
    await removeToken()
    this.props.logout()
    this.props.history.push('/')
  }

  renderTooltip = props => {
    return <Tooltip {...props}>{'User: ' + this.props.USER.FULL_NAME}</Tooltip>
  }

  renderDbTooltip = props => {
    return (
      <Tooltip {...props}>
        {'Database: ' + this.props.USER.database + ' on ' + this.props.USER.sql.toUpperCase() + 'SQL'}
      </Tooltip>
    )
  }

  render() {
    return (
      <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark" sticky="top">
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={this.renderDbTooltip}>
          <Navbar.Brand>
            <img alt="" src={logo} width="30" height="30" className="d-inline-block align-top" />
            {' ' + window.config.companyName + ' Config Tool'}
          </Navbar.Brand>
        </OverlayTrigger>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Form inline>
              <DropdownButton id="dropdown-basic-button" title={this.props.tableName} variant="dark">
                <Dropdown.Item onClick={this.handleTableChange}>Scope Configurations</Dropdown.Item>
                {this.props.access.STATE_ACCESS.includes('R') && (
                  <Dropdown.Item onClick={this.handleTableChange}>State Configurations</Dropdown.Item>
                )}
                {this.props.access.PROPERTIES_ACCESS.includes('R') && (
                  <Dropdown.Item onClick={this.handleTableChange}>Properties</Dropdown.Item>
                )}
                {this.props.access.REGION_ACCESS.includes('R') && (
                  <Dropdown.Item onClick={this.handleTableChange}>Regions</Dropdown.Item>
                )}
              </DropdownButton>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                value={this.props.query}
                onChange={this.handleSearchChange}
              />
              <DropdownButton id="dropdown-basic-button3" variant="dark" title={this.props.filterColumn}>
                {this.props.categories.map(e => {
                  return (
                    <Dropdown.Item key={'header-dropdown-category-item' + e} onClick={this.handleCategoryChange}>
                      {e}
                    </Dropdown.Item>
                  )
                })}
              </DropdownButton>
            </Form>
          </Nav>
          <Nav>
            <Form inline>
              <Buttons history={this.props.history} />
            </Form>
          </Nav>
          <Nav>
            <Form inline>
              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip}>
                <Button className="logOff" variant="outline-danger" size="lg" onClick={this.logOff}>
                  <FontAwesomeIcon icon="sign-out-alt" />
                </Button>
              </OverlayTrigger>
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeFilterQuery, changeFilterColumn, changeTable, logout }, dispatch)
}

const mapStateToProps = state => {
  return {
    query: state.filterData.query,
    filterColumn: state.filterData.filterColumn,
    tableName: state.changeTable.tableName,
    configTableShow: state.changeTable.configTableShow,
    stateDefTableShow: state.changeTable.stateDefTableShow,
    regionalTableShow: state.changeTable.regionalTableShow,
    propertiesTableShow: state.changeTable.propertiesTableShow,
    categories: state.changeTable.categories,
    USER: state.accountLogin.USER,
    database: state.accountLogin.database,
    access: state.accountLogin
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
