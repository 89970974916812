import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "react-bootstrap/Button"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import logo from "assets/embross.png"
import { manageModal } from "redux/actions/manageModal"
import "Dashboard.css"

const Dashboard = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(manageModal({ currentModal: "" }))
  }, [dispatch])

  const accountLogin = useSelector((state) => state.accountLogin)
  const handleOnClick = (alias) => {
    if (!accountLogin.successfulLogin) {
      dispatch(manageModal({ currentModal: "loginMenuShow" }))
    }
    props.history.push(`/${alias}`)
  }
  const cards = []
  window.config.alias.forEach((data, index) => {
    cards.push(
      <Card style={{ width: "20rem", margin: "5px" }} key={data.alias + index}>
        <Card.Body>
          <Card.Title>{data.alias}</Card.Title>
          <Card.Text>database: {data.database}</Card.Text>
          <Card.Text>sql: {data.sql}</Card.Text>
          <Card.Text>hostname: {data.hostname}</Card.Text>

          <Button variant="success" onClick={() => handleOnClick(data.alias)}>
            Access ConfigTool
          </Button>
        </Card.Body>
      </Card>
    )
  })
  return (
    <>
      <Navbar
        collapseOnSelect
        bg="dark"
        expand="lg"
        variant="dark"
        sticky="top"
      >
        <Navbar.Brand>
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
          {" " + window.config.companyName + " Config Tool"}
        </Navbar.Brand>
      </Navbar>
      <Container>
        <div className={"dashboard-title"}>Please Select A Database</div>
        <div className={"dashboard-button-container"}>{cards}</div>
      </Container>
    </>
  )
}

export default Dashboard
