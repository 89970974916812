import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { manageModal, loadProfileId, closeModal } from 'redux/actions/manageModal'
import { clearAccess } from 'redux/actions/editConfigAccess'
import ProfileAccessTable from '../../tables/ProfileAccessTable'
import { saveAccessKeys, addProfileName, deleteProfile } from 'redux/actions/postData'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class ProfileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      PROFILE_NAME: '',
      PROFILE_ID: '',
      ScopeConfigR: true,
      ScopeConfigW: false,
      StateConfigR: false,
      StateConfigW: false,
      PropertiesR: false,
      PropertiesW: false,
      RegionR: false,
      RegionW: false,
      profileExists: []
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleProfile = event => {
    let profileName = event.target.textContent
    if (profileName === '') {
      profileName = event.target.value
    }
    let PROFILE_ID = this.getProfileId()
    let ScopeConfigR = true
    let ScopeConfigW = this.state.ScopeConfigW
    let StateConfigR = this.state.StateConfigR
    let StateConfigW = this.state.StateConfigW
    let PropertiesR = this.state.PropertiesR
    let PropertiesW = this.state.PropertiesW
    let RegionR = this.state.RegionR
    let RegionW = this.state.RegionW

    let profileExists = this.props.profiles.filter(profile => profileName === profile.PROFILE_NAME)
    if (profileExists.length > 0) {
      if (!!profileExists[0].TABLE_ACCESS) {
        let accessKeys = JSON.parse(profileExists[0].TABLE_ACCESS)

        if (!!accessKeys.STATE_ACCESS) {
          ScopeConfigR = accessKeys.SCOPE_ACCESS.includes('R')
          ScopeConfigW = accessKeys.SCOPE_ACCESS.includes('W')
        }

        if (!!accessKeys.STATE_ACCESS) {
          StateConfigR = accessKeys.STATE_ACCESS.includes('R')
          StateConfigW = accessKeys.STATE_ACCESS.includes('W')
        }

        if (!!accessKeys.PROPERTIES_ACCESS) {
          PropertiesR = accessKeys.PROPERTIES_ACCESS.includes('R')
          PropertiesW = accessKeys.PROPERTIES_ACCESS.includes('W')
        }

        if (!!accessKeys.REGION_ACCESS) {
          RegionR = accessKeys.REGION_ACCESS.includes('R')
          RegionW = accessKeys.REGION_ACCESS.includes('W')
        }
      } else {
        ScopeConfigR = true
        ScopeConfigW = false
        StateConfigR = false
        StateConfigW = false
        PropertiesR = false
        PropertiesW = false
        RegionR = false
        RegionW = false
      }

      PROFILE_ID = profileExists[0].PROFILE_ID
    }

    this.setState({
      PROFILE_ID: PROFILE_ID,
      PROFILE_NAME: profileName,
      profileExists: profileExists,
      ScopeConfigR: ScopeConfigR,
      ScopeConfigW: ScopeConfigW,
      StateConfigR: StateConfigR,
      StateConfigW: StateConfigW,
      PropertiesR: PropertiesR,
      PropertiesW: PropertiesW,
      RegionR: RegionR,
      RegionW: RegionW
    })
    this.props.clearAccess()
  }

  getProfileId = () => {
    let counter = 0
    this.props.profiles.map(id => {
      if (id.PROFILE_ID === counter) {
        counter++
        return null
      } else {
        return counter
      }
    })
    return counter
  }

  createTableAccess = () => {
    let TABLE_ACCESS = { SCOPE_ACCESS: '', STATE_ACCESS: '', PROPERTIES_ACCESS: '', REGION_ACCESS: '' }
    if (this.state.ScopeConfigR) {
      TABLE_ACCESS.SCOPE_ACCESS += 'R'
    }
    if (this.state.ScopeConfigW) {
      TABLE_ACCESS.SCOPE_ACCESS += 'W'
    }
    if (this.state.StateConfigR) {
      TABLE_ACCESS.STATE_ACCESS += 'R'
    }
    if (this.state.StateConfigW) {
      TABLE_ACCESS.STATE_ACCESS += 'W'
    }
    if (this.state.PropertiesR) {
      TABLE_ACCESS.PROPERTIES_ACCESS += 'R'
    }
    if (this.state.PropertiesW) {
      TABLE_ACCESS.PROPERTIES_ACCESS += 'W'
    }
    if (this.state.RegionR) {
      TABLE_ACCESS.REGION_ACCESS += 'R'
    }
    if (this.state.RegionW) {
      TABLE_ACCESS.REGION_ACCESS += 'W'
    }
    return JSON.stringify(TABLE_ACCESS)
  }

  addProfile = () => {
    let request = { ...this.state, TABLE_ACCESS: this.createTableAccess() }
    console.log(request)
    this.props.addProfileName(request)
    console.log('PROFILE ADDED!')
    this.resetForm()
    this.props.closeModal()
  }

  deleteProfile = () => {
    const defaultProfile = this.props.profiles.find(e => e.PROFILE_NAME === 'Default')
    const defaultUsers = this.props.users.filter(e => {
      return e.PROFILE_ID === this.state.PROFILE_ID
    })
    this.props.deleteProfile({ ...this.state, DEFAULT_PROFILE: defaultProfile.PROFILE_ID, DEFAULT_USERS: defaultUsers })
    console.log('PROFILE DELETED!')
    this.resetForm()
    this.props.closeModal()
  }

  saveProfile = () => {
    let grant = this.props.COMMIT_GRANT_ACCESS
    let remove = this.props.COMMIT_REMOVE_ACCESS
    let req = [
      { ...this.state, TABLE_ACCESS: this.createTableAccess() },
      { SAVE_GRANT_ACCESS: grant },
      { SAVE_REMOVE_ACCESS: remove }
    ]
    console.log(req)
    this.props.saveAccessKeys(req)
    console.log('PROFILE SAVED!')
    this.resetForm()
    this.props.closeModal()
  }

  editProfile = () => {
    this.props.manageModal({ currentModal: 'configAccessMenuShow' })
    this.props.loadProfileId({ editProfileId: this.state.PROFILE_ID })
  }

  render() {
    let footerButtons = (
      <Modal.Footer>
        <Button variant="outline-dark" disabled>
          Edit
        </Button>
        <Button variant="dark" disabled>
          Save
        </Button>
        <Button variant="danger" disabled>
          Delete
        </Button>
        <Button variant="success" disabled>
          Add
        </Button>
      </Modal.Footer>
    )
    let profileAccess = null
    if (this.state.profileExists.length > 0) {
      if (this.state.PROFILE_NAME === 'Default') {
        footerButtons = (
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.editProfile}>
              Edit
            </Button>
            <Button variant="dark" onClick={this.saveProfile}>
              Save
            </Button>
            <Button variant="danger" disabled>
              Delete
            </Button>
            <Button variant="success" disabled>
              Add
            </Button>
          </Modal.Footer>
        )
      } else if (this.state.PROFILE_NAME === 'Admin') {
        footerButtons = (
          <Modal.Footer>
            <Button variant="outline-dark" disabled>
              Edit
            </Button>
            <Button variant="dark" disabled>
              Save
            </Button>
            <Button variant="danger" disabled>
              Delete
            </Button>
            <Button variant="success" disabled>
              Add
            </Button>
          </Modal.Footer>
        )
      } else {
        footerButtons = (
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.editProfile}>
              Edit
            </Button>
            <Button variant="dark" onClick={this.saveProfile}>
              Save
            </Button>
            <Button variant="danger" onClick={this.deleteProfile}>
              Delete
            </Button>
            <Button variant="success" disabled>
              Add
            </Button>
          </Modal.Footer>
        )
      }

      profileAccess = (
        <Form.Group as={Row} controlId="profileHasAccessTable">
          <Form.Label column sm="2">
            Profile Access:
          </Form.Label>
          <Col sm="10">
            <ProfileAccessTable
              PROFILE_ID={this.state.PROFILE_ID}
              query={''}
              category={''}
              hasAccess={true}
              canEdit={false}
            />
          </Col>
        </Form.Group>
      )
    } else if (this.state.PROFILE_NAME.length > 0) {
      footerButtons = (
        <Modal.Footer>
          <Button variant="outline-dark" disabled>
            Edit
          </Button>
          <Button variant="dark" disabled>
            Save
          </Button>
          <Button variant="danger" disabled>
            Delete
          </Button>
          <Button variant="success" onClick={this.addProfile}>
            Add
          </Button>
        </Modal.Footer>
      )
    }
    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextProfileName">
              <Form.Label column sm="4">
                Profile Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <Form.Control
                    aria-label="Profile Name"
                    aria-describedby="basic-addon2"
                    value={this.state.PROFILE_NAME}
                    onChange={this.handleProfile}
                  />

                  <DropdownButton
                    as={InputGroup.Append}
                    title=""
                    variant="outline-secondary"
                    id="input-group-dropdown-2"
                  >
                    {this.props.profiles.map(profile => {
                      return (
                        <Dropdown.Item key={this.props.database + profile.PROFILE_ID} onClick={this.handleProfile}>
                          {profile.PROFILE_NAME}
                        </Dropdown.Item>
                      )
                    })}
                  </DropdownButton>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Scope Configuration Access:
              </Form.Label>
              <Col sm="2">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox7"
                    label="R"
                    className="profileaccess"
                    checked={this.state.ScopeConfigR}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox7"
                    label="R"
                    className="profileaccess"
                    checked={this.state.ScopeConfigR}
                    onClick={() => {
                      this.setState({
                        ScopeConfigR: !this.state.ScopeConfigR,
                        ScopeConfigW: this.state.ScopeConfigR ? !this.state.ScopeConfigR : this.state.ScopeConfigW
                      })
                    }}
                    disabled
                  />
                )}
              </Col>
              <Col sm="4">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox8"
                    label="W"
                    className="profileaccess"
                    checked={this.state.ScopeConfigW}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox8"
                    label="W"
                    className="profileaccess"
                    checked={this.state.ScopeConfigW}
                    onClick={() => {
                      this.setState({
                        ScopeConfigR: !this.state.ScopeConfigW ? !this.state.ScopeConfigW : this.state.ScopeConfigR,
                        ScopeConfigW: !this.state.ScopeConfigW
                      })
                    }}
                  />
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                State Configuration Access:
              </Form.Label>
              <Col sm="2">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox1"
                    label="R"
                    className="profileaccess"
                    checked={this.state.StateConfigR}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox1"
                    label="R"
                    className="profileaccess"
                    checked={this.state.StateConfigR}
                    onClick={() => {
                      this.setState({
                        StateConfigR: !this.state.StateConfigR,
                        StateConfigW: this.state.StateConfigR ? !this.state.StateConfigR : this.state.StateConfigW
                      })
                    }}
                  />
                )}
              </Col>
              <Col sm="4">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox2"
                    label="W"
                    className="profileaccess"
                    checked={this.state.StateConfigW}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox2"
                    label="W"
                    className="profileaccess"
                    checked={this.state.StateConfigW}
                    onClick={() => {
                      this.setState({
                        StateConfigR: !this.state.StateConfigW ? !this.state.StateConfigW : this.state.StateConfigR,
                        StateConfigW: !this.state.StateConfigW
                      })
                    }}
                  />
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Properties Access:
              </Form.Label>
              <Col sm="2">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox3"
                    label="R"
                    className="profileaccess"
                    checked={this.state.PropertiesR}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox3"
                    label="R"
                    className="profileaccess"
                    checked={this.state.PropertiesR}
                    onClick={() => {
                      this.setState({
                        PropertiesR: !this.state.PropertiesR,
                        PropertiesW: this.state.PropertiesR ? !this.state.PropertiesR : this.state.PropertiesW
                      })
                    }}
                  />
                )}
              </Col>
              <Col sm="4">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox4"
                    label="W"
                    className="profileaccess"
                    checked={this.state.PropertiesW}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox4"
                    label="W"
                    className="profileaccess"
                    checked={this.state.PropertiesW}
                    onClick={() => {
                      this.setState({
                        PropertiesR: !this.state.PropertiesW ? !this.state.PropertiesW : this.state.PropertiesR,
                        PropertiesW: !this.state.PropertiesW
                      })
                    }}
                  />
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Region Access:
              </Form.Label>
              <Col sm="2">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox5"
                    label="R"
                    className="profileaccess"
                    checked={this.state.RegionR}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox5"
                    label="R"
                    className="profileaccess"
                    checked={this.state.RegionR}
                    onClick={() => {
                      this.setState({
                        RegionR: !this.state.RegionR,
                        RegionW: this.state.RegionR ? !this.state.RegionR : this.state.RegionW
                      })
                    }}
                  />
                )}
              </Col>
              <Col sm="4">
                {this.state.PROFILE_NAME === 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox6"
                    label="W"
                    className="profileaccess"
                    checked={this.state.RegionW}
                    disabled
                  />
                )}
                {this.state.PROFILE_NAME !== 'Admin' && (
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox6"
                    label="W"
                    className="profileaccess"
                    checked={this.state.RegionW}
                    onClick={() => {
                      this.setState({
                        RegionR: !this.state.RegionW ? !this.state.RegionW : this.state.RegionR,
                        RegionW: !this.state.RegionW
                      })
                    }}
                  />
                )}
              </Col>
            </Form.Group>
            {profileAccess}
          </Form>
        </Modal.Body>
        {footerButtons}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    COMMIT_GRANT_ACCESS: state.editConfigAccess.commitGrant,
    COMMIT_REMOVE_ACCESS: state.editConfigAccess.commitRemove,
    users: state.loadData.users,
    profiles: state.loadData.profiles,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { manageModal, closeModal, clearAccess, saveAccessKeys, addProfileName, deleteProfile, loadProfileId },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileMenu)
