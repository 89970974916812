import XMLParser from "react-xml-parser"

export function importConfigs(props) {
  try {
    let xmlText = props.xml
    let xml = new XMLParser().parseFromString(xmlText)
    let importRequest = {
      importScopes: [],
      importGroups: [],
      importConfigs: [],
      updateConfigs: [],
      fileName: props.fileName,
    }
    let scopes = xml.getElementsByTagName("SCOPE")
    for (let scope of scopes) {
      let SCOPE_NAME, SCOPE_TYPE, CONFIG_KEY, VALUE, COMMENTS, GROUP_NAME
      SCOPE_NAME = SCOPE_TYPE = CONFIG_KEY = VALUE = COMMENTS = GROUP_NAME = ""
      for (let elem of scope.children) {
        if (elem.name === "NAME") SCOPE_NAME = elem.value
        if (elem.name === "TYPE") SCOPE_TYPE = elem.value
        if (elem.name === "CONFIG_VALUE") {
          for (let config of elem.children) {
            if (config.name === "KEY") CONFIG_KEY = config.value
            if (config.name === "VALUE") VALUE = config.value
            if (config.name === "COMMENTS") COMMENTS = config.value
            if (config.name === "GROUP") GROUP_NAME = config.value
          }
        }
        if (
          SCOPE_NAME.length > 0 &&
          SCOPE_TYPE.length > 0 &&
          CONFIG_KEY.length > 0 &&
          GROUP_NAME.length > 0
        ) {
          // console.log({SCOPE_NAME, SCOPE_TYPE, CONFIG_KEY, VALUE, COMMENTS, GROUP_NAME})
          let configuration = {
            CONFIG_KEY,
            SCOPE_NAME,
            SCOPE_TYPE,
            VALUE,
            COMMENTS,
            GROUP_NAME,
          }
          let GROUP_ID = undefined

          let scopeFound = [
            ...props.scopes,
            ...importRequest.importScopes,
          ].find((e) => e.SCOPE_NAME === configuration.SCOPE_NAME)
          if (scopeFound === undefined) {
            importRequest.importScopes.push({
              SCOPE_NAME: configuration.SCOPE_NAME,
              SCOPE_TYPE: configuration.SCOPE_TYPE,
            })
          }

          let groupFound = [
            ...props.groups,
            ...importRequest.importGroups,
          ].find((e) => e.GROUP_NAME === configuration.GROUP_NAME)
          if (!!groupFound) {
            GROUP_ID = groupFound.GROUP_ID
          } else {
            GROUP_ID = [...props.groups, ...importRequest.importGroups]
              .map((e) => {
                return e.GROUP_ID
              })
              .sort()
              .filter((e, i) => {
                return e === i
              }).length
            importRequest.importGroups.push({
              GROUP_NAME: configuration.GROUP_NAME,
              GROUP_ID: GROUP_ID,
              LAST_UPDATED: new Date().getTime(),
            })
          }

          let configFound = props.configs.find(
            (e) =>
              e.CONFIG_KEY === configuration.CONFIG_KEY &&
              e.SCOPE_NAME === configuration.SCOPE_NAME &&
              e.GROUP_ID === GROUP_ID
          )
          if (!!configFound) {
            if (configFound.VALUE !== configuration.VALUE)
              importRequest.updateConfigs.push({
                ...configFound,
                PREVIOUS_VALUE: configFound.VALUE,
                VALUE: configuration.VALUE,
                COMMENTS: configuration.COMMENTS,
              })
            else if (configFound.COMMENTS !== configuration.COMMENTS)
              importRequest.updateConfigs.push({
                ...configFound,
                PREVIOUS_VALUE: undefined,
                COMMENTS: configuration.COMMENTS,
              })
          } else {
            importRequest.importConfigs.push({
              ...configuration,
              GROUP_ID: GROUP_ID,
            })
          }
        }
      }
    }
    props.importFromFile(importRequest)
  } catch (err) {
    console.log(err)
    props.setError({
      type: "Import Configurations",
      message:
        "Incorrect format of XML file. Please review Appendix A in the ConfigTool User Guide for the correct XML format.",
      isFatal: false,
      errno: "1",
      code: "ERROR_PARSING_XML",
    })
  }
}
