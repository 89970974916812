import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table'
import { manageModal, loadState } from 'redux/actions/manageModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class StateTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expand: false,
      STATE_ID: '',
      STATE_DESC: [],
      COLUMN_SORT: 'State ID',
      desc: true,
      EDIT_STATE: {}
    }
    this.filteredValues = this.filteredValues.bind(this)
    this.handleRowClick = this.handleRowClick.bind(this)
  }

  filteredValues = () => {
    const category = this.props.category
    const query = this.props.query
    const filteredData = this.props.configs.filter(element => {
      element.STATE_ID = element.STATE_ID !== null ? element.STATE_ID : ''
      element.BUSINESS_REF_NO = element.BUSINESS_REF_NO !== null ? element.BUSINESS_REF_NO : ''
      element.IS_FATAL = element.IS_FATAL !== null ? element.IS_FATAL : ''
      element.KAC_REQUIRED = element.KAC_REQUIRED !== null ? element.KAC_REQUIRED : ''
      element.STAMPING_REQUIRED = element.STAMPING_REQUIRED !== null ? element.STAMPING_REQUIRED : ''
      element.USE_FOR_REP = element.USE_FOR_REP !== null ? element.USE_FOR_REP : ''
      element.REP_CATEGORY = element.REP_CATEGORY !== null ? element.REP_CATEGORY : ''
      element.STATE_CATEGORY =
        element.STATE_CATEGORY !== null && element.STATE_CATEGORY !== undefined ? element.STATE_CATEGORY : ''
      if (category === 'Business Reference Number')
        return element.BUSINESS_REF_NO.toString().includes(query.toLowerCase())
      else if (category === 'Is Fatal') return element.IS_FATAL.toString().includes(query)
      else if (category === 'KAC Required') return element.KAC_REQUIRED.toString().includes(query)
      else if (category === 'KAC Required') return element.STAMPING_REQUIRED.toString().includes(query)
      else if (category === 'Use For Rep') return element.USE_FOR_REP.toString().includes(query)
      else if (category === 'Rep Category') return element.REP_CATEGORY.toLowerCase().includes(query.toLowerCase())
      else if (category === 'State Category') return element.STATE_CATEGORY.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Any') {
        return (
          element.STATE_ID.toLowerCase().includes(query.toLowerCase()) ||
          element.REP_CATEGORY.toLowerCase().includes(query.toLowerCase()) ||
          element.STATE_CATEGORY.toLowerCase().includes(query.toLowerCase()) ||
          element.IS_FATAL.toString().includes(query) ||
          element.KAC_REQUIRED.toString().includes(query) ||
          element.STAMPING_REQUIRED.toString().includes(query) ||
          element.BUSINESS_REF_NO.toString().includes(query.toLowerCase()) ||
          element.USE_FOR_REP.toString().includes(query)
        )
      } else return element.STATE_ID.toLowerCase().includes(this.props.query.toLowerCase())
    })

    if (this.state.COLUMN_SORT === 'State ID') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.STATE_ID.toLowerCase() > b.STATE_ID.toLowerCase()
            ? 1
            : b.STATE_ID.toLowerCase() > a.STATE_ID.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.STATE_ID.toLowerCase() > b.STATE_ID.toLowerCase()
            ? -1
            : b.STATE_ID.toLowerCase() > a.STATE_ID.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Business Reference Number') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.BUSINESS_REF_NO > b.BUSINESS_REF_NO ? 1 : b.BUSINESS_REF_NO > a.BUSINESS_REF_NO ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.BUSINESS_REF_NO > b.BUSINESS_REF_NO ? -1 : b.BUSINESS_REF_NO > a.BUSINESS_REF_NO ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Is Fatal') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.IS_FATAL > b.IS_FATAL ? 1 : b.IS_FATAL > a.IS_FATAL ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.IS_FATAL > b.IS_FATAL ? -1 : b.IS_FATAL > a.IS_FATAL ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'KAC Required') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.KAC_REQUIRED > b.KAC_REQUIRED ? 1 : b.KAC_REQUIRED > a.KAC_REQUIRED ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.KAC_REQUIRED > b.KAC_REQUIRED ? -1 : b.KAC_REQUIRED > a.KAC_REQUIRED ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Stamping Required') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.STAMPING_REQUIRED > b.STAMPING_REQUIRED ? 1 : b.STAMPING_REQUIRED > a.STAMPING_REQUIRED ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.STAMPING_REQUIRED > b.STAMPING_REQUIRED ? -1 : b.STAMPING_REQUIRED > a.STAMPING_REQUIRED ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Use For Rep') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.USE_FOR_REP > b.USE_FOR_REP ? 1 : b.USE_FOR_REP > a.USE_FOR_REP ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.USE_FOR_REP > b.USE_FOR_REP ? -1 : b.USE_FOR_REP > a.USE_FOR_REP ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Rep Category') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.REP_CATEGORY.toLowerCase() > b.REP_CATEGORY.toLowerCase()
            ? 1
            : b.REP_CATEGORY.toLowerCase() > a.REP_CATEGORY.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.REP_CATEGORY.toLowerCase() > b.REP_CATEGORY.toLowerCase()
            ? -1
            : b.REP_CATEGORY.toLowerCase() > a.REP_CATEGORY.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'State Category') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.STATE_CATEGORY.toLowerCase() > b.STATE_CATEGORY.toLowerCase()
            ? 1
            : b.STATE_CATEGORY.toLowerCase() > a.STATE_CATEGORY.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.STATE_CATEGORY.toLowerCase() > b.STATE_CATEGORY.toLowerCase()
            ? -1
            : b.STATE_CATEGORY.toLowerCase() > a.STATE_CATEGORY.toLowerCase()
            ? 1
            : 0
        })
    }

    return filteredData
  }

  handleRowClick = state => {
    if (
      state.STATE_ID === this.state.STATE_ID &&
      !state.state_desc_id &&
      JSON.stringify(state) !== JSON.stringify(this.state.EDIT_STATE)
    ) {
      this.setState({ expand: !this.state.expand })
    } else {
      this.setState({ expand: true })
    }
    let descExists = []
    for (let desc of this.props.stateDesc) {
      if (desc.STATE_ID === state.STATE_ID) {
        descExists.push(desc)
      }
    }

    if (JSON.stringify(state) === JSON.stringify(this.state.EDIT_STATE) && !state.state_desc_id && this.state.expand) {
      let editState = { editState: { ...state, STATE_DESC: this.state.STATE_DESC } }
      console.log('def')
      console.log(editState)
      this.props.loadState(editState)
      this.props.manageModal({ currentModal: 'editStateDefMenuShow' })
    } else if (JSON.stringify(state) === JSON.stringify(this.state.EDIT_STATE) && !!state.state_desc_id) {
      let editState = { editState: { ...state } }
      console.log('desc')
      console.log(editState)
      this.props.loadState(editState)
      this.props.manageModal({ currentModal: 'editStateDescMenuShow' })
    } else {
      this.setState({
        EDIT_STATE: state
      })
    }

    this.setState({
      STATE_DESC: descExists,
      STATE_ID: state.STATE_ID
    })
  }

  handleHeaderClick = column => {
    if (column === this.state.COLUMN_SORT) {
      this.setState({
        desc: !this.state.desc
      })
    } else {
      this.setState({
        COLUMN_SORT: column,
        desc: true
      })
    }
  }

  handleColumnSelects = data => {
    if (data === this.state.COLUMN_SORT) {
      if (this.state.desc) return { borderTop: '2px solid #3F4766' }
      else return { borderBottom: '2px solid #3F4766' }
    } else {
      return null
    }
  }

  handleRowSelects = state => {
    if (!state.state_desc_id) {
      //state def
      return JSON.stringify(state) === JSON.stringify(this.state.EDIT_STATE) && this.state.expand
        ? { background: '#C8E3EF' }
        : null
    } else if (!!state.state_desc_id) {
      //state desc
      return JSON.stringify(state) === JSON.stringify(this.state.EDIT_STATE)
        ? { background: '#C8E3EF' }
        : { background: '#FFFFFF' }
    }
  }

  render() {
    return (
      <div className="Table">
        <Table responsive bordered hover striped aria-controls="collapse-table-row" aria-expanded={this.state.expand}>
          <thead>
            <tr className="d-flex">
              <th
                className="col-sm-3"
                style={this.handleColumnSelects('State ID')}
                onClick={() => this.handleHeaderClick('State ID')}
              >
                <FontAwesomeIcon icon="lock" /> State ID
              </th>
              <th
                className="col-sm-2"
                style={this.handleColumnSelects('Business Reference Number')}
                onClick={() => this.handleHeaderClick('Business Reference Number')}
              >
                Business Reference Number
              </th>
              <th
                className="col-sm-1"
                style={this.handleColumnSelects('Is Fatal')}
                onClick={() => this.handleHeaderClick('Is Fatal')}
              >
                Is Fatal
              </th>
              <th
                className="col-sm-1"
                style={this.handleColumnSelects('KAC Required')}
                onClick={() => this.handleHeaderClick('KAC Required')}
              >
                KAC Required
              </th>
              <th
                className="col-sm-2"
                style={this.handleColumnSelects('Stamping Required')}
                onClick={() => this.handleHeaderClick('Stamping Required')}
              >
                Stamping Required
              </th>
              <th
                className="col-sm-1"
                style={this.handleColumnSelects('Use For Rep')}
                onClick={() => this.handleHeaderClick('Use For Rep')}
              >
                Use For Rep
              </th>
              <th
                className="col-sm-1"
                style={this.handleColumnSelects('Rep Category')}
                onClick={() => this.handleHeaderClick('Rep Category')}
              >
                Rep Category
              </th>
              <th
                className="col-sm-1"
                style={this.handleColumnSelects('State Category')}
                onClick={() => this.handleHeaderClick('State Category')}
              >
                State Category
              </th>
            </tr>
          </thead>
          <tbody>
            {this.filteredValues().map((configuration, index) => {
              return (
                <div>
                  <tr
                    className="d-flex"
                    key={this.props.database + index}
                    style={this.handleRowSelects(configuration)}
                    onClick={() => this.handleRowClick(configuration)}
                  >
                    <td className="col-sm-3"> {configuration.STATE_ID} </td>
                    <td className="col-sm-2"> {configuration.BUSINESS_REF_NO} </td>
                    <td className="col-sm-1"> {configuration.IS_FATAL} </td>
                    <td className="col-sm-1"> {configuration.KAC_REQUIRED} </td>
                    <td className="col-sm-2"> {configuration.STAMPING_REQUIRED} </td>
                    <td className="col-sm-1"> {configuration.USE_FOR_REP} </td>
                    <td className="col-sm-1"> {configuration.REP_CATEGORY} </td>
                    <td className="col-sm-1"> {configuration.STATE_CATEGORY} </td>
                  </tr>

                  {configuration.STATE_ID === this.state.STATE_ID && this.state.STATE_DESC.length > 0 && (
                    <div id="example-collapse-text" className="stateDesc">
                      <tr className="d-flex">
                        <th className="col-sm-2">
                          <FontAwesomeIcon icon="lock" /> Language Code
                        </th>
                        <th className="col-sm-3">Short Description</th>
                        <th className="col-sm-1">KAC Message</th>
                        <th className="col-sm-3">Stamping Message</th>
                        <th className="col-sm-3">Screen Message</th>
                      </tr>
                      {this.props.stateDesc
                        .filter(e => {
                          return this.state.STATE_ID === e.STATE_ID
                        })
                        .map((elem, i) => {
                          return (
                            <tr
                              key={elem.STATE_ID + i}
                              className="d-flex"
                              style={this.handleRowSelects(elem)}
                              onClick={() => this.handleRowClick(elem)}
                            >
                              <td className="col-sm-2">{elem.LANGUAGE_CODE}</td>
                              <td className="col-sm-3">{elem.SHORT_DESC}</td>
                              <td className="col-sm-1">{elem.KAC_MSG}</td>
                              <td className="col-sm-3">{elem.STAMPING_MSG}</td>
                              <td className="col-sm-3">{elem.SCREEN_MSG}</td>
                            </tr>
                          )
                        })}
                    </div>
                  )}
                </div>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.stateDef,
    stateDesc: state.loadData.stateDesc,
    query: state.filterData.query,
    category: state.filterData.filterColumn,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadState, manageModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StateTable)
