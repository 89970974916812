import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { manageModal, loadCountry } from 'redux/actions/manageModal'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CountryTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      COLUMN_SORT: 'Country Name',
      desc: true,
      EDIT_CONFIG: {}
    }
  }

  filteredValues = () => {
    const category = this.props.category
    const query = this.props.query
    const filteredData = this.props.countries.filter(element => {
      element.COUNTRY_NAME = !!element.COUNTRY_NAME ? element.COUNTRY_NAME : ''
      element.COUNTRY_CODE2 = !!element.COUNTRY_CODE2 ? element.COUNTRY_CODE2 : ''
      element.COUNTRY_CODE3 = !!element.COUNTRY_CODE3 ? element.COUNTRY_CODE3 : ''
      element.COUNTRY_NUMBER = element.COUNTRY_NUMBER !== null ? element.COUNTRY_NUMBER : ''
      element.LANGUAGE_CODE = !!element.LANGUAGE_CODE ? element.LANGUAGE_CODE : ''

      if (category === 'Country Name') return element.COUNTRY_NAME.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Code 2') return element.COUNTRY_CODE2.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Code 3') return element.COUNTRY_CODE3.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Number') return element.COUNTRY_NUMBER.toString().includes(query)
      else if (category === 'Language Code') return element.LANGUAGE_CODE.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Any')
        return (
          element.COUNTRY_NAME.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_CODE2.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_CODE3.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_NUMBER.toString().includes(query) ||
          element.LANGUAGE_CODE.toLowerCase().includes(query.toLowerCase())
        )
      else return element.COUNTRY_NAME.toLowerCase().includes(this.props.query.toLowerCase())
    })

    if (this.state.COLUMN_SORT === 'Country Name') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NAME > b.COUNTRY_NAME ? 1 : b.COUNTRY_NAME > a.COUNTRY_NAME ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NAME > b.COUNTRY_NAME ? -1 : b.COUNTRY_NAME > a.COUNTRY_NAME ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Code 2') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE2 > b.COUNTRY_CODE2 ? 1 : b.COUNTRY_CODE2 > a.COUNTRY_CODE2 ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE2 > b.COUNTRY_CODE2 ? -1 : b.COUNTRY_CODE2 > a.COUNTRY_CODE2 ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Code 3') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE3 > b.COUNTRY_CODE3 ? 1 : b.COUNTRY_CODE3 > a.COUNTRY_CODE3 ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE3 > b.COUNTRY_CODE3 ? -1 : b.COUNTRY_CODE3 > a.COUNTRY_CODE3 ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Number') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NUMBER > b.COUNTRY_NUMBER ? 1 : b.COUNTRY_NUMBER > a.COUNTRY_NUMBER ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_NUMBER > b.COUNTRY_NUMBER ? -1 : b.COUNTRY_NUMBER > a.COUNTRY_NUMBER ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Language Code') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.LANGUAGE_CODE.toLowerCase() > b.LANGUAGE_CODE.toLowerCase()
            ? 1
            : b.LANGUAGE_CODE.toLowerCase() > a.LANGUAGE_CODE.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.LANGUAGE_CODE.toLowerCase() > b.LANGUAGE_CODE.toLowerCase()
            ? -1
            : b.LANGUAGE_CODE.toLowerCase() > a.LANGUAGE_CODE.toLowerCase()
            ? 1
            : 0
        })
    }
    return filteredData
  }

  handleRowClick = data => {
    console.log(data)
    if (JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG)) {
      this.props.loadCountry({ editCountry: this.state.EDIT_CONFIG })
      this.props.manageModal({ currentModal: 'editCountryMenuShow' })
    } else {
      this.setState({
        EDIT_CONFIG: data
      })
    }
  }

  handleHeaderClick = column => {
    if (column === this.state.COLUMN_SORT) {
      this.setState({
        desc: !this.state.desc
      })
    } else {
      this.setState({
        COLUMN_SORT: column,
        desc: true
      })
    }
  }

  handleColumnSelects = data => {
    if (data === this.state.COLUMN_SORT) {
      if (this.state.desc) return { borderTop: '2px solid #3F4766' }
      else return { borderBottom: '2px solid #3F4766' }
    } else {
      return null
    }
  }

  handleRowSelects = data => {
    return JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG) ? { background: '#C8E3EF' } : null
  }

  render() {
    return (
      <div className="Table">
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th
                style={this.handleColumnSelects('Country Name')}
                onClick={() => this.handleHeaderClick('Country Name')}
              >
                Country Name
              </th>
              <th
                style={this.handleColumnSelects('Country Code 2')}
                onClick={() => this.handleHeaderClick('Country Code 2')}
              >
                <FontAwesomeIcon icon="lock" /> Country Code 2
              </th>
              <th
                style={this.handleColumnSelects('Country Code 3')}
                onClick={() => this.handleHeaderClick('Country Code 3')}
              >
                Country Code 3
              </th>
              <th
                style={this.handleColumnSelects('Country Number')}
                onClick={() => this.handleHeaderClick('Country Number')}
              >
                Country Number
              </th>
              <th
                style={this.handleColumnSelects('Language Code')}
                onClick={() => this.handleHeaderClick('Language Code')}
              >
                <FontAwesomeIcon icon="lock" /> Language Code
              </th>
            </tr>
          </thead>
          <tbody>
            {this.filteredValues().map((configuration, index) => {
              return (
                <tr
                  key={this.props.database + index}
                  style={this.handleRowSelects(configuration)}
                  onClick={() => this.handleRowClick(configuration)}
                >
                  <td> {configuration.COUNTRY_NAME} </td>
                  <td> {configuration.COUNTRY_CODE2} </td>
                  <td> {configuration.COUNTRY_CODE3} </td>
                  <td> {configuration.COUNTRY_NUMBER} </td>
                  <td> {configuration.LANGUAGE_CODE} </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    countries: state.loadData.countries,
    query: state.filterData.query,
    category: state.filterData.filterColumn,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ manageModal, loadCountry }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryTable)
