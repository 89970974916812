import {
  CHANGE_MODAL,
  HIDE_MODAL,
  EDIT_CONFIG,
  EDIT_STATE,
  EDIT_PROPERTY,
  EDIT_COUNTRY,
  EDIT_COUNTRY_INDEX,
  EDIT_CITY,
  EDIT_PROFILE_ACCESS_KEYS
} from '../constants/action-types'

export function manageModal(payload) {
  return { type: CHANGE_MODAL, payload }
}

export function loadConfig(payload) {
  return { type: EDIT_CONFIG, payload }
}

export function loadState(payload) {
  return { type: EDIT_STATE, payload }
}

export function loadProperty(payload) {
  return { type: EDIT_PROPERTY, payload }
}

export function loadCountry(payload) {
  return { type: EDIT_COUNTRY, payload }
}

export function loadCountryIndex(payload) {
  return { type: EDIT_COUNTRY_INDEX, payload }
}

export function loadCity(payload) {
  return { type: EDIT_CITY, payload }
}

export function loadProfileId(payload) {
  return { type: EDIT_PROFILE_ACCESS_KEYS, payload }
}

export function closeModal() {
  return { type: HIDE_MODAL }
}
