import {
  CONFIGDATA_LOADED,
  CONFIGGROUPS_LOADED,
  CONFIGSCOPES_LOADED,
  STATE_DEF_LOADED,
  STATE_DESC_LOADED,
  PROPERTIES_LOADED,
  PROFILES_LOADED,
  USERS_LOADED,
  ACCESS_KEYS_LOADED,
  CITIES_LOADED,
  COUNTRIES_LOADED,
  COUNTRY_INDEXES_LOADED,
  RESET_ERROR,
  SET_ERROR,
} from "../constants/action-types"
import authentication from "../../services/authentication"

const serverURL = window.config.serverURL

export function fetchConfigValues() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getConfigs", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: CONFIGDATA_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchConfigValues error: ", err)
      })
  }
}

export function fetchConfigGroups() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getGroups", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: CONFIGGROUPS_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchConfigGroups error: ", err)
      })
  }
}

export function fetchConfigScopes() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return (dispatch) => {
    return fetch(serverURL + "/getScopes", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: CONFIGSCOPES_LOADED, payload: data })
        return data
      })
      .catch((err) => {
        console.log("fetchConfigScopes error: ", err)
        return err
      })
  }
}

export function fetchStateDef() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getStateDef", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: STATE_DEF_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchStateDef error: ", err)
      })
  }
}

export function fetchStateDesc() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getStateDesc", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: STATE_DESC_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchStateDesc error: ", err)
      })
  }
}

export function fetchProperties() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getProperties", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: PROPERTIES_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchProperties error: ", err)
      })
  }
}

export function fetchProfiles() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getProfiles", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: PROFILES_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchProfiles error: ", err)
      })
  }
}

export function fetchUsers() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getUsers", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: USERS_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchUsers error: ", err)
      })
  }
}

export function fetchAccessKeys() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getProfiles/getAccessKeys", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: ACCESS_KEYS_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchAccessKeys error: ", err)
      })
  }
}

export function fetchCity() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getRegion/city", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: CITIES_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchCity error: ", err)
      })
  }
}

export function fetchCountry() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getRegion/country", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: COUNTRIES_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchCountry error: ", err)
      })
  }
}

export function fetchCountryIndex() {
  const { token } = authentication()
  const bearer = "Bearer " + token
  return function (dispatch) {
    return fetch(serverURL + "/getRegion/countryIndex", {
      method: "GET",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: COUNTRY_INDEXES_LOADED, payload: data })
      })
      .catch((err) => {
        console.log("fetchCountryIndex error: ", err)
      })
  }
}

export function resetError() {
  return { type: RESET_ERROR }
}

export function setError(payload) {
  return { type: SET_ERROR, payload }
}
