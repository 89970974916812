import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { saveCountryIndex, deleteCountryIndex } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class EditCountryIndexIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editCountryIndex !== this.props.editCountryIndex) {
      this.setState({
        ...nextProps.editCountryIndex
      })
    }
  }

  // componentDidUpdate(prevProps){
  //     if (JSON.stringify(this.props.editCountryIndex) !== JSON.stringify(prevProps.editCountryIndex)) {
  //         this.setState({
  //             ...this.props.editCountryIndex
  //         })
  //     }
  // }

  handleCountryCode2 = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_CODE2: value
    })
  }

  handleCountryIndex = event => {
    const value = event.target.value.toUpperCase().substr(0, 3)
    console.log(value)
    this.setState({
      COUNTRY_INDEX: value
    })
  }

  hasDuplicates = () => {
    const array = this.props.countryIndexes.filter(e => {
      return (
        e.COUNTRY_CODE2 === this.state.COUNTRY_CODE2 &&
        e.COUNTRY_INDEX === this.state.COUNTRY_INDEX &&
        e.COUNTRY_INDEX !== this.props.editCountryIndex.COUNTRY_INDEX
      )
    })
    console.log(array)
    if (array.length > 0) {
      this.setState({
        error: 'Country index already exists for this country'
      })
      return true
    }
    return false
  }

  isChanged = () => {
    let prevProp = this.props.editCountryIndex
    return (
      (this.state.COUNTRY_INDEX !== prevProp.COUNTRY_INDEX || this.state.COUNTRY_CODE2 !== prevProp.COUNTRY_CODE2) &&
      this.state.COUNTRY_INDEX.length > 2
    )
  }

  saveCountryIndex = () => {
    if (!this.hasDuplicates()) {
      const req = { ...this.state, OLD_COUNTRY_INDEX: this.props.editCountryIndex.COUNTRY_INDEX }
      console.log(req)
      this.props.saveCountryIndex(req)
      this.resetForm()
      this.props.closeModal()
    }
  }

  deleteCountryIndex = () => {
    const req = { ...this.props.editCountryIndex }
    console.log(req)
    this.props.deleteCountryIndex(req)
    this.resetForm()
    this.props.closeModal()
  }

  render() {
    let footer = (
      <Modal.Footer>
        {!!this.state.error && <p className="error">{this.state.error}</p>}
        <Button variant="danger" onClick={this.deleteCountryIndex}>
          Delete
        </Button>
        <Button variant="success" onClick={this.saveCountryIndex} disabled={!this.isChanged()}>
          Save
        </Button>
      </Modal.Footer>
    )

    const isView = this.props.text.includes('View')
    if (isView) {
      footer = null
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Country Code 2<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="COUNTRY_CODE2"
                  value={this.state.COUNTRY_CODE2}
                  onChange={this.handleCountryCode2}
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Country Index<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="COUNTRY_INDEX"
                  value={this.state.COUNTRY_INDEX}
                  onChange={this.handleCountryIndex}
                  disabled={isView}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    countryIndexes: state.loadData.countryIndexes,
    countries: state.loadData.countries,
    editCountryIndex: state.manageModal.editCountryIndex,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveCountryIndex, deleteCountryIndex, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCountryIndexIndex)
