import {GRANT_ACCESS, REMOVE_ACCESS, COMMIT_GRANT_ACCESS, CLEAR_ACCESS, COMMIT_REMOVE_ACCESS } from "../constants/action-types";

const initialState = {
    grant: [],
    remove: [],
    commitGrant: [],
    commitRemove: []
}

function editConfigAccess (state = initialState, action){
    if(action.type === GRANT_ACCESS){
        if (action.payload.config){
            return {
                ...state,
                grant: [...state.grant, ...action.payload.config]
            }
        } else {
            return {
                ...state,
                grant: [...state.grant.slice(0, action.payload.i), ...state.grant.slice(action.payload.i + 1)]   
            }
        }
    }
    if(action.type === REMOVE_ACCESS){
        if (action.payload.config){
            return {
                ...state,
                remove: [...state.remove, ...action.payload.config]
            }
        } else {
            return {
                ...state,
                remove: [...state.remove.slice(0, action.payload.i), ...state.remove.slice(action.payload.i + 1)]   
            }
        }
    }
    if(action.type === COMMIT_GRANT_ACCESS){
        let newCommitRemove = [...state.commitRemove]
        let newPayload = [...action.payload]
        let c = 0
        for (let i in state.commitRemove){
            for (let r in action.payload){
                if(state.commitRemove.indexOf(state.commitRemove[i]) !== -1 && action.payload.indexOf(action.payload[r]) !== -1){
                    if(newCommitRemove[i-c] === newPayload[r-c]){              
                        newPayload = [...newPayload.slice(0, r-c), ...newPayload.slice(r-c + 1)]
                        newCommitRemove = [...newCommitRemove.slice(0, i-c), ...newCommitRemove.slice(i-c + 1)]
                        c++
                    }
                } 
            }
        }
        return {
            ...state,
            grant: [],
            commitRemove: newCommitRemove,
            commitGrant: [...state.commitGrant,...newPayload]
        }
    }
    if (action.type === COMMIT_REMOVE_ACCESS){
        let newCommitGrant = [...state.commitGrant]
        let newPayload = [...action.payload]
        let c = 0
        for (let i in state.commitGrant){            
            for (let r in action.payload){
                if(state.commitGrant.indexOf(state.commitGrant[i]) !== -1 && action.payload.indexOf(action.payload[r]) !== -1){
                    if(newCommitGrant[i-c] === newPayload[r-c]){ 
                        newPayload = [...newPayload.slice(0, r-c), ...newPayload.slice(r-c + 1)]
                        newCommitGrant = [...newCommitGrant.slice(0, i-c), ...newCommitGrant.slice(i-c + 1)]
                        c++
                    }
                }   
            }
        }
        return {
            ...state,
            remove: [],
            commitGrant: newCommitGrant,
            commitRemove: [...state.commitRemove,...newPayload]
        }
    }
    if(action.type === CLEAR_ACCESS){
        return initialState
    }
    return state
}

export default editConfigAccess;