import { POST_REQUEST_RESPONSE, CLOSE_NOTIF } from "../constants/action-types";

const initialState = {
    response: []
}
 
function postData (state = initialState, action) {

  if (action.type === POST_REQUEST_RESPONSE) {
    console.log('Response:',action)
    if(!!action.payload){
      if(!action.payload.error){
        action.date = Date.now()
        return {
          ...state,
          response: [...state.response, action]
        }
      }
    }
  }
  if (action.type === CLOSE_NOTIF) {
    let newResponse = [...state.response]
    const index = newResponse.map(e => {return e.date}).indexOf(action.payload)
    newResponse.splice(index, 1)
    return {
      ...state,
      response: newResponse
    }
  }

  return state;
}

export default postData;