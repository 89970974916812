import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeModal } from 'redux/actions/manageModal'
import { deleteConfig, saveConfig } from 'redux/actions/postData'
import { jsonViewer } from 'utils/helper'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'

class EditConfigMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteConfirmed: false
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.editConfig) !== JSON.stringify(prevProps.editConfig)) {
      this.setState({
        ...this.props.editConfig,
        PROFILE_ID: this.props.PROFILE_ID,
        isAdmin: this.props.isAdmin,
        deleteConfirmed: false
      })
    }
  }

  handleValue = event => {
    const value = event.target.value
    this.setState({
      VALUE: value,
      deleteConfirmed: false
    })
  }

  handleDescription = event => {
    const description = event.target.value
    this.setState({
      COMMENTS: description,
      deleteConfirmed: false
    })
  }

  handleGroupName = event => {
    const value = event.target.value
    this.setState({
      GROUP_ID: value,
      deleteConfirmed: false
    })
  }

  // findGroupName = (GROUP_ID) => {
  //     let name = this.props.groups.find( e => e.GROUP_ID === GROUP_ID)
  //     if (!!name)
  //         return name.GROUP_NAME
  //     return name
  // }

  saveConfig = () => {
    let req = { ...this.state }
    console.log(req)
    if (this.state.GROUP_ID !== this.props.editConfig.GROUP_ID)
      req = { ...req, OLD_GROUP_ID: this.props.editConfig.GROUP_ID }
    if (this.state.VALUE !== this.props.editConfig.VALUE) req = { ...req, PREVIOUS_VALUE: this.props.editConfig.VALUE }
    this.props.saveConfig(req)
    this.resetForm()
    this.props.closeModal()
  }

  deleteConfig = () => {
    if (this.state.deleteConfirmed) {
      this.props.deleteConfig({ ...this.props.editConfig })
      this.resetForm()
      this.props.closeModal()
    }
    this.setState({ deleteConfirmed: true })
  }

  isChanged = () => {
    let prevProp = this.props.editConfig
    return (
      this.state.VALUE !== prevProp.VALUE ||
      this.state.COMMENTS !== prevProp.COMMENTS ||
      this.state.GROUP_ID !== prevProp.GROUP_ID
    )
  }

  render() {
    const isView = this.props.text.includes('View')
    let footer = (
      <Modal.Footer>
        {this.state.deleteConfirmed ? (
          <Alert variant="info">Are you sure? Do you want to delete this configuration values? </Alert>
        ) : (
          ''
        )}
        <Button variant="danger" onClick={this.deleteConfig}>
          {this.state.deleteConfirmed ? 'Confirm' : 'Delete'}
        </Button>
        <Button variant="success" onClick={this.saveConfig} disabled={!this.isChanged()}>
          Save
        </Button>
      </Modal.Footer>
    )

    if (isView) {
      footer = null
    }

    const jsonView = jsonViewer(this.state.VALUE)

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextConfigKey">
              <Form.Label column sm="2">
                Config Key:
              </Form.Label>
              <Col sm="10">
                <Form.Control type="CONFIG_KEY" value={this.state.CONFIG_KEY} disabled />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="2">
                Scope:
              </Form.Label>
              <Col sm="10">
                <Form.Control type="SCOPE" value={this.state.SCOPE_NAME} disabled />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextPrevValue">
              <Form.Label column sm="2">
                Previous Value:
              </Form.Label>
              <Col sm="10">
                <Form.Control type="LAST_VALUE" value={this.state.PREVIOUS_VALUE} disabled />
              </Col>
            </Form.Group>
            {jsonView && jsonView.isValidJson ? (
              <Form.Group as={Row} controlId="formPlaintextValue">
                <Form.Label column sm="2">
                  Value (JSON View):
                </Form.Label>
                <Col sm="10">{jsonView.view}</Col>
              </Form.Group>
            ) : (
              ''
            )}
            <Form.Group as={Row} controlId="formPlaintextValue">
              <Form.Label column sm="2">
                Value:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={this.state.VALUE}
                  onChange={this.handleValue}
                  disabled={this.props.text.includes('View')}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="2">
                Group<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="10">
                <Form.Control as="select" onChange={this.handleGroupName} value={this.state.GROUP_ID} disabled={isView}>
                  {this.props.groups.map(e => {
                    return (
                      <option key={'dropdown-group-name-' + e.GROUP_ID} value={e.GROUP_ID}>
                        {e.GROUP_NAME}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextDescription">
              <Form.Label column sm="2">
                {' '}
                Description:{' '}
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={this.state.COMMENTS}
                  onChange={this.handleDescription}
                  disabled={isView}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    groups: state.loadData.groups,
    editConfig: state.manageModal.editConfig,
    database: state.accountLogin.database,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    isAdmin: state.accountLogin.isAdmin
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteConfig, saveConfig, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditConfigMenu)
