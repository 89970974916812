import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { saveProperty, deleteProperty } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'

class EditPropertiesMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.editProperty) !== JSON.stringify(prevProps.editProperty)) {
      this.setState({
        ...this.props.editProperty
      })
    }
  }

  handleSectionName = event => {
    let value = this.state.SECTION_NAME
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }
    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        SECTION_NAME: value
      })
    }
  }

  handlePropertyName = event => {
    let value = this.state.PROPERTY_NAME
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }
    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        PROPERTY_NAME: value
      })
    }
  }

  handleClientType = event => {
    let value = this.state.SECTION_NAME
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }
    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        CLIENT_TYPE: value
      })
    }
  }

  handlePropertyValue = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      PROPERTY_VALUE: value
    })
  }

  configExists = () => {
    for (let config of this.props.properties) {
      if (config.SECTION_NAME === this.state.SECTION_NAME && config.PROPERTY_NAME === this.state.PROPERTY_NAME)
        return true
    }
    return false
  }

  getUniqueList = category => {
    let list = []
    if (category === 'SECTION_NAME') {
      list = this.props.properties.map(elem => {
        return elem.SECTION_NAME
      })
    }
    if (category === 'PROPERTY_NAME') {
      list = this.props.properties.map(elem => {
        return elem.PROPERTY_NAME
      })
    }
    if (category === 'CLIENT_TYPE') {
      list = this.props.properties.map(elem => {
        return elem.CLIENT_TYPE
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  isChanged = () => {
    let prevProp = this.props.editProperty
    return (
      this.state.SECTION_NAME !== prevProp.SECTION_NAME ||
      this.state.PROPERTY_NAME !== prevProp.PROPERTY_NAME ||
      this.state.PROPERTY_VALUE !== prevProp.PROPERTY_VALUE ||
      this.state.CLIENT_TYPE !== prevProp.CLIENT_TYPE
    )
  }

  saveConfig = () => {
    const req = {
      ...this.state,
      original: this.props.editProperty,
      isNewPropertiesTable: this.props.isNewPropertiesTable
    }
    console.log(req)
    this.props.saveProperty(req)
    this.setState({})
    this.props.closeModal()
  }

  deleteConfig = () => {
    const req = { ...this.props.editProperty }
    console.log(req)
    this.props.deleteProperty(req)
    this.setState({})
    this.props.closeModal()
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="danger" onClick={this.deleteConfig}>
          Delete
        </Button>
        <Button variant="success" onClick={this.saveConfig} disabled>
          Save
        </Button>
      </Modal.Footer>
    )

    if (this.isChanged()) {
      footer = (
        <Modal.Footer>
          <Button variant="danger" onClick={this.deleteConfig}>
            Delete
          </Button>
          <Button variant="success" onClick={this.saveConfig}>
            Save
          </Button>
        </Modal.Footer>
      )
    }

    const isView = this.props.text.includes('View')
    if (isView) {
      footer = null
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Section Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('SECTION_NAME')}
                  type="SECTION_NAME"
                  id="SECTION_NAME"
                  selected={[this.state.SECTION_NAME]}
                  onChange={this.handleSectionName}
                  onInputChange={this.handleSectionName}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Property Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('PROPERTY_NAME')}
                  type="PROPERTY_NAME"
                  id="PROPERTY_NAME"
                  selected={[this.state.PROPERTY_NAME]}
                  onChange={this.handlePropertyName}
                  onInputChange={this.handlePropertyName}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            {this.props.isNewPropertiesTable && (
              <Form.Group as={Row} controlId="formPlaintextScope">
                <Form.Label column sm="4">
                  Client Type<p className="asterisk"> * </p>:
                </Form.Label>
                <Col sm="8">
                  <Typeahead
                    options={this.getUniqueList('CLIENT_TYPE')}
                    type="CLIENT_TYPE"
                    id="CLIENT_TYPE"
                    selected={[this.state.CLIENT_TYPE]}
                    onChange={this.handleClientType}
                    onInputChange={this.handleClientType}
                    emptyLabel={false}
                    highlightOnlyResult
                    disabled={isView}
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Property Value:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="PROPERTY_VALUE"
                  value={this.state.PROPERTY_VALUE}
                  onChange={this.handlePropertyValue}
                  disabled={isView}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    properties: state.loadData.properties,
    editProperty: state.manageModal.editProperty,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database,
    isNewPropertiesTable: state.loadData.isNewPropertiesTable
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveProperty, deleteProperty, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPropertiesMenu)
