import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { manageModal, loadCountryIndex } from 'redux/actions/manageModal'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CountryIndexTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      COLUMN_SORT: 'Country Index',
      desc: true,
      EDIT_CONFIG: {}
    }
  }

  filteredValues = () => {
    const category = this.props.category
    const query = this.props.query
    const filteredData = this.props.countryIndexes.filter(element => {
      element.COUNTRY_INDEX = element.COUNTRY_INDEX !== null ? element.COUNTRY_INDEX : ''
      element.COUNTRY_CODE2 = !!element.COUNTRY_CODE2 ? element.COUNTRY_CODE2 : ''
      if (category === 'Country Index') return element.COUNTRY_INDEX.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Country Code 2') return element.COUNTRY_CODE2.toLowerCase().includes(query.toLowerCase())
      else if (category === 'Any')
        return (
          element.COUNTRY_INDEX.toLowerCase().includes(query.toLowerCase()) ||
          element.COUNTRY_CODE2.toLowerCase().includes(query.toLowerCase())
        )
      else return element.COUNTRY_INDEX.toLowerCase().includes(query.toLowerCase())
    })

    if (this.state.COLUMN_SORT === 'Country Index') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_INDEX > b.COUNTRY_INDEX ? 1 : b.COUNTRY_INDEX > a.COUNTRY_INDEX ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_INDEX > b.COUNTRY_INDEX ? -1 : b.COUNTRY_INDEX > a.COUNTRY_INDEX ? 1 : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Country Code 2') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE2 > b.COUNTRY_CODE2 ? 1 : b.COUNTRY_CODE2 > a.COUNTRY_CODE2 ? -1 : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.COUNTRY_CODE2 > b.COUNTRY_CODE2 ? -1 : b.COUNTRY_CODE2 > a.COUNTRY_CODE2 ? 1 : 0
        })
    }
    return filteredData
  }

  handleRowClick = data => {
    console.log(data)
    if (JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG)) {
      this.props.loadCountryIndex({ editCountryIndex: this.state.EDIT_CONFIG })
      this.props.manageModal({ currentModal: 'editCountryIndexMenuShow' })
    } else {
      this.setState({
        EDIT_CONFIG: data
      })
    }
  }

  handleHeaderClick = column => {
    if (column === this.state.COLUMN_SORT) {
      this.setState({
        desc: !this.state.desc
      })
    } else {
      this.setState({
        COLUMN_SORT: column,
        desc: true
      })
    }
  }

  handleColumnSelects = data => {
    if (data === this.state.COLUMN_SORT) {
      if (this.state.desc) return { borderTop: '2px solid #3F4766' }
      else return { borderBottom: '2px solid #3F4766' }
    } else {
      return null
    }
  }

  handleRowSelects = data => {
    return JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG) ? { background: '#C8E3EF' } : null
  }

  render() {
    return (
      <div className="Table">
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th
                style={this.handleColumnSelects('Country Index')}
                onClick={() => this.handleHeaderClick('Country Index')}
              >
                Country Index
              </th>
              <th
                style={this.handleColumnSelects('Country Code 2')}
                onClick={() => this.handleHeaderClick('Country Code 2')}
              >
                <FontAwesomeIcon icon="lock" /> Country Code 2
              </th>
            </tr>
          </thead>
          <tbody>
            {this.filteredValues().map((configuration, index) => {
              return (
                <tr
                  key={this.props.database + index}
                  style={this.handleRowSelects(configuration)}
                  onClick={() => this.handleRowClick(configuration)}
                >
                  <td> {configuration.COUNTRY_INDEX} </td>
                  <td> {configuration.COUNTRY_CODE2} </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    countryIndexes: state.loadData.countryIndexes,
    query: state.filterData.query,
    category: state.filterData.filterColumn,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ manageModal, loadCountryIndex }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryIndexTable)
