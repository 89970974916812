import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postScope, deleteScope } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class ScopeMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      SCOPE_NAME: '',
      SCOPE_TYPE: 'GLOBAL',
      scopeExists: []
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleScopeName = event => {
    let scopeName = event.target.textContent
    if (scopeName === '') {
      scopeName = event.target.value
    }
    let scopeExists = this.props.scopes.filter(scope => scopeName === scope.SCOPE_NAME)

    if (scopeExists.length > 0) {
      this.setState({
        SCOPE_TYPE: scopeExists[0].SCOPE_TYPE
      })
    }
    this.setState({
      SCOPE_NAME: scopeName,
      scopeExists: scopeExists
    })
  }

  handleScopeType = event => {
    const scopeType = event.target.value
    console.log(scopeType)
    this.setState({
      SCOPE_TYPE: scopeType
    })
  }

  addScope = () => {
    console.log(this.state)
    this.props.postScope({ ...this.state })
    console.log('scope Added')
    this.resetForm()
    this.props.closeModal()
  }

  deleteScope = () => {
    console.log(this.state)
    this.props.deleteScope({ ...this.state })
    console.log('scope Delete')
    this.resetForm()
    this.props.closeModal()
  }

  render() {
    let isAddDelete = this.state.SCOPE_NAME.length > 0 && !this.state.scopeExists.length > 0

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScopeName">
              <Form.Label column sm="3">
                Scope Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="9">
                <InputGroup>
                  <Form.Control
                    aria-label="Scope Name"
                    aria-describedby="basic-addon2"
                    value={this.state.SCOPE_NAME}
                    onChange={this.handleScopeName}
                  />

                  <DropdownButton
                    as={InputGroup.Append}
                    title=""
                    variant="outline-secondary"
                    id="input-group-dropdown-2"
                  >
                    {this.props.scopes.map((scope, index) => {
                      return (
                        <Dropdown.Item key={this.props.database + index} onClick={this.handleScopeName}>
                          {scope.SCOPE_NAME}
                        </Dropdown.Item>
                      )
                    })}
                  </DropdownButton>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScopeType">
              <Form.Label column sm="3">
                {' '}
                Scope Type:{' '}
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  onChange={this.handleScopeType}
                  value={this.state.SCOPE_TYPE}
                  disabled={this.state.scopeExists.length > 0}
                >
                  <option>GLOBAL</option>
                  <option>CHANNEL</option>
                  <option>STATION</option>
                  <option>KIOSKID</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.deleteScope} disabled={isAddDelete}>
            Delete
          </Button>
          <Button variant="success" onClick={this.addScope} disabled={!isAddDelete}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    scopes: state.loadData.scopes,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postScope, deleteScope, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScopeMenu)
