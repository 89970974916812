import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postBagtagSimulator, postConfigValue } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import airports from 'airport-codes-updated'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCode } from 'country-list'
import DatePicker from 'react-date-picker'

const TimaticResponses = {
  OK: 'OK',
  CONDITIONAL: 'CONDITIONAL',
  DENIED: 'DENIED',
  ERROR: 'ERROR'
}

const ADCResponses = {
  OK: 'OK',
  CONDITIONAL: 'CONDITIONAL',
  NOT_OK: 'NOT_OK',
  ERROR_NOT_AVAILABLE: 'ERROR_NOT_AVAILABLE',
  ERROR_EXIT_DATE: 'ERROR_EXIT_DATE',
  AUTO_BY_PASSED: 'AUTO_BY_PASSED'
}

class PNRBuilderMenu extends React.Component {
  constructor(props) {
    super(props)
    const today = new Date()
    this.initialExpiryDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
    this.state = {
      CONFIG_KEY: '',
      SCOPE_NAME: '',
      VALUE: '',
      GROUP_ID: '',
      COMMENTS: '',
      PROFILE_ID: '',
      PNR: Math.round(Math.pow(36, 6 + 1) - Math.random() * Math.pow(36, 6))
        .toString(36)
        .slice(1)
        .toUpperCase(),
      FLIGHT_TYPE: 'OW',
      FLIGHT_TIME: 'ON TIME',
      FLIGHT_LEG_LIST: [{ DEPARTURE: 'YYZ', ARRIVAL: 'YYC' }],
      PROGRESS: 0,
      SECTIONS: ['FLIGHTS', 'PASSENGERS', 'BAGGAGE', 'BOOKING'],
      SECTION: 'FLIGHTS',
      CARRIER: 'E1',
      CARRIER_BAGTAG: '9998',
      PAX_LIST: [
        {
          type: 'ADULT',
          travelerNo: this.makeid(9),
          prefix: 'MR',
          firstName: 'MARK',
          lastName: 'SMITH',
          dob: new Date(1998, 0, 16),
          documentNo: 'AB123450',
          documentType: 'P',
          nationality: 'CAN',
          expiryDate: this.initialExpiryDate,
          checkinStatus: 'CHECKED_IN',
          bookingStatus: { staff: false, selectee: false, standby: false, inihibited: false },
          timaticResponse: null,
          bags: {
            type: 'REGULAR',
            allowance: 0,
            free: 0,
            loyalty: 0,
            paid: 0,
            checked: 0,
            printed: 0,
            bagtags: [],
            carryon: false
          },
          docValidation: {
            isDocRequired: true,
            timaticResponse: { timaticCheckResult: TimaticResponses.OK, timaticComments: '' }, // conditional, denied
            adcResponse: null // ok, conditional, not_ok, error_not_available, error_exit_date, auto_by_passed
          }
        }
      ]
    }
  }

  resetForm = () => {
    this.setState({
      CONFIG_KEY: '',
      SCOPE_NAME: '',
      VALUE: '',
      GROUP_ID: '',
      COMMENTS: '',
      PROFILE_ID: '',
      PNR: Math.round(Math.pow(36, 6 + 1) - Math.random() * Math.pow(36, 6))
        .toString(36)
        .slice(1)
        .toUpperCase(),
      FLIGHT_TYPE: 'OW',
      FLIGHT_TIME: 'ON TIME',
      FLIGHT_LEG_LIST: [{ DEPARTURE: 'YYZ', ARRIVAL: 'YYC' }],
      PROGRESS: 0,
      SECTIONS: ['FLIGHTS', 'PASSENGERS', 'BAGGAGE', 'BOOKING'],
      SECTION: 'FLIGHTS',
      CARRIER: 'E1',
      CARRIER_BAGTAG: '9998',
      PAX_LIST: [
        {
          type: 'ADULT',
          travelerNo: this.makeid(9),
          prefix: 'MR',
          firstName: 'MARK',
          lastName: 'SMITH',
          dob: new Date(1998, 0, 16),
          documentNo: 'AB123450',
          documentType: 'P',
          nationality: 'CAN',
          expiryDate: this.initialExpiryDate,
          checkinStatus: 'CHECKED_IN',
          bookingStatus: { staff: false, selectee: false, standby: false, inihibited: false },
          timaticResponse: null,
          bags: {
            type: 'REGULAR',
            allowance: 0,
            free: 0,
            paid: 0,
            loyalty: 0,
            checked: 0,
            printed: 0,
            bagtags: [],
            carryon: false
          },
          docValidation: {
            isDocRequired: true,
            timaticResponse: { timaticCheckResult: TimaticResponses.OK, timaticComments: '' }, // conditional, denied, error
            adcResponse: null // ok, conditional, denied, error_not_available, error_exit_date, auto_by_passed
          }
        }
      ]
    })
  }

  buildJSONforPNR = req => {
    let res = {
      recordLocator: req.PNR,
      passengers: this.buildPassengers(req.PAX_LIST),
      flights: this.buildFlights({ flightLegs: req.FLIGHT_LEG_LIST, time: req.FLIGHT_TIME, type: req.FLIGHT_TYPE }),
      bags: this.buildBags(req.PAX_LIST),
      docValidation: this.buildDocValidation(req.PAX_LIST)
    }
    return res
  }

  buildDocValidation = paxList => {
    return paxList.map((pax, i) => {
      return {
        ordinal: i,
        isDocRequired: pax.docValidation.isDocRequired,
        timaticResponse: pax.docValidation.timaticResponse,
        adcResponse: pax.docValidation.adcResponse
      }
    })
  }

  buildPassengers = paxList => {
    let passengers = paxList.map((pax, i) => {
      return {
        ordinal: i,
        firstName: pax.firstName,
        lastName: pax.lastName,
        title: pax.prefix,
        gender: pax.prefix === 'MR' ? 'MALE' : 'FEMALE',
        farePaxType: 'Y',
        selectee: pax.bookingStatus.selectee,
        staff: pax.bookingStatus.staff,
        passengerType: pax.type,
        withInfant: false,
        associatedPassenger: -1,
        dateOfBirth: pax.dob.toISOString().split('T')[0],
        jsonExtend: null,
        onStandby: pax.bookingStatus.standby,
        feeDue: 0.0,
        advCheckedIn: false,
        fqtvData: this.buildFQTV(pax.fqtv),
        paxTxnStatus: {
          identified: false,
          timaticResponse: null,
          checkedInCurrentTxn: false,
          apisCheckedOK: false,
          priorityBoarding: false,
          documentCheckedOK: false,
          selectable: false,
          selected: false,
          apisDocumentVerified: false,
          inhibited: pax.bookingStatus.inhibited,
          inhibitDetails: [],
          jsonExtend: null
        },
        apisData: this.buildAPIS(pax, i),
        bagPoolOrdinal: 0,
        travelerNo: pax.travelerNo
      }
    })
    return passengers
  }

  makeid = length => {
    var result = ''
    var characters = '0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  buildFQTV = planNo => {
    let planId = this.props.configs.find(config => config.CONFIG_KEY === 'AcceptedFQTVCardsForIdentifyPNR')
    if (planId != null) planId = planId.VALUE
    else planId = ''

    return {
      planNo: planNo,
      planId: planId,
      tier: null,
      priority: null,
      jsonExtend: null
    }
  }

  buildAPIS = (pax, i) => {
    let expire = new Date()
    let year = expire.getFullYear()
    let month = expire.getMonth()
    let day = expire.getDate()
    expire = new Date(year + 1, month, day)
    return {
      documents: [
        {
          lastName: pax.lastName,
          firstName: pax.firstName,
          gender: pax.prefix === 'MR' ? 'MALE' : 'FEMALE',
          dateOfBirth: pax.dob ? pax.dob.toISOString().split('T')[0] : '',
          documentNo: pax.documentNo ? pax.documentNo : 'AB12345' + i,
          issuingCountry: pax.nationality ? pax.nationality : 'CAN',
          documentType: pax.documentType ? pax.documentType : 'P',
          expiryDate: pax.expiryDate
            ? pax.expiryDate.toISOString().split('T')[0]
            : this.initialExpiryDate.toISOString().split('T')[0]
        }
      ],
      destination: null,
      redress: null,
      emergencyContact: null,
      ktn: pax.ktn,
      visaDocuments: null
    }
  }

  buildFlights = req => {
    let legs = req.flightLegs.map((leg, i) => {
      return {
        ordinal: i,
        departureFlight: i === 0 ? true : false,
        outbound: true,
        returnFlight: false,
        disrupted: false,
        freeSeating: false,
        seatChangeEnabled: true,
        seatUpgradeEnabled: null,
        opFlightNo: '177' + i,
        mktFlightNo: '177' + i,
        aircraftType: '747',
        mktCarrierCode: this.state.CARRIER,
        opCarrierCode: this.state.CARRIER,
        arrivalSchedule: {
          schedule: {
            airport: {
              code: leg.ARRIVAL,
              name: !!airports.findWhere({ iata: leg.ARRIVAL })
                ? airports.findWhere({ iata: leg.ARRIVAL }).get('city')
                : 'Embross',
              timeZone: !!airports.findWhere({ iata: leg.ARRIVAL })
                ? airports.findWhere({ iata: leg.ARRIVAL }).get('timezone')
                : airports.findWhere({ iata: 'YYZ' }).get('timezone'),
              countryCode2: !!airports.findWhere({ iata: leg.ARRIVAL })
                ? getCode(airports.findWhere({ iata: leg.ARRIVAL }).get('country'))
                : airports.findWhere({ iata: 'YYZ' }).get('country')
            }
          },
          terminal: null
        },
        departureSchedule: {
          gateNumber: 'A' + i,
          schedule: {
            airport: {
              code: leg.DEPARTURE,
              name: !!airports.findWhere({ iata: leg.DEPARTURE })
                ? airports.findWhere({ iata: leg.DEPARTURE }).get('city')
                : 'Embross',
              timeZone: !!airports.findWhere({ iata: leg.DEPARTURE })
                ? airports.findWhere({ iata: leg.DEPARTURE }).get('timezone')
                : airports.findWhere({ iata: 'YYZ' }).get('timezone'),
              countryCode2: !!airports.findWhere({ iata: leg.DEPARTURE })
                ? getCode(airports.findWhere({ iata: leg.DEPARTURE }).get('country'))
                : airports.findWhere({ iata: 'YYZ' }).get('country')
            }
          }
        },
        flightStatus: null,
        priorityCode: null,
        jsonExtend: 'journeyNum=0,LiftStatus=Default'
      }
    })

    return {
      ...req,
      flightLegs: legs
    }
  }

  buildBags = pax => {
    const paxBags = pax.map((bagPax, i) => {
      return {
        name: bagPax.lastName + '/' + bagPax.firstName,
        travelerNo: bagPax.travelerNo,
        paxOrdinal: i,
        baggage: [
          {
            baggageType: bagPax.bags.type,
            checkedCount: bagPax.bags.checked,
            baggageUnit: 'PIECE',
            printedCount: bagPax.bags.printed,
            addedCount: bagPax.bags.added,
            paidOnlyCount: bagPax.bags.paid
          },
          {
            baggageType: 'CARRY_ON',
            checkedCount: 0,
            baggageUnit: 'PIECE',
            printedCount: 0,
            addedCount: bagPax.bags.carryon ? 1 : 0,
            paidOnlyCount: 0
          }
        ],
        checkinStatus: bagPax.checkinStatus,
        allowance: [
          {
            baggageType: bagPax.bags.type,
            baggageUnit: 'PIECE',
            xbagAllowance: bagPax.bags.paid,
            freeAllowance: bagPax.bags.free,
            loyaltyAllowance: bagPax.bags.loyalty
          },
          {
            baggageType: 'CARRY_ON',
            baggageUnit: 'PIECE',
            xbagAllowance: bagPax.bags.carryon ? 1 : 0,
            freeAllowance: 0,
            loyaltyAllowance: 0
          }
        ],
        bagtags: bagPax.bags.bagtags,
        maxLimit: bagPax.bags.allowance
      }
    })
    return paxBags
  }

  handleConfigKey = event => {
    const configKey = event.target.value
    this.setState({
      CONFIG_KEY: configKey,
      PROFILE_ID: this.props.PROFILE_ID,
      isAdmin: this.props.isAdmin
    })
  }

  handleScope = event => {
    const scope = Array.from(event.target.selectedOptions, option => option.value)
    console.log(scope)
    this.setState({
      SCOPE_NAME: scope
    })
  }

  handleValue = event => {
    const value = event.target.value
    this.setState({
      VALUE: value
    })
  }

  handleGroup = event => {
    const groupId = event.target.value
    this.setState({
      GROUP_ID: groupId
    })
  }

  handleDescription = event => {
    const description = event.target.value
    this.setState({
      COMMENTS: description
    })
  }

  handleDeparture = event => {
    let value = this.state.FLIGHT_LEG_LIST[0].DEPARTURE
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase().substr(0, 3)
      console.log(value)
      let flightLegList = this.state.FLIGHT_LEG_LIST
      flightLegList[0].DEPARTURE = value
      this.setState({
        FLIGHT_LEG_LIST: flightLegList
      })
    }
  }

  handlePaxList = i => {
    const firstNames = [
      'MARK',
      'LISA',
      'JOHN',
      'RACHEL',
      'JOSEPH',
      'TRISHA',
      'CARLOS',
      'JULIA',
      'STEVE',
      'JOAN',
      'BILLY',
      'MICHELLE',
      'TONY',
      'ASHLEY'
    ]
    const lastNames = [
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH',
      'SMITH'
    ]
    const prefix = ['MR', 'MS', 'MR', 'MS', 'MR', 'MS', 'MR', 'MS', 'MR', 'MS', 'MR', 'MS', 'MR', 'MS']
    const dobs = [
      '1998-01-16',
      '1988-07-12',
      '1996-09-15',
      '1956-08-20',
      '1908-04-13',
      '1966-09-01',
      '2000-02-01',
      '1975-09-14',
      '1933-05-05',
      '2001-07-08',
      '1986-07-11',
      '1955-10-19',
      '1982-07-04',
      '1983-03-02'
    ]
    const type = 'ADULT'
    const bags = {
      type: 'REGULAR',
      allowance: 0,
      free: 0,
      loyalty: 0,
      paid: 0,
      checked: 0,
      printed: 0,
      bagtags: [],
      carryon: false
    }
    const docValidation = {
      isDocRequired: true,
      timaticResponse: { timaticCheckResult: TimaticResponses.OK, timaticComments: '' }, // conditional, denied
      adcResponse: null
    }
    let paxList = this.state.PAX_LIST
    if (i === '+') {
      const index = paxList.length
      let newPax = {
        type,
        travelerNo: this.makeid(9),
        prefix: prefix[index],
        firstName: firstNames[index],
        lastName: lastNames[index],
        dob: new Date(dobs[index]),
        documentNo: 'AB12345' + index,
        documentType: 'P',
        nationality: 'CAN',
        expiryDate: this.initialExpiryDate,
        checkinStatus: 'CHECKED_IN',
        bookingStatus: { staff: false, selectee: false, standby: false, inihibited: false },
        bags,
        docValidation
      }
      paxList.push(newPax)
    } else {
      paxList.splice(i, 1)
    }
    console.log(paxList)
    this.setState({ PAX_LIST: paxList })
  }

  handleBagsButton = (bagAction, i) => {
    let paxList = this.state.PAX_LIST
    let pax = paxList[i]
    let allowance = pax.bags.allowance
    let free = pax.bags.free
    let loyalty = pax.bags.loyalty
    let paid = pax.bags.paid
    let checked = pax.bags.checked
    let printed = pax.bags.printed
    let bagtags = pax.bags.bagtags
    switch (bagAction) {
      case 'minus-allowance':
        if (allowance !== 0) {
          if (paid + free + loyalty < allowance) allowance--
        }
        break
      case 'plus-allowance':
        allowance++
        break
      case 'minus-loyalty':
        if (paid + free + loyalty > checked && loyalty > 0) loyalty--
        break
      case 'plus-loyalty':
        loyalty++
        break
      case 'minus-free':
        if (paid + free + loyalty > checked && free > 0) free--
        break
      case 'plus-free':
        free++
        break
      case 'minus-paid':
        if (paid + free + loyalty > checked && paid > 0) paid--
        break
      case 'plus-paid':
        paid++
        break
      case 'minus-checked':
        if (checked > printed) checked--
        break
      case 'plus-checked':
        if (checked < paid + loyalty + free) {
          checked++
          let bagNum = Math.floor(new Date() / 1000)
          while (bagtags.includes(bagNum.toString())) {
            bagNum += 1
          }
          bagtags.push(this.state.CARRIER_BAGTAG + bagNum.toString().substr(4, 10))
        }
        break
      case 'minus-printed':
        if (printed !== 0) printed--
        break
      case 'plus-printed':
        if (printed < checked) printed++
        break
      default:
    }
    if (allowance < free + paid + loyalty) {
      allowance++
    }
    bagtags = bagtags.slice(0, checked)
    const newBags = { ...pax.bags, allowance, free, loyalty, paid, checked, printed, bagtags }
    pax = { ...pax, bags: newBags }
    paxList[i] = pax

    this.setState({ PAX_LIST: paxList })
  }

  handleFlightLegs = i => {
    let flightLegList = this.state.FLIGHT_LEG_LIST
    if (i === '+') {
      flightLegList.push({ DEPARTURE: this.state.FLIGHT_LEG_LIST[flightLegList.length - 1].ARRIVAL, ARRIVAL: '' })
    } else {
      flightLegList.splice(i, 1)
    }
    this.setState({ FLIGHT_LEG_LIST: flightLegList })
  }

  editFlightLeg = (event, i) => {
    let value = this.state.ARRIVAL_AIRPORT
    if (Array.isArray(event) && event.length > 0) value = event[0]
    else if (!Array.isArray(event)) value = event

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase().substr(0, 3)
      let flightLegList = this.state.FLIGHT_LEG_LIST
      flightLegList[i] = { DEPARTURE: flightLegList[i].DEPARTURE, ARRIVAL: value }
      this.setState({ FLIGHT_LEG_LIST: flightLegList })
    }
  }

  handleBack = () => {
    let i = this.state.SECTIONS.indexOf(this.state.SECTION)
    if (i > 0) i--

    this.setState({
      PROGRESS: this.state.PROGRESS - 33.5 < 0 ? 0 : this.state.PROGRESS - 33.5,
      SECTION: this.state.SECTIONS[i]
    })
  }

  handleNext = () => {
    let i = this.state.SECTIONS.indexOf(this.state.SECTION)
    if (i < this.state.SECTIONS.length - 1) i++

    this.setState({
      PROGRESS: this.state.PROGRESS + 33.5 > 100 ? 100 : this.state.PROGRESS + 33.5,
      SECTION: this.state.SECTIONS[i]
    })
  }

  addConfig = () => {
    const req = {
      CONFIG_KEY: this.state.PNR,
      SCOPE_NAME: ['GLOBAL'],
      VALUE: JSON.stringify(this.buildJSONforPNR(this.state)),
      GROUP_ID: this.props.group,
      COMMENTS: this.state.COMMENTS,
      PROFILE_ID: this.props.PROFILE_ID
    }
    console.log(req)
    this.props.postConfigValue(req)
    const reqSim = { ...this.buildJSONforPNR(this.state) }
    this.props.postBagtagSimulator(reqSim)
    this.resetForm()
    this.props.closeModal()
  }

  configExists = () => {
    for (let config of this.props.configs) {
      if (
        config.CONFIG_KEY === this.state.CONFIG_KEY &&
        this.state.SCOPE_NAME.includes(config.SCOPE_NAME) &&
        config.GROUP_ID.toString() === this.state.GROUP_ID
      )
        return true
    }
    return false
  }

  getUniqueList = category => {
    let list = []
    if (category === 'AIRPORT_CODE') {
      list = this.props.cities.map(elem => {
        if (/\d/.test(elem.CITY_CODE)) {
          return null
        }
        return elem.CITY_CODE
      })
    } else if (category === 'NATIONALITY') {
      list = this.props.countries.map(elem => {
        return elem.COUNTRY_CODE3
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  render() {
    let flightForm = (
      <Form>
        <Form.Group as={Row} controlId="radioButtons">
          <Col sm></Col>
          <Col sm>
            <Form.Check
              custom
              inline
              type="radio"
              id="custom-inline-radio-1"
              label="One-Way"
              name="formHorizontalRadios"
              checked={this.state.FLIGHT_TYPE === 'OW'}
              onClick={() => {
                this.setState({ FLIGHT_TYPE: 'OW' })
              }}
            />
            <Form.Check
              custom
              inline
              type="radio"
              id="custom-inline-radio-2"
              label="Round Trip"
              name="formHorizontalRadios"
              onClick={() => {
                this.setState({ FLIGHT_TYPE: 'RET' })
              }}
              checked={this.state.FLIGHT_TYPE === 'RET'}
            />
          </Col>
          <Col sm></Col>
        </Form.Group>

        {this.state.FLIGHT_LEG_LIST.map((elem, i) => {
          return (
            <Form.Group as={Row} controlId="flightLegList" key={i}>
              <Form.Label column sm="1">
                Leg {i + 1}
              </Form.Label>
              <Col sm>
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>From</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Typeahead
                    options={this.getUniqueList('AIRPORT_CODE')}
                    type={'AIRPORT_CODE' + i}
                    id={'AIRPORT_CODE' + i}
                    selected={[elem.DEPARTURE]}
                    onChange={this.handleDeparture}
                    onInputChange={this.handleDeparture}
                    highlightOnlyResult
                    disabled={i !== 0}
                  />
                </InputGroup>
              </Col>
              <Col sm>
                <div className="centerIcon">
                  <FontAwesomeIcon icon="plane" size="2x" />
                </div>
              </Col>
              <Col sm>
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>To</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Typeahead
                    options={this.getUniqueList('AIRPORT_CODE')}
                    type={'AIRPORT_CODE' + i}
                    id={'AIRPORT_CODE' + i}
                    selected={[elem.ARRIVAL]}
                    onChange={e => this.editFlightLeg(e, i)}
                    onInputChange={e => this.editFlightLeg(e, i)}
                    highlightOnlyResult
                    disabled={this.state.FLIGHT_LEG_LIST.length !== i + 1}
                  />
                </InputGroup>
              </Col>
              <Col sm>
                {this.state.FLIGHT_LEG_LIST.length === 1 && (
                  <Button
                    className="plusRow"
                    variant="outline-primary"
                    size="sm"
                    onClick={() => this.handleFlightLegs('+')}
                    disabled={
                      this.state.FLIGHT_LEG_LIST[i].ARRIVAL.length !== 3 ||
                      this.state.FLIGHT_LEG_LIST[i].DEPARTURE.length !== 3
                    }
                  >
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                )}
                {this.state.FLIGHT_LEG_LIST.length === i + 1 && this.state.FLIGHT_LEG_LIST.length !== 1 && (
                  <div>
                    <Button
                      className="minusRow"
                      variant="outline-danger"
                      size="sm"
                      onClick={() => this.handleFlightLegs(i)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <Button
                      className="plusRow2"
                      variant="outline-primary"
                      size="sm"
                      onClick={() => this.handleFlightLegs('+')}
                      disabled={
                        this.state.FLIGHT_LEG_LIST[i].ARRIVAL.length !== 3 ||
                        this.state.FLIGHT_LEG_LIST[i].DEPARTURE.length !== 3
                      }
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </div>
                )}
              </Col>
            </Form.Group>
          )
        })}
        <Form.Group as={Row} controlId="carrierAndTime">
          <Form.Label column sm="1">
            Carrier:
          </Form.Label>
          <Col sm="1">
            <Form.Control
              type="carrier"
              value={this.state.CARRIER}
              placeholder="ID"
              onChange={event => {
                let carrier = this.state.CARRIER
                let value = event.target.value
                value = value.toUpperCase().substr(0, 2)
                carrier = value
                this.setState({ CARRIER: carrier })
              }}
            />
          </Col>
          {/* <Form.Label column sm="1">Bagtag:</Form.Label> */}
          <Col sm="1">
            <Form.Control
              type="carrier"
              placeholder="#"
              value={this.state.CARRIER_BAGTAG}
              onChange={event => {
                let carrierBagtag = this.state.CARRIER_BAGTAG
                let value = event.target.value
                value = value.toUpperCase().substr(0, 4)
                carrierBagtag = value
                this.setState({ CARRIER_BAGTAG: carrierBagtag })
              }}
            />
          </Col>
          <Col sm="2"></Col>
          <Form.Label column sm="1">
            Time:
          </Form.Label>
          <Col sm>
            <Form.Check
              custom
              inline
              type="radio"
              id="custom-inline-radio-3"
              label="On Time"
              name="formHorizontalRadios2"
              onClick={() => {
                this.setState({ FLIGHT_TIME: 'ON TIME' })
              }}
              checked={this.state.FLIGHT_TIME === 'ON TIME'}
            />
            <Form.Check
              custom
              inline
              type="radio"
              id="custom-inline-radio-4"
              label="Too Late"
              name="formHorizontalRadios2"
              onClick={() => {
                this.setState({ FLIGHT_TIME: 'LATE' })
              }}
              checked={this.state.FLIGHT_TIME === 'LATE'}
            />
            <Form.Check
              custom
              inline
              type="radio"
              id="custom-inline-radio-5"
              label="Too Early"
              name="formHorizontalRadios2"
              onClick={() => {
                this.setState({ FLIGHT_TIME: 'EARLY' })
              }}
              checked={this.state.FLIGHT_TIME === 'EARLY'}
            />
          </Col>
        </Form.Group>
      </Form>
    )

    let passengerForm = (
      <Form>
        {this.state.PAX_LIST.map((pax, i) => {
          return (
            <div key={i}>
              <Form.Group as={Row} controlId="formPaxFullName">
                <Form.Label column sm>
                  {'PAX' + (i + 1) + '    '}
                  {pax.type === 'ADULT' && <FontAwesomeIcon icon="user-alt" />}
                  {pax.type === 'CHILD' && <FontAwesomeIcon icon="child" />}
                  {pax.type === 'INFANT' && <FontAwesomeIcon icon="baby" />}
                </Form.Label>
                <Col sm>
                  <Form.Control
                    as="select"
                    value={pax.prefix}
                    onChange={event => {
                      let paxList = this.state.PAX_LIST
                      let value = event.target.value
                      paxList[i].prefix = value
                      this.setState({ PAX_LIST: paxList })
                    }}
                  >
                    <option key={'MR' + i} value="MR">
                      Mr
                    </option>
                    <option key={'MS' + i} value="MS">
                      Ms
                    </option>
                  </Form.Control>
                </Col>
                <Col sm>
                  <Form.Control
                    type="Fname"
                    placeholder="First Name"
                    value={pax.firstName}
                    onChange={event => {
                      let paxList = this.state.PAX_LIST
                      let value = event.target.value
                      value = value.toUpperCase()
                      paxList[i].firstName = value
                      this.setState({ PAX_LIST: paxList })
                    }}
                  />
                </Col>
                <Col sm>
                  <Form.Control
                    type="Lname"
                    placeholder="Last Name"
                    value={pax.lastName}
                    onChange={event => {
                      let paxList = this.state.PAX_LIST
                      let value = event.target.value
                      value = value.toUpperCase()
                      paxList[i].lastName = value
                      this.setState({ PAX_LIST: paxList })
                    }}
                  />
                </Col>
                <Col sm>
                  <DatePicker
                    className="dob"
                    onChange={date => {
                      let paxList = this.state.PAX_LIST
                      let age = Math.floor((new Date() - date) / (1000 * 60 * 60 * 24 * 365.25))
                      console.log(age)
                      let type = paxList[i].type
                      let bagtype = paxList[i].bags.type
                      if (age <= 2) type = bagtype = 'INFANT'
                      else if (age <= 17) type = bagtype = 'CHILD'
                      else {
                        type = 'ADULT'
                        if (bagtype !== 'MILITARY') bagtype = 'REGULAR'
                      }
                      console.log(type)
                      paxList[i].dob = date
                      paxList[i].type = type
                      paxList[i].bags.type = bagtype
                      this.setState({ PAX_LIST: paxList })
                    }}
                    value={pax.dob}
                  />
                </Col>
                <Col sm>
                  {i === 0 && this.state.PAX_LIST.length < 14 && (
                    <Button
                      className="plusRow"
                      variant="outline-primary"
                      size="sm"
                      onClick={() => this.handlePaxList('+')}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  )}
                  {i !== 0 && (
                    <Button
                      className="minusRow"
                      variant="outline-danger"
                      size="sm"
                      onClick={() => this.handlePaxList(i)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPaxDetailInformation">
                <Col sm="12">
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          <FontAwesomeIcon icon="chevron-down" /> Options
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Form.Label>
                            <FontAwesomeIcon icon="credit-card" /> Frequent Flyer
                          </Form.Label>
                          <Form.Group as={Row} controlId="formPaxFrequentFlyerNumber" className="pl-3">
                            <Col sm>
                              <Form.Control
                                type="FQTV"
                                placeholder="FQTV Number"
                                onChange={event => {
                                  let paxList = this.state.PAX_LIST
                                  const value = event.target.value
                                  if (/^[0-9\b]+$/.test(value) || value === '') paxList[i].fqtv = value
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                value={pax.fqtv}
                              />
                            </Col>
                            <Col sm>
                              <Form.Control
                                type="KTN"
                                placeholder="KTN Number"
                                onChange={event => {
                                  let paxList = this.state.PAX_LIST
                                  const value = event.target.value
                                  if (/^[0-9\b]+$/.test(value) || value === '') paxList[i].ktn = value
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                value={pax.ktn}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Label>
                            <FontAwesomeIcon icon="id-card" /> Passenger Document
                          </Form.Label>
                          <Form.Group as={Row} controlId="formPaxDocumentData" className="pl-3">
                            <Col sm>
                              <Form.Control
                                type="text"
                                placeholder="Document Number"
                                onChange={event => {
                                  let paxList = this.state.PAX_LIST
                                  const value = event.target.value
                                  if (/^[0-9\b]+$/.test(value) || value === '') paxList[i].documentNo = value
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                value={pax.documentNo}
                              />
                            </Col>
                            <Col sm>
                              <DatePicker
                                className="expiryDate"
                                onChange={date => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].expiryDate = date
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                value={pax.expiryDate}
                              />
                            </Col>
                            <Col sm>
                              <Form.Control
                                as="select"
                                value={pax.documentType}
                                key={'documentType' + i}
                                onChange={event => {
                                  let paxList = this.state.PAX_LIST
                                  let value = event.target.value
                                  paxList[i].documentType = value
                                  this.setState({ PAX_LIST: paxList })
                                }}
                              >
                                <option value="">Select Document Type</option>
                                <option value="P">Passport</option>
                              </Form.Control>
                            </Col>
                            <Col sm>
                              <Form.Control
                                type="text"
                                placeholder="Nationality"
                                onChange={event => {
                                  let paxList = this.state.PAX_LIST
                                  let value = event.target.value
                                  value = value.toUpperCase().substr(0, 3)
                                  paxList[i].nationality = value
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                value={pax.nationality}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Label>
                            <FontAwesomeIcon icon="calendar-check" /> Booking Status
                          </Form.Label>
                          <Form.Group as={Row} controlId="formPaxCheckedIn" className="pl-3">
                            <Col sm>
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'custom-inline-radio-p1' + i}
                                label="Not Checked In"
                                name={'formHorizontalCheckbox' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].checkinStatus = 'CHECKED_IN'
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.checkinStatus === 'CHECKED_IN'}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'custom-inline-radio-p2' + i}
                                label="Checkin In Not Printed"
                                name={'formHorizontalCheckbox' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].checkinStatus = 'CHECKED_IN_NOT_PRINTED'
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.checkinStatus === 'CHECKED_IN_NOT_PRINTED'}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'custom-inline-radio-p3' + i}
                                label="Checkin In Printed"
                                name={'formHorizontalCheckbox' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].checkinStatus = 'CHECKED_IN_PRINTED'
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.checkinStatus === 'CHECKED_IN_PRINTED'}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="formPaxBookingStatus" className="pl-3">
                            <Col sm>
                              <Form.Check
                                custom
                                inline
                                type="checkbox"
                                id={'custom-inline-checkbox-1' + i}
                                label="Standby"
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].bookingStatus.standby = !paxList[i].bookingStatus.standby
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.bookingStatus.standby}
                              />
                              <Form.Check
                                custom
                                inline
                                type="checkbox"
                                id={'custom-inline-checked-2' + i}
                                label="Staff"
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].bookingStatus.staff = !paxList[i].bookingStatus.staff
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.bookingStatus.staff}
                              />
                              <Form.Check
                                custom
                                inline
                                type="checkbox"
                                id={'custom-inline-checked-3' + i}
                                label="Selectee"
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].bookingStatus.selectee = !paxList[i].bookingStatus.selectee
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.bookingStatus.selectee}
                              />
                              <Form.Check
                                custom
                                inline
                                type="checkbox"
                                id={'custom-inline-checked-4' + i}
                                label="Inhibited"
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].bookingStatus.inhibited = !paxList[i].bookingStatus.inhibited
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.bookingStatus.inhibited}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Label>
                            <FontAwesomeIcon icon="clipboard-check" /> Document Validation
                          </Form.Label>
                          <Form.Group as={Row} controlId="formPaxDocValidation" className="pl-3">
                            <Col sm>
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'doc-inline-radio-1' + i}
                                label="Doc Required"
                                name={'formHorizontalRadiosDocReq' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.isDocRequired = true
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.isDocRequired}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'doc-inline-radio-2' + i}
                                label="Doc Not Required"
                                name={'formHorizontalRadiosDocReq' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.isDocRequired = false
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={!pax.docValidation.isDocRequired}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="timaticResponse" className="pl-3">
                            <Col sm>
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-1' + i}
                                label="Timatic response ok"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.timaticResponse.timaticCheckResult = TimaticResponses.OK
                                  paxList[i].docValidation.adcResponse = null
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.timaticResponse.timaticCheckResult === TimaticResponses.OK}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-2' + i}
                                label="Timatic response conditional ok"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.timaticResponse.timaticCheckResult =
                                    TimaticResponses.CONDITIONAL
                                  paxList[i].docValidation.adcResponse = null
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={
                                  pax.docValidation.timaticResponse.timaticCheckResult === TimaticResponses.CONDITIONAL
                                }
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-3' + i}
                                label="Timatic response denied"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.timaticResponse.timaticCheckResult = TimaticResponses.DENIED
                                  paxList[i].docValidation.adcResponse = null
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={
                                  pax.docValidation.timaticResponse.timaticCheckResult === TimaticResponses.DENIED
                                }
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-4' + i}
                                label="Timatic response error"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.timaticResponse.timaticCheckResult = TimaticResponses.ERROR
                                  paxList[i].docValidation.adcResponse = null
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={
                                  pax.docValidation.timaticResponse.timaticCheckResult === TimaticResponses.ERROR
                                }
                              />
                              <br />
                              {/****************************************** ADC RESPONSE ***************************************/}
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-5' + i}
                                label="ADC response ok"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.adcResponse = ADCResponses.OK
                                  paxList[i].docValidation.timaticResponse = {
                                    timaticCheckResult: null,
                                    timaticComments: null
                                  }
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.adcResponse === ADCResponses.OK}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-6' + i}
                                label="ADC response conditional ok"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.adcResponse = ADCResponses.CONDITIONAL
                                  paxList[i].docValidation.timaticResponse = {
                                    timaticCheckResult: null,
                                    timaticComments: null
                                  }
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.adcResponse === ADCResponses.CONDITIONAL}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-7' + i}
                                label="ADC response not ok"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.adcResponse = ADCResponses.NOT_OK
                                  paxList[i].docValidation.timaticResponse = {
                                    timaticCheckResult: null,
                                    timaticComments: null
                                  }
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.adcResponse === ADCResponses.NOT_OK}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-8' + i}
                                label="ADC response error not available"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.adcResponse = ADCResponses.ERROR_NOT_AVAILABLE
                                  paxList[i].docValidation.timaticResponse = {
                                    timaticCheckResult: null,
                                    timaticComments: null
                                  }
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.adcResponse === ADCResponses.ERROR_NOT_AVAILABLE}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'inline-radio-9' + i}
                                label="ADC response error exit date"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.adcResponse = ADCResponses.ERROR_EXIT_DATE
                                  paxList[i].docValidation.timaticResponse = {
                                    timaticCheckResult: null,
                                    timaticComments: null
                                  }
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.adcResponse === ADCResponses.ERROR_EXIT_DATE}
                              />
                              <Form.Check
                                custom
                                inline
                                type="radio"
                                id={'timatic-inline-radio-3' + i}
                                label="ADC response auto by passed"
                                name={'formHorizontalRadiosTimatic' + i}
                                onClick={() => {
                                  let paxList = this.state.PAX_LIST
                                  paxList[i].docValidation.adcResponse = ADCResponses.AUTO_BY_PASSED
                                  paxList[i].docValidation.timaticResponse = {
                                    timaticCheckResult: null,
                                    timaticComments: null
                                  }
                                  this.setState({ PAX_LIST: paxList })
                                }}
                                checked={pax.docValidation.adcResponse === ADCResponses.AUTO_BY_PASSED}
                              />
                            </Col>
                          </Form.Group>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Form.Group>
            </div>
          )
        })}
      </Form>
    )

    let baggageForm = (
      <Form>
        {this.state.PAX_LIST.map((pax, i) => {
          return (
            <div key={i}>
              {/* <Form.Group as={Row} controlId="formLabel">
                    <Form.Label column sm="3">Pool Baggage:</Form.Label>
                    <Col sm="8">
                        <Form.Check type="switch" id="custom-switch1" label="" checked={(this.state.BAGGAGE_POOL)} onClick={() => this.setState({ BAGGAGE_POOL: !this.state.BAGGAGE_POOL})}/>
                    </Col>
                </Form.Group> */}
              <Form.Group as={Row} controlId="formLabel">
                <Form.Label column sm="4">
                  {pax.firstName + ' ' + pax.lastName + ' BAGS    '} {<FontAwesomeIcon icon="suitcase" />}
                </Form.Label>
                <Col sm="5">
                  <div className="bagType">
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      id={'custom-inline-radio-bagType1' + i}
                      label="REGULAR"
                      name={'bagTypeRadio' + i}
                      checked={pax.bags.type === 'REGULAR'}
                      onClick={() => {
                        let paxList = this.state.PAX_LIST
                        paxList[i].bags.type = 'REGULAR'
                        this.setState({ PAX_LIST: paxList })
                      }}
                    />
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      id={'custom-inline-radio-bagType2' + i}
                      label="INFANT"
                      name={'bagTypeRadio' + i}
                      checked={pax.bags.type === 'INFANT'}
                      onClick={() => {
                        let paxList = this.state.PAX_LIST
                        paxList[i].bags.type = 'INFANT'
                        this.setState({ PAX_LIST: paxList })
                      }}
                    />
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      id={'custom-inline-radio-bagType3' + i}
                      label="CHILD"
                      name={'bagTypeRadio' + i}
                      checked={pax.bags.type === 'CHILD'}
                      onClick={() => {
                        let paxList = this.state.PAX_LIST
                        paxList[i].bags.type = 'CHILD'
                        this.setState({ PAX_LIST: paxList })
                      }}
                    />
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      id={'custom-inline-radio-bagType4' + i}
                      label="MILITARY"
                      name={'bagTypeRadio' + i}
                      checked={pax.bags.type === 'MILITARY'}
                      onClick={() => {
                        let paxList = this.state.PAX_LIST
                        paxList[i].bags.type = 'MILITARY'
                        this.setState({ PAX_LIST: paxList })
                      }}
                    />
                  </div>
                </Col>
                <Col md={{ span: 2, offset: 1 }}>
                  <Form.Check
                    className="carryon"
                    custom
                    inline
                    type="checkbox"
                    id={'custom-inline-checkbox-carryon' + i}
                    label="Carry On"
                    onClick={() => {
                      let paxList = this.state.PAX_LIST
                      paxList[i].bags.carryon = !paxList[i].bags.carryon
                      this.setState({ PAX_LIST: paxList })
                    }}
                    checked={pax.bags.carryon}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formLabelBagsTypes">
                <Table>
                  <thead>
                    <tr>
                      <th className="bagTitle">MAX LIMIT</th>
                      <th className="bagTitle">FREE</th>
                      <th className="bagTitle">LOYALTY</th>
                      <th className="bagTitle">PAID</th>
                      <th className="bagTitle">CHECKED</th>
                      <th className="bagTitle">PRINTED</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Group as={Row} controlId="formBagInputFields">
                          <Col sm>
                            <Button
                              className="minusRow2"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('minus-allowance', i)}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </Col>
                          <Col sm className={'p-0'}>
                            <h4 className="bagNumber">{pax.bags.allowance}</h4>
                          </Col>
                          <Col sm>
                            <Button
                              className="plusRow"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('plus-allowance', i)}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </Col>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group as={Row} controlId="formBagInputFields">
                          <Col sm>
                            <Button
                              className="minusRow2"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('minus-free', i)}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </Col>
                          <Col sm className={'p-0'}>
                            <h4 className="bagNumber">{pax.bags.free}</h4>
                          </Col>
                          <Col sm>
                            <Button
                              className="plusRow"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('plus-free', i)}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </Col>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group as={Row} controlId="formBagInputFields">
                          <Col sm>
                            <Button
                              className="minusRow2"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('minus-loyalty', i)}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </Col>
                          <Col sm className={'p-0'}>
                            <h4 className="bagNumber">{pax.bags.loyalty}</h4>
                          </Col>
                          <Col sm>
                            <Button
                              className="plusRow"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('plus-loyalty', i)}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </Col>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group as={Row} controlId="formBagInputFields">
                          <Col sm>
                            <Button
                              className="minusRow2"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('minus-paid', i)}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </Col>
                          <Col sm className={'p-0'}>
                            <h4 className="bagNumber">{pax.bags.paid}</h4>
                          </Col>
                          <Col sm>
                            <Button
                              className="plusRow"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('plus-paid', i)}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </Col>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group as={Row} controlId="formBagInputFields">
                          <Col sm>
                            <Button
                              className="minusRow2"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('minus-checked', i)}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </Col>
                          <Col sm className={'p-0'}>
                            <h4 className="bagNumber">{pax.bags.checked}</h4>
                          </Col>
                          <Col sm>
                            <Button
                              className="plusRow"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('plus-checked', i)}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </Col>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group as={Row} controlId="formBagInputFields">
                          <Col sm>
                            <Button
                              className="minusRow2"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('minus-printed', i)}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </Col>
                          <Col sm className={'p-0'}>
                            <h4 className="bagNumber">{pax.bags.printed}</h4>
                          </Col>
                          <Col sm>
                            <Button
                              className="plusRow"
                              variant="link"
                              size="sm"
                              onClick={() => this.handleBagsButton('plus-printed', i)}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </Col>
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Form.Group as={Row} controlId="formBagTags">
                <Col sm="12">
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          <FontAwesomeIcon icon="chevron-down" /> Bag Tags
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {pax.bags.bagtags.map((bagtag, k) => {
                            return (
                              <Form.Group as={Row} key={bagtag + k} controlId="formBagTagInput">
                                <Col xs={2}>
                                  <Form.Label>
                                    <FontAwesomeIcon icon="tag" />
                                    {'        BAG TAG ' + (k + 1) + ':'}
                                  </Form.Label>
                                </Col>
                                <Col sm>
                                  <Form.Control
                                    type="text"
                                    size="sm"
                                    onChange={event => {
                                      let paxList = this.state.PAX_LIST
                                      const value = event.target.value
                                      if (/^[0-9\b]+$/.test(value) || value === '') paxList[i].bags.bagtags[k] = value
                                      this.setState({ PAX_LIST: paxList })
                                    }}
                                    value={bagtag}
                                  />
                                </Col>
                              </Form.Group>
                            )
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Form.Group>
            </div>
          )
        })}
      </Form>
    )

    let pnrForm = (
      <Form>
        <Form.Group as={Row} controlId="formPNR">
          <Form.Label column sm="1">
            <h4>PNR: </h4>
          </Form.Label>
          <Col sm>
            <Form.Control
              type="text"
              size="lg"
              value={this.state.PNR}
              onChange={event => this.setState({ PNR: event.target.value })}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formFlightCard">
          <Col sm>
            <Card bg="light" key="cardpax" text="dark">
              <Card.Header>Flight Itinerary</Card.Header>
              <Card.Body>
                <Card.Title>
                  <Form.Group as={Row} controlId="formFlightPath">
                    {this.state.FLIGHT_TIME === 'ON TIME' && (
                      <Col sm>
                        <div className="onTime">{this.state.FLIGHT_TIME}</div>
                        <div className="flightDesc">- Flight Type: {this.state.FLIGHT_TYPE}</div>
                        <div className="flightDesc">- Carrier: {this.state.CARRIER}</div>
                      </Col>
                    )}
                    {this.state.FLIGHT_TIME !== 'ON TIME' && (
                      <Col sm>
                        <div className="notOnTime">{this.state.FLIGHT_TIME}</div>
                        <div className="flightDesc">- Flight Type: {this.state.FLIGHT_TYPE}</div>
                        <div className="flightDesc">- Carrier: {this.state.CARRIER}</div>
                      </Col>
                    )}
                  </Form.Group>
                </Card.Title>
              </Card.Body>
              {this.state.FLIGHT_LEG_LIST.filter(leg => {
                return leg.ARRIVAL.length === 3
              }).map((leg, i) => {
                return (
                  <Card.Body key={'LegArrival' + i}>
                    <Card.Title>
                      <Form.Group as={Row} controlId="formFlightPath">
                        <Col sm>{leg.DEPARTURE}</Col>
                        <Col sm>
                          <FontAwesomeIcon icon="plane" />
                        </Col>
                        <Col sm="1">{leg.ARRIVAL}</Col>
                      </Form.Group>
                    </Card.Title>
                  </Card.Body>
                )
              })}
            </Card>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPAXCard">
          <Col sm>
            <Card bg="light" key={'cardpax'} text="dark">
              <Card.Header>Passengers</Card.Header>
              {this.state.PAX_LIST.map((pax, i) => {
                return (
                  <Card.Body key={'paxList' + i}>
                    <Card.Title>
                      {' '}
                      {pax.firstName + ' ' + pax.lastName + ' '}
                      {pax.type === 'ADULT' && <FontAwesomeIcon icon="user-alt" />}
                      {pax.type === 'CHILD' && <FontAwesomeIcon icon="child" />}
                      {pax.type === 'INFANT' && <FontAwesomeIcon icon="baby" />}
                    </Card.Title>
                    <Card.Text>
                      {'BAG-' +
                        pax.bags.type +
                        ': ' +
                        pax.bags.allowance +
                        ' ALLOWANCE, ' +
                        pax.bags.free +
                        ' FREE, ' +
                        pax.bags.loyalty +
                        ' LOYALTY, ' +
                        pax.bags.paid +
                        ' PAID, ' +
                        pax.bags.checked +
                        ' CHECKED, ' +
                        pax.bags.printed +
                        ' PRINTED'}{' '}
                      {pax.bags.carryon && ' + HAS CARRY-ON'}
                    </Card.Text>
                  </Card.Body>
                )
              })}
            </Card>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPNR">
          <Form.Label column sm="1">
            {' '}
            Description:{' '}
          </Form.Label>
          <Col sm>
            <Form.Control as="textarea" rows="1" onChange={this.handleDescription} />
          </Col>
        </Form.Group>
      </Form>
    )

    const { closeModal, postBagtagSimulator, postConfigValue, isAdmin, PROFILE_ID, ...props } = this.props

    return (
      <Modal {...props} size="xl">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <h2>{this.state.SECTION}</h2>
          <ProgressBar animated now={this.state.PROGRESS} />
          <br />
          {this.state.SECTION === 'FLIGHTS' && flightForm}
          {this.state.SECTION === 'PASSENGERS' && passengerForm}
          {this.state.SECTION === 'BAGGAGE' && baggageForm}
          {this.state.SECTION === 'BOOKING' && pnrForm}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleBack}>
            Back
          </Button>
          {this.state.SECTION !== 'BOOKING' && (
            <Button variant="primary" onClick={this.handleNext}>
              Next
            </Button>
          )}
          {this.state.SECTION === 'BOOKING' && (
            <Button variant="success" onClick={this.addConfig}>
              Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.configs,
    groups: state.loadData.groups,
    scopes: state.loadData.scopes,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database,
    countries: state.loadData.countries,
    cities: state.loadData.cities,
    group: state.manageModal.simulatorGroup
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postConfigValue, postBagtagSimulator, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PNRBuilderMenu)
