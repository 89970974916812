import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { logout } from 'redux/actions/loginInfo'
import authentication from '../../services/authentication'
import { resetError } from 'redux/actions/getData'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class ErrorMenu extends React.Component {
  handleLogOut = async () => {
    const { removeToken } = authentication()
    await removeToken()
    window.location.reload()
  }

  render() {
    let innerModal = null
    const err = this.props.error
    if (!!err) {
      innerModal = (
        <div>
          <Modal.Header>
            <Modal.Title>{`${err.type} ${this.props.text} ${err.errno}: ${err.code}`}</Modal.Title>
          </Modal.Header>

          <Modal.Body {...this.props} size="sm">
            {err.message !== err.sqlMessage && (
              <div>
                <p className="asterisk">{err.message}</p>
                <br />
                <br />
              </div>
            )}
            {!!err.sqlMessage && (
              <body>
                <b>{'SQL Message: '}</b>
                {`${err.sqlMessage}`}
              </body>
            )}
          </Modal.Body>

          <Modal.Footer>
            {err.isFatal && (
              <Button variant="outline-danger" onClick={this.handleLogOut}>
                Log Out
              </Button>
            )}
            {!err.isFatal && (
              <Button
                variant="outline-danger"
                onClick={() => {
                  this.props.resetError()
                }}
              >
                Close
              </Button>
            )}
          </Modal.Footer>
        </div>
      )
    }
    return (
      <Modal {...this.props} size="lg">
        {innerModal}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.loadData.error
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout, resetError }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorMenu)
