import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import logo from 'assets/embross.png'
import { closeNotification } from 'redux/actions/postData'
import Toast from 'react-bootstrap/Toast'

class PostNotification extends React.Component {
  render() {
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'relative',
          minHeight: '200px'
        }}
      >
        <div
          style={{
            position: 'fixed',
            bottom: 20,
            right: 20
          }}
        >
          {this.props.response.map((e, i) => {
            return (
              <Toast
                key={'toast' + i}
                onClose={() => this.props.closeNotification(e.date)}
                show={!!e.request}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  <img src={logo} width="15" height="15" className="rounded mr-2" alt="" />
                  <strong className="mr-auto">{e.request}</strong>
                  {/* <small>{moment(e.date).fromNow()}</small> */}
                </Toast.Header>
                <Toast.Body>{e.message}</Toast.Body>
              </Toast>
            )
          })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    response: state.postData.response,
    successfulLogin: state.accountLogin.successfulLogin
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeNotification }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostNotification)
