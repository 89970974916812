import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { saveCountry, deleteCountry } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'
import { by639_1 } from 'iso-language-codes'

class EditCountry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editCountry !== this.props.editCountry) {
      this.setState({
        ...nextProps.editCountry
      })
    }
  }

  // componentDidUpdate(prevProps){
  //     if (JSON.stringify(this.props.editCountry) !== JSON.stringify(prevProps.editCountry)) {
  //         this.setState({
  //             ...this.props.editCountry
  //         })
  //     }
  // }

  handleCountryCode2 = event => {
    let value = this.state.COUNTRY_CODE2
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase().substr(0, 2)
      console.log(value)
      this.setState({
        COUNTRY_CODE2: value
      })
    }
  }

  handleCountryCode3 = event => {
    let value = this.state.COUNTRY_CODE3
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase().substr(0, 3)
      console.log(value)
      this.setState({
        COUNTRY_CODE3: value
      })
    }
  }

  handleCountryName = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_NAME: value
    })
  }

  handleCountryNum = event => {
    const re = /^[0-9\b]+$/
    let value = this.state.COUNTRY_NUMBER
    if (event.target.value === '' || re.test(event.target.value)) value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_NUMBER: value
    })
  }

  handleLangCode = event => {
    const value = event.target.value.toUpperCase()
    console.log(value)
    this.setState({
      LANGUAGE_CODE: value
    })
  }

  configExists = () => {
    for (let config of this.props.properties) {
      if (config.SECTION_NAME === this.state.SECTION_NAME && config.PROPERTY_NAME === this.state.PROPERTY_NAME)
        return true
    }
    return false
  }

  isChanged = () => {
    let prevProp = this.props.editCountry
    return (
      (this.state.COUNTRY_NAME !== prevProp.COUNTRY_NAME ||
        this.state.COUNTRY_CODE3 !== prevProp.COUNTRY_CODE3 ||
        this.state.COUNTRY_CODE2 !== prevProp.COUNTRY_CODE2 ||
        (!!this.state.COUNTRY_NUMBER ? this.state.COUNTRY_NUMBER.toString() : this.state.COUNTRY_NUMBER) !==
          (!!prevProp.COUNTRY_NUMBER ? prevProp.COUNTRY_NUMBER.toString() : prevProp.COUNTRY_NUMBER) ||
        this.state.LANGUAGE_CODE !== prevProp.LANGUAGE_CODE) &&
      this.state.COUNTRY_NAME.length > 0 &&
      this.state.COUNTRY_CODE3.length > 0
    )
  }

  getUniqueList = category => {
    let list = []

    if (category === 'COUNTRY_CODE2') {
      list = this.props.countries.map(elem => {
        return elem.COUNTRY_CODE2
      })
    } else if (category === 'COUNTRY_CODE3') {
      list = this.props.countries.map(elem => {
        return elem.COUNTRY_CODE3
      })
    } else if (category === 'LANGUAGE_CODE') {
      list = Object.keys(by639_1).map(elem => {
        return elem.toUpperCase()
      })
    }

    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  saveConfig = () => {
    const req = { ...this.state }
    console.log(req)
    this.props.saveCountry(req)
    this.resetForm()
    this.props.closeModal()
  }

  deleteConfig = () => {
    const onlyCountry =
      this.props.countries.filter(e => {
        return e.COUNTRY_CODE2 === this.props.editCountry.COUNTRY_CODE2
      }).length === 1
    let cityList = this.props.cities.filter(e => {
      return e.COUNTRY_CODE2 === this.props.editCountry.COUNTRY_CODE2
    })
    const req = { ...this.props.editCountry, cityList: cityList, onlyCountry: onlyCountry }
    console.log(req)
    this.props.deleteCountry(req)
    this.resetForm()
    this.props.closeModal()
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="danger" onClick={this.deleteConfig}>
          Delete
        </Button>
        <Button variant="success" onClick={this.saveConfig} disabled>
          Save
        </Button>
      </Modal.Footer>
    )

    if (this.isChanged()) {
      footer = (
        <Modal.Footer>
          <Button variant="danger" onClick={this.deleteConfig}>
            Delete
          </Button>
          <Button variant="success" onClick={this.saveConfig}>
            Save
          </Button>
        </Modal.Footer>
      )
    }

    const isView = this.props.text.includes('View')
    if (isView) {
      footer = null
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Country Code 2<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('COUNTRY_CODE2')}
                  type="COUNTRY_CODE2"
                  id="COUNTRY_CODE2"
                  selected={[this.state.COUNTRY_CODE2]}
                  onChange={this.handleCountryCode2}
                  onInputChange={this.handleCountryCode2}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Country Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="COUNTRY_NAME"
                  value={this.state.COUNTRY_NAME}
                  onChange={this.handleCountryName}
                  disabled={isView}
                />
              </Col>
              <Col sm="2">
                <Form.Control as="select" onChange={this.handleLangCode} value={this.state.LANGUAGE_CODE} disabled>
                  {this.getUniqueList('LANGUAGE_CODE').map(e => {
                    return (
                      <option key={this.props.database + e} value={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Country Code 3<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('COUNTRY_CODE3')}
                  type="COUNTRY_CODE3"
                  id="COUNTRY_CODE3"
                  selected={[this.state.COUNTRY_CODE3]}
                  onChange={this.handleCountryCode3}
                  onInputChange={this.handleCountryCode3}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Country Number:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="COUNTRY_NUMBER"
                  value={this.state.COUNTRY_NUMBER}
                  onChange={this.handleCountryNum}
                  disabled={isView}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    cities: state.loadData.cities,
    countries: state.loadData.countries,
    editCountry: state.manageModal.editCountry,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveCountry, deleteCountry, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCountry)
