import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postStateDef } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'

class StateDefMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      STATE_ID: '',
      BUSINESS_REF_NO: '',
      IS_FATAL: false,
      KAC_REQUIRED: false,
      STAMPING_REQUIRED: false,
      USE_FOR_REP: false,
      REP_CATEGORY: '',
      STATE_CATEGORY: ''
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleStateId = event => {
    const value = event.target.value.toUpperCase()
    console.log(value)
    this.setState({
      STATE_ID: value
    })
  }

  handleBusinessRef = event => {
    const re = /^[0-9\b]+$/
    let value = this.state.BUSINESS_REF_NO
    if (event.target.value === '' || re.test(event.target.value)) value = event.target.value
    console.log(value)
    this.setState({
      BUSINESS_REF_NO: value
    })
  }

  handleRepCategory = event => {
    let value = this.state.REP_CATEGORY
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase()
      console.log(value)
      this.setState({
        REP_CATEGORY: value
      })
    }
  }

  handleStateCategory = event => {
    let value = this.state.STATE_CATEGORY
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase()
      console.log(value)
      this.setState({
        STATE_CATEGORY: value
      })
    }
  }

  handleIsFatal = () => {
    this.setState({
      IS_FATAL: !this.state.IS_FATAL
    })
  }

  handleKacRequired = () => {
    this.setState({
      KAC_REQUIRED: !this.state.KAC_REQUIRED
    })
  }

  handleStampingRequired = () => {
    this.setState({
      STAMPING_REQUIRED: !this.state.STAMPING_REQUIRED
    })
  }

  handleUseForRep = () => {
    this.setState({
      USE_FOR_REP: !this.state.USE_FOR_REP
    })
  }

  addConfig = () => {
    let isFatal, kacReq, stampReq, useForRep
    isFatal = kacReq = stampReq = useForRep = '0'
    if (this.state.IS_FATAL) {
      isFatal = '1'
    }
    if (this.state.KAC_REQUIRED) {
      kacReq = '1'
    }
    if (this.state.STAMPING_REQUIRED) {
      stampReq = '1'
    }
    if (this.state.USE_FOR_REP) {
      useForRep = '1'
    }
    const req = {
      ...this.state,
      IS_FATAL: isFatal,
      KAC_REQUIRED: kacReq,
      STAMPING_REQUIRED: stampReq,
      USE_FOR_REP: useForRep
    }
    console.log(req)
    this.props.postStateDef(req)
    console.log('STATE ADDED')
    this.resetForm()
    this.props.closeModal()
  }

  configExists = () => {
    for (let config of this.props.configs) {
      if (config.STATE_ID === this.state.STATE_ID) return true
    }
    return false
  }

  getUniqueList = category => {
    let list = []
    if (category === 'REP_CATEGORY') {
      list = this.props.configs.map(elem => {
        return !!elem.REP_CATEGORY ? elem.REP_CATEGORY.replace(/\s/g, '') : null
      })
    }
    if (category === 'STATE_CATEGORY') {
      list = this.props.configs.map(elem => {
        return !!elem.STATE_CATEGORY ? elem.STATE_CATEGORY.replace(/\s/g, '') : null
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="success" onClick={this.addConfig} disabled>
          Add
        </Button>
      </Modal.Footer>
    )
    if (this.state.STATE_ID.length > 0 && !this.configExists()) {
      footer = (
        <Modal.Footer>
          <Button variant="success" onClick={this.addConfig}>
            Add
          </Button>
        </Modal.Footer>
      )
    }
    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextConfigKey">
              <Form.Label column sm="4">
                State ID<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="STATE_ID" value={this.state.STATE_ID} onChange={this.handleStateId} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Business Reference Number:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="BUSINESS_REF_NO"
                  value={this.state.BUSINESS_REF_NO}
                  onChange={this.handleBusinessRef}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                Is Fatal:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch1"
                  label=""
                  checked={this.state.IS_FATAL}
                  onClick={this.handleIsFatal}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                KAC Required:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch2"
                  label=""
                  checked={this.state.KAC_REQUIRED}
                  onClick={this.handleKacRequired}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                Stamping Required:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch3"
                  label=""
                  checked={this.state.STAMPING_REQUIRED}
                  onClick={this.handleStampingRequired}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                Use For Rep:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch4"
                  label=""
                  checked={this.state.USE_FOR_REP}
                  onClick={this.handleUseForRep}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Rep Category:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('REP_CATEGORY')}
                  id="REP_CATEGORY"
                  type="REP_CATEGORY"
                  selected={[this.state.REP_CATEGORY]}
                  onChange={this.handleRepCategory}
                  onInputChange={this.handleRepCategory}
                  emptyLabel={false}
                  highlightOnlyResult
                />
                {/* <Form.Control type="REP_CATEGORY" value={this.state.REP_CATEGORY} onChange={this.handleRepCategory}/> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                State Category:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('STATE_CATEGORY')}
                  type="STATE_CATEGORY"
                  id="STATE_CATEGORY"
                  selected={[this.state.STATE_CATEGORY]}
                  onChange={this.handleStateCategory}
                  onInputChange={this.handleStateCategory}
                  emptyLabel={false}
                  highlightOnlyResult
                />
                {/* <Form.Control type="STATE_CATEGORY" value={this.state.STATE_CATEGORY} onChange={this.handleStateCategory}/> */}
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.stateDef,
    stateDesc: state.loadData.stateDesc,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postStateDef, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StateDefMenu)
