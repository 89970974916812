import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table'
import { grantAccess, removeAccess } from 'redux/actions/editConfigAccess'
import '../../css/table.css'

class ProfileAccessTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      HAS_ACCESS_LAST_INDEX: null,
      NO_ACCESS_LAST_INDEX: null
    }
  }

  filteredValues = () => {
    const category = this.props.category
    const query = this.props.query
    const id = this.props.PROFILE_ID
    const hasAccessArray = this.props.accessKeys.filter(element => {
      return element.PROFILE_ID === id
    })

    let filteredData
    if (this.props.hasAccess) {
      filteredData = this.props.accessKeys
        .filter(element => {
          let flag = true
          for (let remove of this.props.COMMIT_REMOVE_ACCESS) {
            if (
              element.CONFIG_KEY === remove.CONFIG_KEY &&
              element.SCOPE_NAME === remove.SCOPE_NAME &&
              element.GROUP_ID === remove.GROUP_ID
            ) {
              flag = false
            }
          }
          return flag
        })
        .filter(element => {
          if (category === 'Scope Name')
            return element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) && element.PROFILE_ID === id
          else if (category === 'Group Name')
            return element.GROUP_NAME.toLowerCase().includes(query.toLowerCase()) && element.PROFILE_ID === id
          else if (category === 'Any')
            return (
              (element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) ||
                element.GROUP_NAME.toLowerCase().includes(query.toLowerCase()) ||
                element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase())) &&
              element.PROFILE_ID === id
            )
          else return element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase()) && element.PROFILE_ID === id
        })
    } else {
      filteredData = this.props.accessKeys
        .filter(element => {
          let flag = true
          for (let access of hasAccessArray) {
            if (
              element.CONFIG_KEY === access.CONFIG_KEY &&
              element.SCOPE_NAME === access.SCOPE_NAME &&
              element.GROUP_ID === access.GROUP_ID
            ) {
              flag = false
            }
          }
          return flag
        })
        .filter(element => {
          let flag = true
          for (let grant of this.props.COMMIT_GRANT_ACCESS) {
            if (
              element.CONFIG_KEY === grant.CONFIG_KEY &&
              element.SCOPE_NAME === grant.SCOPE_NAME &&
              element.GROUP_ID === grant.GROUP_ID
            ) {
              flag = false
            }
          }
          return flag
        })
        .filter(element => {
          if (category === 'Scope Name')
            return (
              element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) &&
              element.PROFILE_ID !== id &&
              element.PROFILE_ID === 1
            )
          else if (category === 'Group Name')
            return (
              element.GROUP_NAME.toLowerCase().includes(query.toLowerCase()) &&
              element.PROFILE_ID !== id &&
              element.PROFILE_ID === 1
            )
          else if (category === 'Any')
            return (
              (element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) ||
                element.GROUP_NAME.toLowerCase().includes(query.toLowerCase()) ||
                element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase())) &&
              element.PROFILE_ID !== id &&
              element.PROFILE_ID === 1
            )
          else
            return (
              element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase()) &&
              element.PROFILE_ID !== id &&
              element.PROFILE_ID === 1
            )
        })
    }
    return filteredData
  }

  handleRowClick = (data, e, i) => {
    let index
    let singleClick = true
    console.log('has acces', this.state.HAS_ACCESS_LAST_INDEX)
    console.log('no acces', this.state.NO_ACCESS_LAST_INDEX)
    if (e.shiftKey) {
      let tableRows
      console.log('Shift was HELD at index ' + i)
      if (this.props.hasAccess && this.state.HAS_ACCESS_LAST_INDEX !== null && this.props.REMOVE_ACCESS.length > 0) {
        singleClick = false
        tableRows = this.addRemoveRows()
          .slice(this.state.HAS_ACCESS_LAST_INDEX + 1, i + 1)
          .filter(e => {
            let flag = true
            for (let row of this.props.REMOVE_ACCESS) {
              if (JSON.stringify(e) === JSON.stringify(row)) {
                flag = false
              }
            }
            return flag
          })
        this.props.removeAccess({ config: tableRows })
        this.setState({
          HAS_ACCESS_LAST_INDEX: i
        })
      } else if (this.state.NO_ACCESS_LAST_INDEX !== null && this.props.GRANT_ACCESS.length > 0) {
        singleClick = false
        console.log(this.props.GRANT_ACCESS)
        tableRows = this.addRemoveRows()
          .slice(this.state.NO_ACCESS_LAST_INDEX + 1, i + 1)
          .filter(e => {
            let flag = true
            for (let row of this.props.GRANT_ACCESS) {
              if (JSON.stringify(e) === JSON.stringify(row)) {
                flag = false
              }
            }
            return flag
          })
        this.props.grantAccess({ config: tableRows })
        this.setState({
          NO_ACCESS_LAST_INDEX: i
        })
      }
    }

    if (singleClick) {
      if (this.props.hasAccess) {
        index = this.props.REMOVE_ACCESS.indexOf(data)
        if (index === -1) {
          this.props.removeAccess({ config: [data] })
          this.setState({
            HAS_ACCESS_LAST_INDEX: i
          })
        } else {
          this.props.removeAccess({ i: index })
          this.setState({
            HAS_ACCESS_LAST_INDEX: this.props.REMOVE_ACCESS.length > 1 ? i : null
          })
        }
      } else {
        console.log('dabra')
        index = this.props.GRANT_ACCESS.indexOf(data)
        if (index === -1) {
          this.props.grantAccess({ config: [data] })
          this.setState({
            NO_ACCESS_LAST_INDEX: i
          })
        } else {
          this.props.grantAccess({ i: index })
          this.setState({
            NO_ACCESS_LAST_INDEX: this.props.GRANT_ACCESS.length > 1 ? i : null
          })
        }
      }
    }
  }

  handleRowSelects = data => {
    if (this.props.hasAccess) {
      return this.props.REMOVE_ACCESS.indexOf(data) !== -1 ? { background: '#DD6973' } : null
    } else {
      return this.props.GRANT_ACCESS.indexOf(data) !== -1 ? { background: '#C8E3EF' } : null
    }
  }

  addRemoveRows = () => {
    let table
    const category = this.props.category
    const query = this.props.query
    if (this.props.hasAccess) {
      table = [...this.filteredValues(), ...this.props.COMMIT_GRANT_ACCESS]
        .sort((a, b) => {
          if (a.CONFIG_KEY.toLowerCase() < b.CONFIG_KEY.toLowerCase()) {
            return -1
          }
          if (a.CONFIG_KEY.toLowerCase() > b.CONFIG_KEY.toLowerCase()) {
            return 1
          }
          return 0
        })
        .filter(element => {
          if (category === 'Scope Name') return element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase())
          else if (category === 'Group Name') return element.GROUP_NAME.toLowerCase().includes(query.toLowerCase())
          else if (category === 'Any')
            return (
              element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) ||
              element.GROUP_NAME.toLowerCase().includes(query.toLowerCase()) ||
              element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase())
            )
          else return element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase())
        })
    } else {
      table = [...this.filteredValues(), ...this.props.COMMIT_REMOVE_ACCESS]
        .sort((a, b) => {
          if (a.CONFIG_KEY.toLowerCase() < b.CONFIG_KEY.toLowerCase()) {
            return -1
          }
          if (a.CONFIG_KEY.toLowerCase() > b.CONFIG_KEY.toLowerCase()) {
            return 1
          }
          return 0
        })
        .filter(element => {
          if (category === 'Scope Name') return element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase())
          else if (category === 'Group Name') return element.GROUP_NAME.toLowerCase().includes(query.toLowerCase())
          else if (category === 'Any')
            return (
              element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) ||
              element.GROUP_NAME.toLowerCase().includes(query.toLowerCase()) ||
              element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase())
            )
          else return element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase())
        })
    }
    return table
  }

  render() {
    let accessTable = (
      <Table responsive bordered striped>
        <thead>
          <tr className="d-flex">
            <th className="col-sm-6">Config Key</th>
            <th className="col-sm-3">Scope Name</th>
            <th className="col-sm-3">Group Name</th>
          </tr>
        </thead>
        <tbody>
          {this.addRemoveRows().map((configuration, index) => {
            return (
              <tr key={this.props.database + index} className="d-flex">
                <td className="col-sm-6"> {configuration.CONFIG_KEY} </td>
                <td className="col-sm-3"> {configuration.SCOPE_NAME} </td>
                <td className="col-sm-3"> {configuration.GROUP_NAME} </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )

    if (this.props.canEdit) {
      accessTable = (
        <Table bordered striped hover>
          <thead>
            <tr className="d-flex">
              <th className="col-sm-6">Config Key</th>
              <th className="col-sm-3">Scope Name</th>
              <th className="col-sm-3">Group Name</th>
            </tr>
          </thead>
          <tbody>
            {this.addRemoveRows().map((configuration, index) => {
              return (
                <tr
                  style={this.handleRowSelects(configuration)}
                  key={this.props.database + index}
                  className="d-flex"
                  onClick={e => this.handleRowClick(configuration, e, index)}
                >
                  <td className="col-sm-6"> {configuration.CONFIG_KEY} </td>
                  <td className="col-sm-3"> {configuration.SCOPE_NAME} </td>
                  <td className="col-sm-3"> {configuration.GROUP_NAME} </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )
    }
    return (
      <div className="Table">
        <div className="table-wrapper-scroll-y my-custom-scrollbar">{accessTable}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    accessKeys: state.loadData.accessKeys,
    GRANT_ACCESS: state.editConfigAccess.grant,
    REMOVE_ACCESS: state.editConfigAccess.remove,
    COMMIT_GRANT_ACCESS: state.editConfigAccess.commitGrant,
    COMMIT_REMOVE_ACCESS: state.editConfigAccess.commitRemove,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ grantAccess, removeAccess }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileAccessTable)
