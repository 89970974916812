import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'redux/reducers/reducers'

let storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
if (process.env.NODE_ENV === 'production') {
  storeEnhancers = compose
}

const store = createStore(rootReducer, {}, storeEnhancers(applyMiddleware(thunk)))
// console.log(store.getState());
console.log(process.env.NODE_ENV)

export default store
