import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { manageModal, closeModal } from "redux/actions/manageModal"
import { importFromFile } from "redux/actions/postData"
import { setError } from "redux/actions/getData"
import { importConfigs } from "services/importConfigXML"
import ConfigurationMenu from "../modals/ScopeConfiguration/ConfigurationMenu"
import ScopeMenu from "../modals/ScopeConfiguration/ScopeMenu"
import GroupMenu from "../modals/ScopeConfiguration/GroupMenu"
import UserMenu from "../modals/ScopeConfiguration/UserMenu"
import ProfileMenu from "../modals/ScopeConfiguration/ProfileMenu"
import ConfigAccessMenu from "../modals/ScopeConfiguration/ConfigAccessMenu"
import EditConfigMenu from "../modals/ScopeConfiguration/EditConfigMenu"
import StateDefMenu from "../modals/StateConfiguration/StateDefMenu"
import EditStateDefMenu from "../modals/StateConfiguration/EditStateDefMenu"
import StateDescMenu from "../modals/StateConfiguration/StateDescMenu"
import EditStateDescMenu from "../modals/StateConfiguration/EditStateDescMenu"
import EditPropertiesMenu from "../modals/Properties/EditPropertiesMenu"
import PropertiesMenu from "../modals/Properties/PropertiesMenu"
import EditCityMenu from "../modals/Regions/EditCityMenu"
import EditCountryMenu from "../modals/Regions/EditCountryMenu"
import EditCountryIndexMenu from "../modals/Regions/EditCountryIndexMenu"
import CityMenu from "../modals/Regions/CityMenu"
import CountryMenu from "../modals/Regions/CountryMenu"
import LoginMenu from "../modals/LoginMenu"
import ErrorMenu from "../modals/ErrorMenu"
import PNRBuilderMenu from "../modals/ScopeConfiguration/PNRBuilderMenu"
import BoardingPassBuilder from "../modals/ScopeConfiguration/BoardingPassBuilder"
import ExportConfigMenu from "../modals/ScopeConfiguration/ExportConfigMenu"
import Button from "react-bootstrap/Button"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"

class Buttons extends React.Component {
  loadFile = (event) => {
    let file = null
    file = event.target.files[0]
    if (file !== "") {
      let reader = new FileReader()
      reader.onload = () =>
        importConfigs({
          xml: reader.result,
          fileName: file.name,
          ...this.props,
        })
      reader.readAsText(file)
    }
  }

  render() {
    let buttonToolbar = null
    if (this.props.configTableShow) {
      if (this.props.access.SCOPE_ACCESS.includes("W")) {
        buttonToolbar = (
          <ButtonToolbar>
            <Button
              variant="dark"
              onClick={() =>
                this.props.manageModal({ currentModal: "configMenuShow" })
              }
            >
              Configuration Menu
            </Button>
            <ConfigurationMenu
              text="Configuration Menu"
              show={this.props.currentModal === "configMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <Button
              variant="dark"
              onClick={() =>
                this.props.manageModal({ currentModal: "scopeMenuShow" })
              }
            >
              Scope Menu
            </Button>
            <ScopeMenu
              text="Scope Menu"
              show={this.props.currentModal === "scopeMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <Button
              variant="dark"
              onClick={() =>
                this.props.manageModal({ currentModal: "groupMenuShow" })
              }
            >
              Group Menu
            </Button>
            <GroupMenu
              text="Group Menu"
              show={this.props.currentModal === "groupMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <Button
              variant="dark"
              onClick={() =>
                this.props.manageModal({ currentModal: "userMenuShow" })
              }
              disabled={!this.props.isAdmin}
            >
              User Menu
            </Button>
            <UserMenu
              text="User Menu"
              show={this.props.currentModal === "userMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <Button
              variant="dark"
              onClick={() =>
                this.props.manageModal({ currentModal: "profileMenuShow" })
              }
              disabled={!this.props.isAdmin}
            >
              Profile Menu
            </Button>
            <ProfileMenu
              text="Profile Menu"
              show={this.props.currentModal === "profileMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <ConfigAccessMenu
              text="Edit Profile Access Keys"
              show={this.props.currentModal === "configAccessMenuShow"}
              onHide={() =>
                this.props.manageModal({ currentModal: "profileMenuShow" })
              }
            />
            <Button
              variant="dark"
              onClick={() =>
                this.props.manageModal({ currentModal: "exportConfigsShow" })
              }
            >
              Export Configs
            </Button>
            <ExportConfigMenu
              text="Export Configuration"
              show={this.props.currentModal === "exportConfigsShow"}
              onHide={() => this.props.closeModal()}
            />
            <input
              id="myInput"
              type="file"
              value=""
              ref={(ref) => (this.myInput = ref)}
              style={{ display: "none" }}
              onChange={this.loadFile}
              accept=".xml,application/xml"
            />
            <Button variant="dark" onClick={() => this.myInput.click()}>
              Import Configs
            </Button>
            <EditConfigMenu
              text="Edit Configuration"
              show={this.props.currentModal === "editConfigMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </ButtonToolbar>
        )
      } else {
        buttonToolbar = (
          <ButtonToolbar>
            <Button variant="dark" disabled>
              Configuration Menu
            </Button>
            <Button variant="dark" disabled>
              Scope Menu
            </Button>
            <Button variant="dark" disabled>
              Group Menu
            </Button>
            <Button variant="dark" disabled>
              User Menu
            </Button>
            <Button variant="dark" disabled>
              Profile Menu
            </Button>
            <Button
              variant="dark"
              onClick={() =>
                this.props.manageModal({ currentModal: "exportConfigsShow" })
              }
            >
              Export Configs
            </Button>
            <ExportConfigMenu
              text="Export Configuration"
              show={this.props.currentModal === "exportConfigsShow"}
              onHide={() => this.props.closeModal()}
            />
            <Button variant="dark" disabled>
              Import Configs
            </Button>
            <EditConfigMenu
              text="View Configuration"
              show={this.props.currentModal === "editConfigMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </ButtonToolbar>
        )
      }
    } else if (this.props.stateDefTableShow) {
      if (this.props.access.STATE_ACCESS.includes("W")) {
        buttonToolbar = (
          <div>
            <ButtonToolbar>
              <Button
                variant="dark"
                onClick={() =>
                  this.props.manageModal({ currentModal: "stateDefMenuShow" })
                }
              >
                State Definition Menu
              </Button>
              <Button
                variant="dark"
                onClick={() =>
                  this.props.manageModal({ currentModal: "stateDescMenuShow" })
                }
              >
                State Description Menu
              </Button>
            </ButtonToolbar>
            <StateDefMenu
              text="State Definition Menu"
              show={this.props.currentModal === "stateDefMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <StateDescMenu
              text="State Description Menu"
              show={this.props.currentModal === "stateDescMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditStateDefMenu
              text="Edit State Definition"
              show={this.props.currentModal === "editStateDefMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditStateDescMenu
              text="Edit State Description"
              show={this.props.currentModal === "editStateDescMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </div>
        )
      } else {
        buttonToolbar = (
          <div>
            <ButtonToolbar>
              <Button variant="dark" disabled>
                State Definition Menu
              </Button>
              <Button variant="dark" disabled>
                State Description Menu
              </Button>
            </ButtonToolbar>
            <EditStateDefMenu
              text="View State Definition"
              show={this.props.currentModal === "editStateDefMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditStateDescMenu
              text="View State Description"
              show={this.props.currentModal === "editStateDescMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </div>
        )
      }
    } else if (this.props.propertiesTableShow) {
      if (this.props.access.PROPERTIES_ACCESS.includes("W")) {
        buttonToolbar = (
          <div>
            <ButtonToolbar>
              <Button
                variant="dark"
                onClick={() =>
                  this.props.manageModal({ currentModal: "propertiesMenuShow" })
                }
              >
                Properties Menu
              </Button>
            </ButtonToolbar>
            <PropertiesMenu
              text="Properties Menu"
              show={this.props.currentModal === "propertiesMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditPropertiesMenu
              text="Edit Properties"
              show={this.props.currentModal === "editPropertiesMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </div>
        )
      } else {
        buttonToolbar = (
          <div>
            <ButtonToolbar>
              <Button variant="dark" disabled>
                Properties Menu
              </Button>
            </ButtonToolbar>

            <EditPropertiesMenu
              text="View Property"
              show={this.props.currentModal === "editPropertiesMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </div>
        )
      }
    } else if (this.props.regionalTableShow) {
      if (this.props.access.REGION_ACCESS.includes("W")) {
        buttonToolbar = (
          <div>
            <ButtonToolbar>
              <Button
                variant="dark"
                onClick={() =>
                  this.props.manageModal({ currentModal: "cityMenuShow" })
                }
              >
                City Menu
              </Button>
              <Button
                variant="dark"
                onClick={() =>
                  this.props.manageModal({ currentModal: "countryMenuShow" })
                }
              >
                Country Menu
              </Button>
            </ButtonToolbar>
            <EditCityMenu
              text="Edit City"
              show={this.props.currentModal === "editCityMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditCountryMenu
              text="Edit Country"
              show={this.props.currentModal === "editCountryMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditCountryIndexMenu
              text="Edit Country Index"
              show={this.props.currentModal === "editCountryIndexMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <CityMenu
              text="City Menu"
              show={this.props.currentModal === "cityMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <CountryMenu
              text="Country Menu"
              show={this.props.currentModal === "countryMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </div>
        )
      } else {
        buttonToolbar = (
          <div>
            <ButtonToolbar>
              <Button variant="dark" disabled>
                Country Menu
              </Button>
              <Button variant="dark" disabled>
                City Menu
              </Button>
            </ButtonToolbar>

            <EditCountryMenu
              text="View Country"
              show={this.props.currentModal === "editCountryMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditCountryIndexMenu
              text="View Country Index"
              show={this.props.currentModal === "editCountryIndexMenuShow"}
              onHide={() => this.props.closeModal()}
            />
            <EditCityMenu
              text="View City"
              show={this.props.currentModal === "editCityMenuShow"}
              onHide={() => this.props.closeModal()}
            />
          </div>
        )
      }
    } else {
      buttonToolbar = (
        <ButtonToolbar>
          <Button variant="dark" disabled>
            Configuration Menu
          </Button>
          <Button variant="dark" disabled>
            Scope Menu
          </Button>
          <Button variant="dark" disabled>
            Group Configs
          </Button>
          <Button variant="dark" disabled>
            User Menu
          </Button>
          <Button variant="dark" disabled>
            Profile Menu
          </Button>
          <Button variant="dark" disabled>
            Export Configs
          </Button>
          <Button variant="dark" disabled>
            Import Configs
          </Button>
        </ButtonToolbar>
      )
    }
    const loginMenuPopup =
      this.props.currentModal === "loginMenuShow" ? (
        <LoginMenu text="Login" show={true} history={this.props.history} />
      ) : (
        ""
      )
    return (
      <div className="Buttons">
        {buttonToolbar}
        {loginMenuPopup}
        <ErrorMenu text="Error" show={!!this.props.error} />
        <PNRBuilderMenu
          text="PNR Builder Menu"
          show={this.props.currentModal === "pnrMenuShow"}
          onHide={() => this.props.closeModal()}
        />
        <BoardingPassBuilder
          text="Boarding Pass Generator"
          show={this.props.currentModal === "generateBpShow"}
          onHide={() => this.props.closeModal()}
        />
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { manageModal, closeModal, importFromFile, setError },
    dispatch
  )
}

const mapStateToProps = (state) => {
  return {
    configTableShow: state.changeTable.configTableShow,
    stateDefTableShow: state.changeTable.stateDefTableShow,
    regionalTableShow: state.changeTable.regionalTableShow,
    propertiesTableShow: state.changeTable.propertiesTableShow,
    currentModal: state.manageModal.currentModal,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database,
    access: state.accountLogin,
    scopes: state.loadData.scopes,
    groups: state.loadData.groups,
    configs: state.loadData.configs,
    error: state.loadData.error,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Buttons)
