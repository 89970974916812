import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import { Typeahead } from 'react-bootstrap-typeahead'

class BoardingPassBuilder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      PNR: ''
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handlePNR = event => {
    let value = this.state.PNR
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      let pnr = value
      this.setState({
        PNR: pnr
      })
    }
  }

  generateBP = (
    pnr,
    firstName,
    lastName,
    from,
    to,
    airline,
    flightNumber,
    seatClass = 'B',
    seatNumber = '25A',
    sequence = '0012',
    paxStatus = '1'
  ) => {
    let today = new Date()
    let jd = Math.ceil((today - new Date(today.getFullYear(), 0, 1)) / 86400000)
    let bp =
      'M1' +
      (lastName.substring(0, 10) + '/' + firstName.substring(0, 9)).toUpperCase().padEnd(20, ' ') +
      'E' +
      pnr.toUpperCase().padEnd(7) +
      from.toUpperCase().substring(0, 3) +
      to.toUpperCase().substring(0, 3) +
      airline.toUpperCase().substring(0, 3) +
      ' ' +
      flightNumber.padStart(4, '0') +
      ' ' +
      jd.toString().padStart(3, '0') +
      seatClass.substring(0, 1) +
      seatNumber.padStart(4, '0') +
      sequence.toString().padStart(4, '0') +
      ' ' +
      paxStatus.substring(0, 1) +
      '00'
    return bp
  }

  parseBooking = () => {
    let booking = this.props.configs.find(e => e.CONFIG_KEY === this.state.PNR)
    if (!booking) return null
    // let bookingJson = JSON.parse(booking)
    // let barcodes = []
    // generateBP(this.state.PNR, )
  }

  getUniqueList = category => {
    let list = []
    if (category === 'PNR') {
      list = this.props.configs
        .filter(elem => elem.GROUP_ID.toString() === this.props.group)
        .map(e => {
          return e.CONFIG_KEY
        })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="danger" onClick={() => this.props.closeModal}>
          Close
        </Button>
      </Modal.Footer>
    )

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="2">
                PNR :
              </Form.Label>
              <Col sm>
                <InputGroup className="mb-2">
                  <Typeahead
                    options={this.getUniqueList('PNR')}
                    type={'PNR'}
                    id={'PNR'}
                    selected={[this.state.PNR]}
                    onChange={this.handlePNR}
                    onInputChange={this.handlePNR}
                    highlightOnlyResult
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.configs,
    group: state.manageModal.simulatorGroup
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoardingPassBuilder)
