import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { manageModal, loadProperty } from 'redux/actions/manageModal'
import Table from 'react-bootstrap/Table'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

class PropertiesTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      INITIALIZE: true,
      COLUMN_SORT: 'Section Name',
      EDIT_CONFIG: {},
      desc: true,
      CLIENT_TYPE: ''
    }
  }

  componentDidUpdate() {
    if (this.state.INITIALIZE && this.props.isNewPropertiesTable) {
      this.setState({
        CLIENT_TYPE: this.props.properties[0].CLIENT_TYPE,
        INITIALIZE: false
      })
    }
  }

  filteredValues = () => {
    const category = this.props.category
    const query = this.props.query
    const filteredData = this.props.properties
      .filter(element => {
        if (!!this.state.CLIENT_TYPE) return element.CLIENT_TYPE === this.state.CLIENT_TYPE
        return true
      })
      .filter(element => {
        element.PROPERTY_NAME = !!element.PROPERTY_NAME ? element.PROPERTY_NAME : ''
        element.PROPERTY_VALUE = !!element.PROPERTY_VALUE ? element.PROPERTY_VALUE : ''
        element.SECTION_NAME = !!element.SECTION_NAME ? element.SECTION_NAME : ''
        if (category === 'Property Name') return element.PROPERTY_NAME.toLowerCase().includes(query.toLowerCase())
        else if (category === 'Property Value')
          return element.PROPERTY_VALUE.toLowerCase().includes(query.toLowerCase())
        else if (category === 'Any')
          return (
            element.SECTION_NAME.toLowerCase().includes(query.toLowerCase()) ||
            element.PROPERTY_NAME.toLowerCase().includes(query.toLowerCase()) ||
            element.PROPERTY_VALUE.toLowerCase().includes(query.toLowerCase())
          )
        else return element.SECTION_NAME.toLowerCase().includes(this.props.query.toLowerCase())
      })

    if (this.state.COLUMN_SORT === 'Section Name') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.SECTION_NAME.toLowerCase() > b.SECTION_NAME.toLowerCase()
            ? 1
            : b.SECTION_NAME.toLowerCase() > a.SECTION_NAME.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.SECTION_NAME.toLowerCase() > b.SECTION_NAME.toLowerCase()
            ? -1
            : b.SECTION_NAME.toLowerCase() > a.SECTION_NAME.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Property Name') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.PROPERTY_NAME.toLowerCase() > b.PROPERTY_NAME.toLowerCase()
            ? 1
            : b.PROPERTY_NAME.toLowerCase() > a.PROPERTY_NAME.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.PROPERTY_NAME.toLowerCase() > b.PROPERTY_NAME.toLowerCase()
            ? -1
            : b.PROPERTY_NAME.toLowerCase() > a.PROPERTY_NAME.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === 'Property Value') {
      if (this.state.desc)
        filteredData.sort(function(a, b) {
          return a.PROPERTY_VALUE.toLowerCase() > b.PROPERTY_VALUE.toLowerCase()
            ? 1
            : b.PROPERTY_VALUE.toLowerCase() > a.PROPERTY_VALUE.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function(a, b) {
          return a.PROPERTY_VALUE.toLowerCase() > b.PROPERTY_VALUE.toLowerCase()
            ? -1
            : b.PROPERTY_VALUE.toLowerCase() > a.PROPERTY_VALUE.toLowerCase()
            ? 1
            : 0
        })
    }

    return filteredData
  }

  handleClientChange = event => {
    const key = event.toString()
    console.log(key)
    this.setState({
      CLIENT_TYPE: key
    })
  }

  handleHeaderClick = column => {
    if (column === this.state.COLUMN_SORT) {
      this.setState({
        desc: !this.state.desc
      })
    } else {
      this.setState({
        COLUMN_SORT: column,
        desc: true
      })
    }
  }

  handleColumnSelects = data => {
    if (data === this.state.COLUMN_SORT) {
      if (this.state.desc) return { borderTop: '2px solid #3F4766' }
      else return { borderBottom: '2px solid #3F4766' }
    } else {
      return null
    }
  }

  handleRowClick = data => {
    console.log(data)
    if (JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG)) {
      this.props.loadProperty({ editProperty: this.state.EDIT_CONFIG })
      this.props.manageModal({ currentModal: 'editPropertiesMenuShow' })
    } else {
      this.setState({
        EDIT_CONFIG: data
      })
    }
  }

  handleRowSelects = data => {
    return JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG) ? { background: '#C8E3EF' } : null
  }

  getUniqueClientTypes = () => {
    let list = this.props.properties
      .map(elem => {
        return elem.CLIENT_TYPE
      })
      .filter(e => e !== '' && !!e)
      .sort()
    const listUnique = [...new Set(list)]
    return listUnique
  }

  render() {
    return (
      <div className="Table">
        {this.props.isNewPropertiesTable && (
          <Tabs id="clientTypes" activeKey={this.state.CLIENT_TYPE} onSelect={k => this.handleClientChange(k)}>
            {this.getUniqueClientTypes().map(e => {
              return <Tab key={this.props.database + e} eventKey={e} title={e}></Tab>
            })}
          </Tabs>
        )}
        <Table responsive striped bordered hover>
          <thead>
            <tr className="d-flex">
              <th
                className="col-sm-3"
                style={this.handleColumnSelects('Section Name')}
                onClick={() => this.handleHeaderClick('Section Name')}
              >
                Section Name
              </th>
              <th
                className="col-sm-3"
                style={this.handleColumnSelects('Property Name')}
                onClick={() => this.handleHeaderClick('Property Name')}
              >
                Property Name
              </th>
              <th
                className="col-sm-6"
                style={this.handleColumnSelects('Property Value')}
                onClick={() => this.handleHeaderClick('Property Value')}
              >
                Property Value
              </th>
            </tr>
          </thead>
          <tbody>
            {this.filteredValues().map((property, index) => {
              return (
                <tr
                  key={this.props.database + index}
                  className="d-flex"
                  style={this.handleRowSelects(property)}
                  onClick={() => this.handleRowClick(property)}
                >
                  <td className="col-sm-3"> {property.SECTION_NAME} </td>
                  <td className="col-sm-3"> {property.PROPERTY_NAME} </td>
                  <td className="col-sm-6"> {property.PROPERTY_VALUE} </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    properties: state.loadData.properties,
    query: state.filterData.query,
    category: state.filterData.filterColumn,
    database: state.accountLogin.database,
    isNewPropertiesTable: state.loadData.isNewPropertiesTable
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ manageModal, loadProperty }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesTable)
