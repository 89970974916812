const initialState = {
  updateData: null
}
const updateResponses = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATED_DATA':
      return { updatedData: action.data }
    case 'RESET_UPDATED_DATA':
      return { updatedData: null }
    default:
      return state
  }
}

export default updateResponses
