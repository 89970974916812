import {
  LOGIN_ATTEMPT,
  CONFIRM_ADMIN,
  DATABASE_LOADED,
  DESTROY_SESSION,
} from "../constants/action-types"

export function login(payload) {
  console.log("PAYLOAD", payload)
  return { type: LOGIN_ATTEMPT, payload }
}

export function logout() {
  return { type: DESTROY_SESSION }
}

export function isAdmin() {
  return { type: CONFIRM_ADMIN }
}

export function setDatabase(payload) {
  return { type: DATABASE_LOADED, payload }
}
