import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeModal } from 'redux/actions/manageModal'
import { deleteStateDesc, saveStateDesc } from 'redux/actions/postData'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { by639_1 } from 'iso-language-codes'

class EditStateDescMenu extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {}
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.editState) !== JSON.stringify(prevProps.editState)) {
      this.setState({
        ...this.props.editState
      })
    }
  }

  handleLanguageCode = event => {
    const value = event.target.value.toUpperCase()
    console.log(value)
    this.setState({
      LANGUAGE_CODE: value
    })
  }

  handleShortDesc = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      SHORT_DESC: value
    })
  }

  handleKacMsg = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      KAC_MSG: value
    })
  }

  handleStampingMsg = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      STAMPING_MSG: value
    })
  }

  handleScreenMsg = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      SCREEN_MSG: value
    })
  }

  getUniqueList = category => {
    let list = []
    if (category === 'LANGUAGE_CODE') {
      list = Object.keys(by639_1).map(elem => {
        return elem.toUpperCase()
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  isChanged = () => {
    let prevProp = this.props.editState
    return (
      this.state.STATE_ID !== prevProp.STATE_ID ||
      this.state.LANGUAGE_CODE !== prevProp.LANGUAGE_CODE ||
      this.state.SHORT_DESC !== prevProp.SHORT_DESC ||
      this.state.KAC_MSG !== prevProp.KAC_MSG ||
      this.state.STAMPING_MSG !== prevProp.STAMPING_MSG ||
      this.state.SCREEN_MSG !== prevProp.SCREEN_MSG
    )
  }

  saveConfig = () => {
    const req = { ...this.state }
    console.log(req)
    this.props.saveStateDesc(req)
    this.resetForm()
    this.props.closeModal()
  }

  deleteConfig = () => {
    const req = { ...this.props.editState }
    console.log(req)
    this.props.deleteStateDesc(req)
    this.resetForm()
    this.props.closeModal()
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="danger" onClick={this.deleteConfig}>
          Delete
        </Button>
        <Button variant="success" onClick={this.saveConfig} disabled>
          Save
        </Button>
      </Modal.Footer>
    )

    if (this.isChanged()) {
      footer = (
        <Modal.Footer>
          <Button variant="danger" onClick={this.deleteConfig}>
            Delete
          </Button>
          <Button variant="success" onClick={this.saveConfig}>
            Save
          </Button>
        </Modal.Footer>
      )
    }

    const isView = this.props.text.includes('View')
    if (isView) {
      footer = null
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextConfigKey">
              <Form.Label column sm="4">
                State ID<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="STATE_ID" value={this.state.STATE_ID} disabled />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Language Code<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control as="select" onChange={this.handleLangCode} value={this.state.LANGUAGE_CODE} disabled>
                  {this.getUniqueList('LANGUAGE_CODE').map(e => {
                    return (
                      <option key={this.props.database + e} value={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Short Description:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="SHORT_DESC"
                  value={this.state.SHORT_DESC}
                  onChange={this.handleShortDesc}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                KAC Message:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="KAC_MSG"
                  value={this.state.KAC_MSG}
                  onChange={this.handleKacMsg}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Stamping Message:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="STAMPING_MSG"
                  value={this.state.STAMPING_MSG}
                  onChange={this.handleStampingMsg}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Screen Message:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="SCREEN_MSG"
                  as="textarea"
                  rows="3"
                  value={this.state.SCREEN_MSG}
                  onChange={this.handleScreenMsg}
                  disabled={isView}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.stateDef,
    stateDesc: state.loadData.stateDesc,
    editState: state.manageModal.editState,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteStateDesc, saveStateDesc, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStateDescMenu)
