import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postGroup, deleteGroup } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class GroupMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      GROUP_NAME: '',
      GROUP_ID: '',
      LAST_UPDATED: '',
      PROPERTY_NAME: '',
      groupExists: []
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleGroupName = event => {
    let groupName = event.target.textContent
    if (groupName === '') {
      groupName = event.target.value
    }

    let groupExists = this.props.groups.filter(group => groupName === group.GROUP_NAME)

    if (groupExists.length > 0) {
      this.setState({
        GROUP_ID: groupExists[0].GROUP_ID
      })
    } else {
      this.setState({
        GROUP_ID: this.getGroupId(),
        PROPERTY_NAME: ''
      })
    }
    this.setState({
      GROUP_NAME: groupName,
      groupExists: groupExists
    })
    console.log(this.state)
  }

  getGroupId = () => {
    let counter = 0
    this.props.groups.map(id => {
      if (id.GROUP_ID === counter) {
        counter++
        return null
      } else {
        return counter
      }
    })
    return counter
  }

  handlePropertyName = event => {
    const propertyValue = event.target.value
    let propertyName = this.props.properties.filter(property => propertyValue === property.PROPERTY_VALUE)
    this.setState({
      PROPERTY_NAME: propertyName[0].PROPERTY_NAME
    })
    console.log(this.state)
  }

  addGroup = () => {
    const date = new Date()
    console.log({ ...this.state, LAST_UPDATED: date.getTime() })
    this.props.postGroup({ ...this.state })
    console.log('scope Added')
    this.resetForm()
    this.props.closeModal()
  }

  deleteGroup = () => {
    const defaultGroup = this.props.groups.find(e => e.GROUP_NAME === 'DefaultGroup')
    const defaultAccessKeys = this.props.accessKeys.filter(e => {
      return e.GROUP_ID === this.state.GROUP_ID
    })
    const defaultConfigs = this.props.configs.filter(e => {
      return e.GROUP_ID === this.state.GROUP_ID
    })
    const req = {
      ...this.state,
      DEFAULT_GROUP: defaultGroup.GROUP_ID,
      defaultAccessKeys: defaultAccessKeys,
      defaultConfigs: defaultConfigs
    }
    console.log(req)
    this.props.deleteGroup(req)
    console.log('scope Delete')
    this.resetForm()
    this.props.closeModal()
  }

  findPropertyName = () => {
    let name = ''
    this.props.properties.map(property => {
      if (property.PROPERTY_VALUE === this.state.GROUP_NAME) return (name = property.PROPERTY_NAME)
      else return null
    })
    return name
  }

  render() {
    let addDeleteButtons = (
      <Modal.Footer>
        <Button variant="danger" onClick={this.deleteGroup} disabled>
          Delete
        </Button>
        <Button variant="success" onClick={this.addGroup} disabled>
          Add
        </Button>
      </Modal.Footer>
    )
    let propertyName = null
    if (this.state.groupExists.length > 0) {
      propertyName = (
        <Form.Group as={Row} controlId="formPlaintextPropertyName">
          <Form.Label column sm="3">
            {' '}
            Property Name:{' '}
          </Form.Label>
          <Col sm="9">
            <Form.Control as="select" disabled>
              <option>{this.findPropertyName()}</option>
            </Form.Control>
          </Col>
        </Form.Group>
      )
      addDeleteButtons = (
        <Modal.Footer>
          {this.state.GROUP_NAME === 'DefaultGroup' && this.props.isAdmin && (
            <Button variant="outline-dark" onClick={this.deleteGroup}>
              Clear Configurations
            </Button>
          )}
          <Button variant="danger" onClick={this.deleteGroup} disabled={this.state.GROUP_NAME === 'DefaultGroup'}>
            Delete
          </Button>
          <Button variant="success" disabled>
            Add
          </Button>
        </Modal.Footer>
      )
    } else {
      propertyName = null
      if (this.state.GROUP_NAME.length > 0) {
        addDeleteButtons = (
          <Modal.Footer>
            <Button variant="danger" disabled>
              Delete
            </Button>
            <Button variant="success" onClick={this.addGroup}>
              Add
            </Button>
          </Modal.Footer>
        )
      }
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextGroupName">
              <Form.Label column sm="3">
                {' '}
                Group Name<p className="asterisk"> * </p>:{' '}
              </Form.Label>
              <Col sm="9">
                <InputGroup>
                  <Form.Control
                    aria-label="Group Name"
                    aria-describedby="basic-addon2"
                    value={this.state.GROUP_NAME}
                    onChange={this.handleGroupName}
                  />

                  <DropdownButton
                    as={InputGroup.Append}
                    title=""
                    variant="outline-secondary"
                    id="input-group-dropdown-2"
                  >
                    {this.props.groups.map(group => {
                      return (
                        <Dropdown.Item key={this.props.database + group.GROUP_ID} onClick={this.handleGroupName}>
                          {group.GROUP_NAME}
                        </Dropdown.Item>
                      )
                    })}
                  </DropdownButton>
                </InputGroup>
              </Col>
            </Form.Group>

            {propertyName}
          </Form>
        </Modal.Body>
        {addDeleteButtons}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.configs,
    accessKeys: state.loadData.accessKeys,
    groups: state.loadData.groups,
    properties: state.loadData.properties,
    database: state.accountLogin.database,
    isAdmin: state.accountLogin.isAdmin
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postGroup, deleteGroup, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupMenu)
