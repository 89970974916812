import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class ExportConfigMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      GROUP_ID: 'ALL',
      SCOPE_TYPE: 'ALL',
      SCOPE_NAME: 'ALL',
      CONFIG_KEY: 'ALL'
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  filteredData = () => {
    const filteredData = this.props.configs
      .filter(element => {
        let flag = false
        for (let key of this.props.accessKeys) {
          if (element.CONFIG_KEY === key.CONFIG_KEY && this.props.PROFILE_ID === key.PROFILE_ID) {
            flag = true
          }
        }
        return flag
      })
      .filter(element => {
        if (this.state.GROUP_ID !== 'ALL') {
          return element.GROUP_ID.toString() === this.state.GROUP_ID
        } else {
          return true
        }
      })
    return filteredData
  }

  filteredConfigs = () => {
    const filteredData = this.filteredData()
      .filter(element => {
        if (this.state.SCOPE_TYPE !== 'ALL') return element.SCOPE_TYPE === this.state.SCOPE_TYPE
        else return true
      })
      .filter(element => {
        if (this.state.SCOPE_NAME !== 'ALL') {
          return element.SCOPE_NAME === this.state.SCOPE_NAME
        } else {
          return true
        }
      })
    return filteredData
  }

  filteredScopeTypes = () => {
    let scopeTypes = ['GLOBAL', 'CHANNEL', 'STATION', 'KIOSKID']
    if (this.state.GROUP_ID !== 'ALL') {
      scopeTypes = [...new Set(this.filteredData().map(type => type.SCOPE_TYPE))]
    }
    return scopeTypes
  }

  filteredScopeNames = () => {
    const filteredData = this.filteredData().filter(element => {
      if (this.state.SCOPE_TYPE !== 'ALL') return element.SCOPE_TYPE === this.state.SCOPE_TYPE
      else return true
    })
    const scopeNames = [...new Set(filteredData.map(type => type.SCOPE_NAME))]
    return scopeNames
  }

  handleConfigKey = event => {
    const configKey = event.target.value
    this.setState({
      CONFIG_KEY: configKey
    })
  }

  handleScopeType = event => {
    const scopeType = event.target.value
    this.setState({
      SCOPE_TYPE: scopeType,
      SCOPE_NAME: 'ALL',
      CONFIG_KEY: 'ALL'
    })
  }

  handleScopeName = event => {
    const scopeName = event.target.value
    this.setState({
      SCOPE_NAME: scopeName,
      CONFIG_KEY: 'ALL'
    })
  }

  handleGroup = event => {
    const groupId = event.target.value
    this.setState({
      GROUP_ID: groupId,
      SCOPE_TYPE: 'ALL',
      SCOPE_NAME: 'ALL',
      CONFIG_KEY: 'ALL'
    })
  }

  findGroupId = groupId => {
    for (let group of this.props.groups) if (group.GROUP_ID === groupId) return group.GROUP_NAME
  }

  createXML = () => {
    let xml = '<?xml version="1.0" encoding="UTF-8"?>\n'
    xml += '<CONFIGS>\n'
    for (let scopeName of this.filteredScopeNames()) {
      if (this.state.SCOPE_NAME === 'ALL') {
        xml += this.createScope(scopeName)
      } else {
        if (scopeName === this.state.SCOPE_NAME) {
          xml += this.createScope(scopeName)
        }
      }
    }
    xml += '</CONFIGS>\n'

    return xml
  }

  createScope = scopeName => {
    let xml = '\t<SCOPE>\n\t\t<NAME>' + scopeName + '</NAME>\n'
    let scopeType = ''
    for (let scope of this.props.scopes) {
      if (scopeName === scope.SCOPE_NAME) {
        xml += '\t\t<TYPE>' + scope.SCOPE_TYPE + '</TYPE>\n'
        scopeType = scope.SCOPE_TYPE
      }
    }
    for (let config of this.filteredConfigs()) {
      if (scopeName === config.SCOPE_NAME && scopeType === config.SCOPE_TYPE && this.state.CONFIG_KEY === 'ALL') {
        xml +=
          '\t\t<CONFIG_VALUE>\n\t\t\t<KEY>' +
          config.CONFIG_KEY +
          '</KEY>\n\t\t\t<VALUE>' +
          config.VALUE +
          '</VALUE>\n\t\t\t<COMMENTS>' +
          config.COMMENTS +
          '</COMMENTS>\n\t\t\t<GROUP>' +
          this.findGroupId(config.GROUP_ID) +
          '</GROUP>\n\t\t</CONFIG_VALUE>\n'
      } else if (
        scopeName === config.SCOPE_NAME &&
        scopeType === config.SCOPE_TYPE &&
        this.state.CONFIG_KEY === config.CONFIG_KEY
      ) {
        xml +=
          '\t\t<CONFIG_VALUE>\n\t\t\t<KEY>' +
          config.CONFIG_KEY +
          '</KEY>\n\t\t\t<VALUE>' +
          config.VALUE +
          '</VALUE>\n\t\t\t<COMMENTS>' +
          config.COMMENTS +
          '</COMMENTS>\n\t\t\t<GROUP>' +
          this.findGroupId(config.GROUP_ID) +
          '</GROUP>\n\t\t</CONFIG_VALUE>\n'
      }
    }
    xml += '\t</SCOPE>\n'

    return xml
  }

  exportConfig = () => {
    console.log(this.state)
    const element = document.createElement('a')
    let myString = this.createXML()
    const file = new Blob([myString], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'CTSConfigs.xml'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  render() {
    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextGroup">
              <Form.Label column sm="2">
                {' '}
                Group:{' '}
              </Form.Label>
              <Col sm="10">
                <Form.Control as="select" onChange={this.handleGroup}>
                  <option key="null" value="ALL">
                    ALL
                  </option>
                  {this.props.groups.map(group => {
                    return (
                      <option key={this.props.database + group.GROUP_ID} value={group.GROUP_ID}>
                        {group.GROUP_NAME}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextScopeType">
              <Form.Label column sm="2">
                {' '}
                Scope Type:{' '}
              </Form.Label>
              <Col sm="10">
                <Form.Control as="select" onChange={this.handleScopeType} value={this.state.SCOPE_TYPE}>
                  <option>ALL</option>
                  {this.filteredScopeTypes().map(type => {
                    return (
                      <option key={this.props.database + type} value={type}>
                        {type}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextScopeType">
              <Form.Label column sm="2">
                {' '}
                Scope Name:{' '}
              </Form.Label>
              <Col sm="10">
                <Form.Control as="select" onChange={this.handleScopeName} value={this.state.SCOPE_NAME}>
                  <option key="null" value="ALL">
                    ALL
                  </option>
                  {this.filteredScopeNames().map((scope, index) => {
                    return (
                      <option key={this.props.database + index} value={scope}>
                        {scope}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextConfigKey">
              <Form.Label column sm="2">
                {' '}
                Config Key:{' '}
              </Form.Label>
              <Col sm="10">
                <Form.Control as="select" onChange={this.handleConfigKey} value={this.state.CONFIG_KEY}>
                  <option key="null" value="ALL">
                    ALL
                  </option>
                  {this.filteredConfigs().map((config, index) => {
                    return (
                      <option key={this.props.database + index} value={config.CONFIG_KEY}>
                        {config.CONFIG_KEY}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={this.exportConfig} download>
            Export Configs
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.configs,
    groups: state.loadData.groups,
    accessKeys: state.loadData.accessKeys,
    scopes: state.loadData.scopes,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportConfigMenu)
