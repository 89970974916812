import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeModal } from 'redux/actions/manageModal'
import { deleteStateDef, saveStateDef } from 'redux/actions/postData'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'

class EditStateDefMenu extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {}
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.editState) !== JSON.stringify(prevProps.editState)) {
      this.setState({
        ...this.props.editState
      })
    }
  }

  handleBusinessRef = event => {
    const re = /^[0-9\b]+$/
    let value = this.state.BUSINESS_REF_NO
    if (event.target.value === '' || re.test(event.target.value)) value = event.target.value
    console.log(value)
    this.setState({
      BUSINESS_REF_NO: value
    })
  }

  handleRepCategory = event => {
    let value = this.state.REP_CATEGORY
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase()
      console.log(value)
      this.setState({
        REP_CATEGORY: value
      })
    }
  }

  handleStateCategory = event => {
    let value = this.state.STATE_CATEGORY
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase()
      console.log(value)
      this.setState({
        STATE_CATEGORY: value
      })
    }
  }

  handleIsFatal = () => {
    let flag = null

    if (this.state.IS_FATAL === '0') {
      flag = '1'
    } else if (this.state.IS_FATAL === '1') {
      flag = '0'
    }

    console.log('IS FATAL', flag)
    this.setState({
      IS_FATAL: flag
    })
  }

  handleKacRequired = () => {
    let flag = null

    if (this.state.KAC_REQUIRED === '0') {
      flag = '1'
    } else if (this.state.KAC_REQUIRED === '1') {
      flag = '0'
    }

    console.log('KAC REQUIRED', flag)
    this.setState({
      KAC_REQUIRED: flag
    })
  }

  handleStampingRequired = () => {
    let flag = null

    if (this.state.STAMPING_REQUIRED === '0') {
      flag = '1'
    } else if (this.state.STAMPING_REQUIRED === '1') {
      flag = '0'
    }

    console.log('STAMPING REQUIRED', flag)
    this.setState({
      STAMPING_REQUIRED: flag
    })
  }

  handleUseForRep = () => {
    let flag = null
    if (this.state.USE_FOR_REP === '0') {
      flag = '1'
    } else if (this.state.USE_FOR_REP === '1') {
      flag = '0'
    }

    console.log('USE FOR REP', flag)
    this.setState({
      USE_FOR_REP: flag
    })
  }

  getUniqueList = category => {
    let list = []
    if (category === 'REP_CATEGORY') {
      list = this.props.configs.map(elem => {
        return !!elem.REP_CATEGORY ? elem.REP_CATEGORY.replace(/\s/g, '') : null
      })
    }
    if (category === 'STATE_CATEGORY') {
      list = this.props.configs.map(elem => {
        return !!elem.STATE_CATEGORY ? elem.STATE_CATEGORY.replace(/\s/g, '') : null
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  saveConfig = () => {
    const req = { ...this.state }
    console.log(req)
    this.props.saveStateDef(req)
    this.resetForm()
    this.props.closeModal()
  }

  deleteConfig = () => {
    const req = { ...this.props.editState }
    console.log(req)
    this.props.deleteStateDef(req)
    this.resetForm()
    this.props.closeModal()
  }

  isChanged = () => {
    let prevProp = this.props.editState
    return (
      this.state.STATE_ID !== prevProp.STATE_ID ||
      (!!this.state.BUSINESS_REF_NO ? this.state.BUSINESS_REF_NO.toString() : this.state.BUSINESS_REF_NO) !==
        (!!prevProp.BUSINESS_REF_NO ? prevProp.BUSINESS_REF_NO.toString() : prevProp.BUSINESS_REF_NO) ||
      this.state.IS_FATAL !== prevProp.IS_FATAL ||
      this.state.KAC_REQUIRED !== prevProp.KAC_REQUIRED ||
      this.state.STAMPING_REQUIRED !== prevProp.STAMPING_REQUIRED ||
      this.state.USE_FOR_REP !== prevProp.USE_FOR_REP ||
      this.state.REP_CATEGORY !== prevProp.REP_CATEGORY ||
      this.state.STATE_CATEGORY !== prevProp.STATE_CATEGORY
    )
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="danger" onClick={this.deleteConfig}>
          Delete
        </Button>
        <Button variant="success" onClick={this.saveConfig} disabled>
          Save
        </Button>
      </Modal.Footer>
    )

    if (this.isChanged()) {
      footer = (
        <Modal.Footer>
          <Button variant="danger" onClick={this.deleteConfig}>
            Delete
          </Button>
          <Button variant="success" onClick={this.saveConfig}>
            Save
          </Button>
        </Modal.Footer>
      )
    }
    const isView = this.props.text.includes('View')
    if (isView) {
      footer = null
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextConfigKey">
              <Form.Label column sm="4">
                State ID<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control type="STATE_ID" value={this.state.STATE_ID} disabled />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Business Reference Number:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="BUSINESS_REF_NO"
                  value={this.state.BUSINESS_REF_NO}
                  onChange={this.handleBusinessRef}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                Is Fatal:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch1"
                  label=""
                  checked={this.state.IS_FATAL === '1'}
                  onClick={this.handleIsFatal}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                KAC Required:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch2"
                  label=""
                  checked={this.state.KAC_REQUIRED === '1'}
                  onClick={this.handleKacRequired}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                Stamping Required:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch3"
                  label=""
                  checked={this.state.STAMPING_REQUIRED === '1'}
                  onClick={this.handleStampingRequired}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicCheckbox">
              <Form.Label column sm="4">
                Use For Rep:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch4"
                  label=""
                  checked={this.state.USE_FOR_REP === '1'}
                  onClick={this.handleUseForRep}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Rep Category:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('REP_CATEGORY')}
                  type="REP_CATEGORY"
                  id="REP_CATEGORY"
                  selected={[
                    !this.state.REP_CATEGORY ? this.state.REP_CATEGORY : this.state.REP_CATEGORY.replace(/\s/g, '')
                  ]}
                  onChange={this.handleRepCategory}
                  onInputChange={this.handleRepCategory}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled={isView}
                />
                {/* <Form.Control type="REP_CATEGORY" value={this.state.REP_CATEGORY} onChange={this.handleRepCategory}/> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                State Category:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('STATE_CATEGORY')}
                  type="STATE_CATEGORY"
                  id="STATE_CATEGORY"
                  selected={[this.state.STATE_CATEGORY]}
                  onChange={this.handleStateCategory}
                  onInputChange={this.handleStateCategory}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled={isView}
                />
                {/* <Form.Control type="STATE_CATEGORY" value={this.state.STATE_CATEGORY} onChange={this.handleStateCategory}/> */}
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    configs: state.loadData.stateDef,
    stateDesc: state.loadData.stateDesc,
    editState: state.manageModal.editState,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteStateDef, saveStateDef, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStateDefMenu)
