import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { manageModal, loadConfig } from "redux/actions/manageModal"
import Table from "react-bootstrap/Table"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Button from "react-bootstrap/Button"
import Jumbotron from "react-bootstrap/Jumbotron"
import Alert from "react-bootstrap/Alert"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isEmpty, isNotEmpty, validateJson } from "utils/helper"
import updateResponses from "redux/actions/responseAction"

class ConfigTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groupKey: "0",
      EDIT_CONFIG: null,
      COLUMN_SORT: "Config Key",
      desc: true,
    }
    this.index = 0
    this.showAlert = false
    this.updateData = null
    this.alertColors = ["secondary", "success", "danger", "warning"]
    this.filteredValues = this.getFilteredValues()
  }

  componentDidUpdate(prevProps) {
    // this.props.fetchConfigValues()
    this.filteredValues = this.getFilteredValues()
    if (
      JSON.stringify(this.props.groups) !== JSON.stringify(prevProps.groups)
    ) {
      this.setState({
        groupKey:
          this.props.groups.length > 0
            ? this.props.groups[0].GROUP_ID.toString()
            : "0",
      })
    }
    if (isEmpty(this.props.updatedData)) {
      this.resetUpdatedData()
    } else if (this.updateData !== this.props.updatedData) {
      if (this.index === this.alertColors.length - 1) {
        this.index = 0
      }
      this.showAlert = true
      this.index++
      this.updateData = this.props.updatedData
    }
  }

  getFilteredValues = () => {
    const category = this.props.category
    const query = this.props.query
    let filteredData = this.props.configs
      .filter((element) => {
        let flag = false
        for (let key of this.props.accessKeys) {
          if (
            element.CONFIG_KEY === key.CONFIG_KEY &&
            this.props.PROFILE_ID === key.PROFILE_ID &&
            element.GROUP_ID === key.GROUP_ID &&
            element.SCOPE_NAME === key.SCOPE_NAME
          ) {
            flag = true
          }
        }
        return flag
      })
      .filter((element) => {
        element.SCOPE_NAME = !!element.SCOPE_NAME ? element.SCOPE_NAME : ""
        element.SCOPE_TYPE = !!element.SCOPE_TYPE ? element.SCOPE_TYPE : ""
        element.VALUE = !!element.VALUE ? element.VALUE : ""
        element.COMMENTS = !!element.COMMENTS ? element.COMMENTS : ""
        if (category === "Scope Name")
          return (
            element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) &&
            element.GROUP_ID.toString().includes(this.state.groupKey)
          )
        else if (category === "Scope Type")
          return (
            element.SCOPE_TYPE.toLowerCase().includes(query.toLowerCase()) &&
            element.GROUP_ID.toString().includes(this.state.groupKey)
          )
        else if (category === "Value")
          return (
            element.VALUE.toLowerCase().includes(query.toLowerCase()) &&
            element.GROUP_ID.toString().includes(this.state.groupKey)
          )
        else if (category === "Description")
          return (
            element.COMMENTS.toLowerCase().includes(query.toLowerCase()) &&
            element.GROUP_ID.toString().includes(this.state.groupKey)
          )
        else if (category === "Any")
          return (
            (element.CONFIG_KEY.toLowerCase().includes(query.toLowerCase()) ||
              element.SCOPE_NAME.toLowerCase().includes(query.toLowerCase()) ||
              element.SCOPE_TYPE.toLowerCase().includes(query.toLowerCase()) ||
              element.VALUE.toLowerCase().includes(query.toLowerCase()) ||
              element.COMMENTS.toLowerCase().includes(query.toLowerCase())) &&
            element.GROUP_ID.toString().includes(this.state.groupKey)
          )
        else
          return (
            element.CONFIG_KEY.toLowerCase().includes(
              this.props.query.toLowerCase()
            ) && element.GROUP_ID.toString().includes(this.state.groupKey)
          )
      })

    if (this.state.COLUMN_SORT === "Config Key") {
      if (this.state.desc)
        filteredData.sort(function (a, b) {
          return a.CONFIG_KEY.toLowerCase() > b.CONFIG_KEY.toLowerCase()
            ? 1
            : b.CONFIG_KEY.toLowerCase() > a.CONFIG_KEY.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function (a, b) {
          return a.CONFIG_KEY.toLowerCase() > b.CONFIG_KEY.toLowerCase()
            ? -1
            : b.CONFIG_KEY.toLowerCase() > a.CONFIG_KEY.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === "Scope Name") {
      if (this.state.desc)
        filteredData.sort(function (a, b) {
          return a.SCOPE_NAME.toLowerCase() > b.SCOPE_NAME.toLowerCase()
            ? 1
            : b.SCOPE_NAME.toLowerCase() > a.SCOPE_NAME.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function (a, b) {
          return a.SCOPE_NAME.toLowerCase() > b.SCOPE_NAME.toLowerCase()
            ? -1
            : b.SCOPE_NAME.toLowerCase() > a.SCOPE_NAME.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === "Scope Type") {
      if (this.state.desc)
        filteredData.sort(function (a, b) {
          return a.SCOPE_TYPE.toLowerCase() > b.SCOPE_TYPE.toLowerCase()
            ? 1
            : b.SCOPE_TYPE.toLowerCase() > a.SCOPE_TYPE.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function (a, b) {
          return a.SCOPE_TYPE.toLowerCase() > b.SCOPE_TYPE.toLowerCase()
            ? -1
            : b.SCOPE_TYPE.toLowerCase() > a.SCOPE_TYPE.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === "Value") {
      if (this.state.desc)
        filteredData.sort(function (a, b) {
          return a.VALUE.toLowerCase() > b.VALUE.toLowerCase()
            ? 1
            : b.VALUE.toLowerCase() > a.VALUE.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function (a, b) {
          return a.VALUE.toLowerCase() > b.VALUE.toLowerCase()
            ? -1
            : b.VALUE.toLowerCase() > a.VALUE.toLowerCase()
            ? 1
            : 0
        })
    }
    if (this.state.COLUMN_SORT === "Description") {
      if (this.state.desc)
        filteredData.sort(function (a, b) {
          return a.COMMENTS.toLowerCase() > b.COMMENTS.toLowerCase()
            ? 1
            : b.COMMENTS.toLowerCase() > a.COMMENTS.toLowerCase()
            ? -1
            : 0
        })
      else
        filteredData.sort(function (a, b) {
          return a.COMMENTS.toLowerCase() > b.COMMENTS.toLowerCase()
            ? -1
            : b.COMMENTS.toLowerCase() > a.COMMENTS.toLowerCase()
            ? 1
            : 0
        })
    }
    return filteredData
  }

  handleGroupChange = (event) => {
    this.props.fetchConfigValues()
    const key = event.toString()
    this.setState({
      groupKey: key,
    })
  }

  handleRowClick = (data) => {
    // Second Click
    if (JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG)) {
      this.props.loadConfig({ editConfig: this.state.EDIT_CONFIG })
      this.props.manageModal({ currentModal: "editConfigMenuShow" })
      // First Click
    } else {
      this.setState({
        EDIT_CONFIG: data,
      })
    }
  }

  handleHeaderClick = (column) => {
    if (column === this.state.COLUMN_SORT) {
      this.setState({
        desc: !this.state.desc,
      })
    } else {
      this.setState({
        COLUMN_SORT: column,
        desc: true,
      })
    }
  }

  handleRowSelects = (data) => {
    return JSON.stringify(data) === JSON.stringify(this.state.EDIT_CONFIG)
      ? { background: "#C8E3EF" }
      : null
  }

  handleColumnSelects = (data) => {
    let style = null
    if (data === this.state.COLUMN_SORT) {
      if (this.state.desc) style = { ...style, borderTop: "2px solid #3F4766" }
      else style = { ...style, borderBottom: "2px solid #3F4766" }
    }
    return style
  }

  getBaggageSummary = (bags) => {
    let countFreeAllowance = 0
    let countLoyaltyAllowance = 0
    let countXbagAllowance = 0
    let paidBagCount = 0
    let checkedBagCount = 0
    let printedBagCount = 0
    const baggageSummary = []
    bags.map((bag) => {
      bag.baggage.map((data) => {
        if (data.baggageType === "REGULAR") {
          paidBagCount += data.paidOnlyCount
          checkedBagCount += data.checkedCount
          printedBagCount += data.printedCount
        } else if (data.baggageType === "CARRY_ON") {
          // TODO
        }
      })
      bag.allowance.map((allowed) => {
        if (allowed.baggageType === "REGULAR") {
          countFreeAllowance += allowed.freeAllowance
          countLoyaltyAllowance += allowed.loyaltyAllowance
          countXbagAllowance += allowed.xbagAllowance
        } else if (allowed.baggageType === "CARRY_ON") {
          // TODO
        }
      })
    })
    const countAllowance =
      countFreeAllowance + countLoyaltyAllowance + countXbagAllowance
    baggageSummary.push("BAG-REG: " + countAllowance + " Allowances")
    if (countFreeAllowance > 0)
      baggageSummary.push(countFreeAllowance + " FREE")
    if (countLoyaltyAllowance > 0)
      baggageSummary.push(countLoyaltyAllowance + " LOYALTY")
    if (paidBagCount > 0) baggageSummary.push(paidBagCount + " PAID")
    if (checkedBagCount > 0) baggageSummary.push(checkedBagCount + " CHECKED")
    if (printedBagCount > 0) baggageSummary.push(printedBagCount + " PRINTED")
    return baggageSummary.join(", ")
  }

  displayPNRInfo = (value) => {
    let pnrInfo = []
    if (validateJson(value) && value[0] === "{") {
      const info = JSON.parse(value)
      // pnr JSON object
      if (isNotEmpty(info.recordLocator)) {
        const paxCount = info.passengers.length
        const flights = info.flights.flightLegs[0]
        const d_airport = flights.departureSchedule.schedule.airport.code
        const a_airport = flights.arrivalSchedule.schedule.airport.code
        const carrierCode = flights.opCarrierCode
        const bags = this.getBaggageSummary(info.bags)
        pnrInfo.push(d_airport + "→" + a_airport)
        pnrInfo.push(paxCount + " PAX")
        pnrInfo.push(carrierCode)
        pnrInfo.push(bags)
      } else {
        pnrInfo.push(JSON.stringify(info))
      }
    } else {
      pnrInfo.push(value)
    }
    return pnrInfo.join(", ")
  }

  resetUpdatedData = () => {
    this.showAlert = false
    this.props.updateResponses("UPDATED_DATA", null)
  }

  truncateValue = (value, size = 40) => {
    let truncate = value
    if (value.length > size) {
      value = truncate.substring(0, size) + "..."
    }
    return value
  }

  render() {
    const configKey = this.props.updatedData
      ? this.props.updatedData.CONFIG_KEY
      : ""
    return (
      <div className="Table">
        <div className="fixed-top mt-5">
          <Alert
            show={this.showAlert}
            onClose={this.resetUpdatedData}
            variant={this.alertColors[this.index]}
            dismissible
          >
            <span onClick={() => this.handleRowClick(this.props.updatedData)}>
              Config Key: <b>{configKey}</b> (Please click here to see the
              details)
            </span>
          </Alert>
        </div>
        <Tabs
          id="configGroups"
          activeKey={this.state.groupKey}
          onSelect={(k) => this.handleGroupChange(k)}
        >
          {this.props.groups.map((group) => {
            return (
              <Tab
                key={this.props.database + group.GROUP_ID}
                eventKey={group.GROUP_ID}
                title={group.GROUP_NAME}
              ></Tab>
            )
          })}
        </Tabs>
        {/* this.state.groupKey === '6' */}
        {this.props.groups.some(
          (e) =>
            e.GROUP_ID.toString() === this.state.groupKey &&
            e.GROUP_NAME.includes("Simulation")
        ) &&
          window.config.enablePNRGenerator && (
            <Jumbotron fluid>
              <p className="asterisk">
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.props.manageModal({
                      currentModal: "pnrMenuShow",
                      simulatorGroup: this.state.groupKey,
                    })
                  }
                >
                  PNR Builder
                </Button>{" "}
                {/* <Button variant="secondary" onClick={() => this.props.manageModal({currentModal: "generateBpShow", simulatorGroup: this.state.groupKey})}>Generate Boarding Pass</Button>{' '} */}
              </p>
            </Jumbotron>
          )}
        <Table responsive striped bordered hover>
          <thead>
            <tr className="d-flex">
              <th
                className="col-sm-2"
                style={this.handleColumnSelects("Config Key")}
                onClick={() => this.handleHeaderClick("Config Key")}
              >
                <FontAwesomeIcon icon="lock" /> Config Key
              </th>
              <th
                className="col-sm-1"
                style={this.handleColumnSelects("Scope Name")}
                onClick={() => this.handleHeaderClick("Scope Name")}
              >
                <FontAwesomeIcon icon="lock" /> Scope Name
              </th>
              <th
                className="col-sm-1"
                style={this.handleColumnSelects("Scope Type")}
                onClick={() => this.handleHeaderClick("Scope Type")}
              >
                {" "}
                <FontAwesomeIcon icon="lock" /> Scope Type
              </th>
              <th
                className="col-sm-4"
                style={this.handleColumnSelects("Value")}
                onClick={() => this.handleHeaderClick("Value")}
              >
                Value
              </th>
              <th
                className="col-sm-4"
                style={this.handleColumnSelects("Description")}
                onClick={() => this.handleHeaderClick("Description")}
              >
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {this.filteredValues.map((configuration, index) => {
              if (
                typeof configuration.PREVIOUS_VALUE === "object" &&
                configuration.PREVIOUS_VALUE !== null
              ) {
                console.log("configuration: ", configuration)
              }
              return (
                <tr
                  className="d-flex"
                  style={this.handleRowSelects(configuration)}
                  key={
                    this.props.database +
                    configuration.CONFIG_KEY +
                    configuration.SCOPE_NAME +
                    index
                  }
                  onClick={() => this.handleRowClick(configuration)}
                >
                  <td className="col-sm-2"> {configuration.CONFIG_KEY} </td>
                  <td className="col-sm-1"> {configuration.SCOPE_NAME} </td>
                  <td className="col-sm-1"> {configuration.SCOPE_TYPE} </td>
                  <td className="col-sm-4">
                    {" "}
                    {this.truncateValue(
                      this.displayPNRInfo(configuration.VALUE),
                      60
                    )}{" "}
                  </td>
                  <td className="col-sm-4">
                    {" "}
                    {this.truncateValue(configuration.COMMENTS)}{" "}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    configs: state.loadData.configs,
    groups: state.loadData.groups,
    query: state.filterData.query,
    category: state.filterData.filterColumn,
    accessKeys: state.loadData.accessKeys,
    PROFILE_ID: state.accountLogin.PROFILE_ID,
    database: state.accountLogin.database,
    updatedData: state.updateResponses.updatedData,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { manageModal, loadConfig, updateResponses },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigTable)
