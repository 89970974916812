import { POST_REQUEST_RESPONSE, CLOSE_NOTIF } from '../constants/action-types'
import authentication from '../../services/authentication'
import updateResponses from 'redux/actions/responseAction'
import { fetchConfigValues } from 'redux/actions/getData'

const serverURL = window.config.serverURL

export function postConfigValue(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getConfigs/postConfigValue', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch(updateResponses('UPDATED_DATA', payload))
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Configuration Value',
          message: `Successfully added configuration: ${payload.CONFIG_KEY}`
        })
        fetchConfigValues()
      })
  }
}

export function deleteConfig(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getConfigs/deleteConfig', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch(updateResponses('UPDATED_DATA', null))
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete Configuration Value',
          message: `Successfully deleted configuration: ${payload.CONFIG_KEY}`
        })
      })
  }
}

export function saveConfig(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getConfigs/saveConfig', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch(updateResponses('UPDATED_DATA', payload))
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save Configuration Value',
          message: `Successfully updated configuration: ${payload.CONFIG_KEY}-${payload.SCOPE_NAME}-${payload.GROUP_ID}`
        })
      })
  }
}

export function importFromFile(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getConfigs/importFromFile', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Configuration Value',
          message: `Successfully imported configurations from file: ${payload.fileName}`
        })
      })
  }
}

export function postScope(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getScopes/postScope', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Scope',
          message: `Successfully added scope: ${payload.SCOPE_NAME}`
        })
      })
  }
}

export function deleteScope(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getScopes/deleteScope', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete Scope',
          message: `Successfully deleted scope: ${payload.SCOPE_NAME}`
        })
      })
  }
}

export function postGroup(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getGroups/postGroup', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Group',
          message: `Successfully added group: ${payload.GROUP_NAME}`
        })
      })
  }
}

export function deleteGroup(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getGroups/deleteGroup', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        let message = `Successfully deleted group: ${payload.GROUP_NAME}`
        if (payload.GROUP_NAME === 'DefaultGroup') {
          message = `Successfully cleared configuration values from ${payload.GROUP_NAME}`
        }
        dispatch({ type: POST_REQUEST_RESPONSE, payload: data, request: 'Delete Group', message: message })
      })
  }
}

export function addUser(payload) {
  const { token } = authentication()
  console.log('Adding User...')
  const bearer = 'Bearer ' + token
  return function(dispatch) {
    return fetch(serverURL + '/getUsers/addUser', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post New User',
          message: `Successfully added new user: ${payload.USER_ID}`
        })
      })
  }
}

export function deleteUser(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('Deleting User...')
  return function(dispatch) {
    return fetch(serverURL + '/getUsers/deleteUser', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete User',
          message: `Successfully deleted user: ${payload.USER_ID}`
        })
      })
  }
}

export function saveUser(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('Saving User...')
  return function(dispatch) {
    return fetch(serverURL + '/getUsers/saveUser', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save User',
          message: `Successfully updated user credentials: ${payload.USER_ID}`
        })
      })
  }
}

export function saveAccessKeys(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getProfiles/saveAccessKeys', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save Access Keys',
          message: `Successfully saved access keys for profile: ${payload[0].PROFILE_NAME}`
        })
      })
  }
}

export function addProfileName(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getProfiles/addProfileName', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Profile',
          message: `Successfully added profile: ${payload.PROFILE_NAME}`
        })
      })
  }
}

export function deleteProfile(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getProfiles/deleteProfile', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete Profile',
          message: `Successfully deleted profile: ${payload.PROFILE_NAME}`
        })
      })
  }
}

export function deleteStateDef(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getStateDef/deleteState', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete State Definition',
          message: `Successfully deleted state definition: ${payload.STATE_ID}`
        })
      })
  }
}

export function saveStateDef(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getStateDef/saveState', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save State Definition',
          message: `Successfully updated state definition: ${payload.STATE_ID}`
        })
      })
  }
}

export function postStateDef(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getStateDef/postState', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post State Definition',
          message: `Successfully added state definition: ${payload.STATE_ID}`
        })
      })
  }
}

export function deleteStateDesc(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getStateDesc/deleteState', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete State Description',
          message: `Successfully deleted state description for: ${payload.STATE_ID} in ${payload.LANGUAGE_CODE}`
        })
      })
  }
}

export function saveStateDesc(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getStateDesc/saveState', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save State Description',
          message: `Successfully updated state description for: ${payload.STATE_ID}`
        })
      })
  }
}

export function postStateDesc(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getStateDesc/postState', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post State Description',
          message: `Successfully added state description for: ${payload.STATE_ID}`
        })
      })
  }
}

export function postProperty(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getProperties/postProperty', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Property',
          message: `Successfully added property: ${payload.SECTION_NAME}-${payload.PROPERTY_NAME}`
        })
      })
  }
}

export function saveProperty(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getProperties/saveProperty', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save Property',
          message: `Successfully updated property: ${payload.SECTION_NAME}-${payload.PROPERTY_NAME}`
        })
      })
  }
}

export function deleteProperty(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getProperties/deleteProperty', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete Property',
          message: `Successfully deleted property: ${payload.SECTION_NAME}-${payload.PROPERTY_NAME}`
        })
      })
  }
}

export function postCountry(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/postCountry', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Country',
          message: `Successfully added country: ${payload.COUNTRY_CODE2}/${payload.COUNTRY_CODE3}`
        })
      })
  }
}

export function saveCountry(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/saveCountry', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save Country',
          message: `Successfully updated country: ${payload.COUNTRY_NAME}`
        })
      })
  }
}

export function deleteCountry(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/deleteCountry', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete Country',
          message: `Successfully deleted country: ${payload.COUNTRY_CODE2}/${payload.COUNTRY_CODE3}`
        })
      })
  }
}

export function saveCountryIndex(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/saveCountryIndex', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save Country Index',
          message: `Successfully updated country index: ${payload.COUNTRY_INDEX} for ${payload.COUNTRY_CODE2}`
        })
      })
  }
}

export function deleteCountryIndex(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/deleteCountryIndex', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete Country Index',
          message: `Successfully deleted country index: ${payload.COUNTRY_INDEX} for ${payload.COUNTRY_CODE2}`
        })
      })
  }
}

export function postCity(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/postCity', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post City',
          message: `Successfully added city: ${payload.CITY_CODE}`
        })
      })
  }
}

export function saveCity(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/saveCity', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Save City',
          message: `Successfully updated city: ${payload.CITY_NAME}`
        })
      })
  }
}

export function deleteCity(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/getRegion/deleteCity', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Delete City',
          message: `Successfully deleted city: ${payload.CITY_NAME}`
        })
      })
  }
}

export function postBagtagSimulator(payload) {
  const { token } = authentication()
  const bearer = 'Bearer ' + token
  console.log('PAYLOAD', payload)
  return function(dispatch) {
    return fetch(serverURL + '/simulator/postBagtags', {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: POST_REQUEST_RESPONSE,
          payload: data,
          request: 'Post Bagtags',
          message: `Successfully added bagtags: ${payload.recordLocator}`
        })
      })
  }
}

export function closeNotification(payload) {
  return { type: CLOSE_NOTIF, payload: payload }
}
