import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { addUser, deleteUser, saveUser } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class UserMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      USER_ID: '',
      FULL_NAME: '',
      USER_PWD: '',
      USER_ADMIN: false,
      PROFILE_ID: '',
      PROFILE_NAME: '',
      userExists: null
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleUserId = event => {
    let userId = event.target.textContent
    if (userId === '') {
      userId = event.target.value
    }

    let userExists = this.props.users.find(user => userId === user.USER_ID)
    console.log(userExists)
    if (!!userExists) {
      let profileName = ''
      this.props.profiles.map(profile => {
        if (profile.PROFILE_ID === userExists.PROFILE_ID) return (profileName = profile.PROFILE_NAME)
        else return null
      })
      let isAdmin = userExists.USER_ADMIN === 'true'

      this.setState({
        FULL_NAME: userExists.FULL_NAME,
        USER_ADMIN: isAdmin,
        PROFILE_ID: userExists.PROFILE_ID,
        PROFILE_NAME: profileName
      })
    }
    this.setState({
      USER_ID: userId,
      USER_PWD: '',
      userExists: userExists
    })
  }

  handleNewPass = event => {
    const newPass = event.target.value
    this.setState({
      USER_PWD: newPass
    })
  }

  handleFullName = event => {
    const fullName = event.target.value
    console.log(fullName)
    this.setState({
      FULL_NAME: fullName
    })
  }

  handleAdminUser = () => {
    if (!this.state.USER_ADMIN) {
      let profileId = ''
      this.props.profiles.map(profile => {
        if (profile.PROFILE_NAME === 'Admin') return (profileId = profile.PROFILE_ID)
        else return null
      })
      this.setState({
        PROFILE_NAME: 'Admin',
        PROFILE_ID: profileId
      })
    }
    this.setState({
      USER_ADMIN: !this.state.USER_ADMIN
    })
  }

  handleProfile = event => {
    const profileName = event.target.value
    let profileId = ''
    this.props.profiles.map(profile => {
      if (profile.PROFILE_NAME === profileName) return (profileId = profile.PROFILE_ID)
      else return null
    })
    console.log(profileId)
    this.setState({
      PROFILE_NAME: profileName,
      PROFILE_ID: profileId
    })
  }

  saveUser = () => {
    const admin = this.state.USER_ADMIN.toString()
    const req = { ...this.state, USER_ADMIN: admin }
    console.log(req)
    this.props.saveUser(req)
    console.log('USER SAVED!')
    this.resetForm()
    this.props.closeModal()
  }

  addUser = () => {
    const admin = this.state.USER_ADMIN.toString()
    const req = { ...this.state, USER_ADMIN: admin }
    console.log(req)
    this.props.addUser(req)
    console.log('USER ADDED!')
    this.resetForm()
    this.props.closeModal()
  }

  deleteUser = () => {
    const admin = this.state.USER_ADMIN.toString()
    const req = { ...this.state, USER_ADMIN: admin }
    console.log(req)
    this.props.deleteUser(req)
    console.log('USER DELETED!')
    this.resetForm()
    this.props.closeModal()
  }

  render() {
    let profile = (
      <Form.Group as={Row} controlId="formPlaintextProfile">
        <Form.Label column sm="3">
          {' '}
          Profile <p className="asterisk"> * </p>:{' '}
        </Form.Label>
        <Col sm="9">
          <Form.Control as="select" value={this.state.PROFILE_NAME} onChange={this.handleProfile}>
            <option></option>
            {this.props.profiles.map(profile => {
              return <option key={this.props.database + profile.PROFILE_ID}>{profile.PROFILE_NAME}</option>
            })}
          </Form.Control>
        </Col>
      </Form.Group>
    )
    let isAdminUser = (
      <Form.Group as={Row} controlId="formBasicCheckbox">
        <Form.Label column sm="3">
          Admin User:
        </Form.Label>
        <Col sm="9">
          <Form.Check
            custom
            type="checkbox"
            id="custom-checkbox1"
            label=""
            style={{ margin: '7px 0 0 0' }}
            checked={this.state.USER_ADMIN}
            onClick={this.handleAdminUser}
          />
        </Col>
      </Form.Group>
    )
    if (!!this.state.userExists) {
      if (this.state.USER_ID === 'admin') {
        isAdminUser = (
          <Form.Group as={Row} controlId="formBasicCheckbox">
            <Form.Label column sm="3">
              Admin User:
            </Form.Label>
            <Col sm="9">
              <Form.Check
                custom
                type="checkbox"
                id="custom-checkbox1"
                label=""
                style={{ margin: '7px 0 0 0' }}
                checked={this.state.USER_ADMIN}
                disabled
              />
            </Col>
          </Form.Group>
        )
        profile = (
          <Form.Group as={Row} controlId="formPlaintextProfile">
            <Form.Label column sm="3">
              {' '}
              Profile<p className="asterisk"> * </p>:{' '}
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select" value={this.state.PROFILE_NAME} disabled>
                <option></option>
                {this.props.profiles.map(profile => {
                  return <option key={this.props.database + profile.PROFILE_ID}>{profile.PROFILE_NAME}</option>
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        )
      }
      if (this.state.USER_ADMIN) {
        profile = (
          <Form.Group as={Row} controlId="formPlaintextProfile">
            <Form.Label column sm="3">
              {' '}
              Profile<p className="asterisk"> * </p>:{' '}
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select" value={this.state.PROFILE_NAME} disabled>
                <option></option>
                {this.props.profiles.map(profile => {
                  return <option key={this.props.database + profile.PROFILE_ID}>{profile.PROFILE_NAME}</option>
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        )
      }
    } else {
      if (this.state.USER_ADMIN) {
        profile = (
          <Form.Group as={Row} controlId="formPlaintextProfile">
            <Form.Label column sm="3">
              {' '}
              Profile<p className="asterisk"> * </p>:{' '}
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select" value={this.state.PROFILE_NAME} disabled>
                <option></option>
                {this.props.profiles.map(profile => {
                  return <option key={this.props.database + profile.PROFILE_ID}>{profile.PROFILE_NAME}</option>
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        )
      }
    }
    const isAdd =
      !this.state.userExists &&
      this.state.USER_ID.length > 0 &&
      this.state.FULL_NAME.length > 0 &&
      this.state.PROFILE_ID.toString().length > 0 &&
      this.state.USER_PWD.length > 0
    const isSave =
      !!this.state.userExists && this.state.FULL_NAME.length > 0 && this.state.PROFILE_ID.toString().length > 0
    const isDelete = !!this.state.userExists
    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextUserId">
              <Form.Label column sm="3">
                User Id<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="9">
                <InputGroup>
                  <Form.Control
                    aria-label="User Id"
                    aria-describedby="basic-addon2"
                    value={this.state.USER_ID}
                    onChange={this.handleUserId}
                  />

                  <DropdownButton
                    as={InputGroup.Append}
                    title=""
                    variant="outline-secondary"
                    id="input-group-dropdown-2"
                  >
                    {this.props.users.map((user, index) => {
                      return (
                        <Dropdown.Item key={this.props.database + index} onClick={this.handleUserId}>
                          {user.USER_ID}
                        </Dropdown.Item>
                      )
                    })}
                  </DropdownButton>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicPassword">
              <Form.Label column sm="3">
                New Password<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="9">
                <Form.Control type="password" onChange={this.handleNewPass} value={this.state.USER_PWD} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextFullName">
              <Form.Label column sm="3">
                Full Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="9">
                <Form.Control type="FULL_NAME" value={this.state.FULL_NAME} onChange={this.handleFullName} />
              </Col>
            </Form.Group>

            {isAdminUser}

            {profile}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={this.saveUser} disabled={!isSave}>
            Save
          </Button>
          <Button variant="danger" onClick={this.deleteUser} disabled={!isDelete}>
            Delete
          </Button>
          <Button variant="success" onClick={this.addUser} disabled={!isAdd}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    users: state.loadData.users,
    profiles: state.loadData.profiles,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addUser, deleteUser, saveUser, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenu)
