import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { postCountry, saveCountry, deleteCountry } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { by639_1 } from 'iso-language-codes'

class CountryMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      COUNTRY_CODE3: '',
      COUNTRY_NAME: '',
      COUNTRY_CODE2: '',
      COUNTRY_NUMBER: '',
      LANGUAGE_CODE: '',
      COUNTRY_INDEX: '',
      countryCodeExists: false,
      countryIndexList: [],
      countryNameList: [],
      error: null
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState({ ...this.baseState, countryIndexList: [], countryNameList: [] })
  }

  handleCountryCode2 = event => {
    let value = this.state.COUNTRY_CODE2
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase().substr(0, 2)
      console.log(value)
      let countryList = this.props.countries.filter(e => {
        return e.COUNTRY_CODE2 === value
      })
      let indexList = this.props.index
        .filter(e => {
          return e.COUNTRY_CODE2 === value
        })
        .map(e => {
          return e.COUNTRY_INDEX
        })
      if (countryList.length > 0) {
        this.setState({
          ...countryList.shift(),
          countryCodeExists: true,
          countryNameList: countryList,
          COUNTRY_INDEX: indexList.shift(),
          countryIndexList: indexList
        })
      } else {
        let baseState = this.state
        if (this.state.countryCodeExists) {
          baseState = this.baseState
        }
        this.setState({
          ...baseState,
          COUNTRY_CODE2: value,
          countryCodeExists: false
        })
      }
    }
  }

  handleCountryCode3 = event => {
    let value = this.state.COUNTRY_CODE3
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      value = value.toUpperCase().substr(0, 3)
      console.log(value)

      let countryList = this.props.countries.filter(e => {
        return e.COUNTRY_CODE3 === value
      })
      if (countryList.length > 0) {
        let indexList = this.props.index
          .filter(e => {
            return e.COUNTRY_CODE2 === countryList[0].COUNTRY_CODE2
          })
          .map(e => {
            return e.COUNTRY_INDEX
          })
        this.setState({
          ...countryList.shift(),
          countryCodeExists: true,
          countryNameList: countryList,
          COUNTRY_INDEX: indexList.shift(),
          countryIndexList: indexList
        })
      } else {
        this.setState({
          COUNTRY_CODE3: value
        })
      }
    }
  }

  handleCountryName = event => {
    let value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_NAME: value
    })
  }

  handleCountryIndex = event => {
    let value = event.target.value.toUpperCase().substr(0, 3)
    console.log(value)
    this.setState({
      COUNTRY_INDEX: value,
      error: null
    })
  }

  handleCountryNumber = event => {
    const re = /^[0-9\b]+$/
    let value = this.state.COUNTRY_NUMBER
    if (event.target.value === '' || re.test(event.target.value)) value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_NUMBER: value
    })
  }

  handleLangCode = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      LANGUAGE_CODE: value.toUpperCase(),
      error: null
    })
  }

  handleCountryNameRow = data => {
    console.log(data)
    let countryNameList = this.state.countryNameList
    if (data === '+') {
      countryNameList.push({ COUNTRY_NAME: '', LANGUAGE_CODE: '' })
    } else {
      countryNameList.splice(data, 1)
    }
    console.log(countryNameList)
    this.setState({
      countryNameList: countryNameList
    })
  }

  handleCountryNameList = (event, i) => {
    console.log(event.target.value, i)
    let countryNameList = this.state.countryNameList
    let editCountryName = { COUNTRY_NAME: event.target.value, LANGUAGE_CODE: countryNameList[i].LANGUAGE_CODE }
    countryNameList[i] = editCountryName
    this.setState({
      countryNameList: countryNameList
    })
  }

  handleCountryLangList = (event, i) => {
    console.log(event.target.value, i)
    let countryNameList = this.state.countryNameList
    let editCountryName = { COUNTRY_NAME: countryNameList[i].COUNTRY_NAME, LANGUAGE_CODE: event.target.value }
    countryNameList[i] = editCountryName
    this.setState({
      countryNameList: countryNameList,
      error: null
    })
  }

  handleCountryIndexRow = data => {
    console.log(data)
    let countryIndexList = this.state.countryIndexList
    if (data === '+') {
      countryIndexList.push('')
    } else {
      countryIndexList.splice(data, 1)
    }
    console.log(countryIndexList)
    this.setState({
      countryIndexList: countryIndexList
    })
  }

  handleCountryIndexList = (event, i) => {
    const value = event.target.value.toUpperCase().substr(0, 3)
    console.log(value, i)
    let countryIndexList = this.state.countryIndexList
    countryIndexList[i] = value
    this.setState({
      countryIndexList: countryIndexList,
      error: null
    })
  }

  getUniqueList = category => {
    let list = []
    if (category === 'COUNTRY_CODE2') {
      list = this.props.countries.map(elem => {
        return elem.COUNTRY_CODE2
      })
    } else if (category === 'COUNTRY_CODE3') {
      list = this.props.countries.map(elem => {
        return elem.COUNTRY_CODE3
      })
    } else if (category === 'LANGUAGE_CODE') {
      list = Object.keys(by639_1).map(elem => {
        return elem.toUpperCase()
      })
    }

    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]
    return listUnique
  }
  hasDuplicates = () => {
    const langArray = [
      this.state.LANGUAGE_CODE,
      ...this.state.countryNameList.map(e => {
        return e.LANGUAGE_CODE
      })
    ]
    const indexArray = [this.state.COUNTRY_INDEX, ...this.state.countryIndexList]

    if (new Set(langArray).size !== langArray.length) {
      this.setState({
        error: 'Duplicate language codes are not allowed'
      })
      return true
    } else if (new Set(indexArray).size !== indexArray.length) {
      this.setState({
        error: 'Duplicate country indexes are not allowed'
      })
      return true
    }
    return false
  }

  saveCountry = () => {
    if (!this.hasDuplicates()) {
      const req = { ...this.state }
      console.log(req)
      this.props.saveCountry(req)
      this.resetForm()
      this.props.closeModal()
    }
  }

  addCountry = () => {
    if (!this.hasDuplicates()) {
      const req = { ...this.state }
      console.log(req)
      this.props.postCountry(req)
      this.resetForm()
      this.props.closeModal()
    }
  }

  deleteCountry = () => {
    let cityList = this.props.cities.filter(e => {
      return e.COUNTRY_CODE2 === this.state.COUNTRY_CODE2
    })
    const req = { ...this.state, cityList: cityList }
    console.log(req)
    this.props.deleteCountry(req)
    this.resetForm()
    this.props.closeModal()
  }

  render() {
    const isAdd =
      this.state.COUNTRY_CODE2.length > 1 &&
      this.state.COUNTRY_CODE3.length > 2 &&
      this.state.COUNTRY_NAME.length > 0 &&
      this.state.LANGUAGE_CODE.length > 0 &&
      !this.state.countryCodeExists &&
      !this.state.countryNameList.some(e => e.COUNTRY_NAME.length < 1 || e.LANGUAGE_CODE.length < 1)
    const isDelete = this.state.countryCodeExists
    const isSave =
      this.state.COUNTRY_CODE3.length > 2 &&
      this.state.COUNTRY_NAME.length > 0 &&
      this.state.LANGUAGE_CODE.length > 0 &&
      this.state.countryCodeExists &&
      !this.state.countryNameList.some(e => e.COUNTRY_NAME.length < 1 || e.LANGUAGE_CODE.length < 1)

    let footer = (
      <Modal.Footer>
        <Button variant="dark" onClick={this.saveCountry} disabled={!isSave}>
          Save
        </Button>
        <Button variant="danger" onClick={this.deleteCountry} disabled={!isDelete}>
          Delete
        </Button>
        <Button variant="success" onClick={this.addCountry} disabled={!isAdd}>
          Add
        </Button>
      </Modal.Footer>
    )
    if (!!this.state.error) {
      footer = (
        <Modal.Footer>
          <p className="error">{this.state.error}</p>
          <Button variant="dark" onClick={this.saveCountry} disabled={!isSave}>
            Save
          </Button>
          <Button variant="danger" onClick={this.deleteCountry} disabled={!isDelete}>
            Delete
          </Button>
          <Button variant="success" onClick={this.addCountry} disabled={!isAdd}>
            Add
          </Button>
        </Modal.Footer>
      )
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Country Code <p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="4">
                <Typeahead
                  placeholder="2 Character"
                  options={this.getUniqueList('COUNTRY_CODE2')}
                  type="COUNTRY_CODE2"
                  id="COUNTRY_CODE2"
                  selected={this.state.COUNTRY_CODE2.length > 0 ? [this.state.COUNTRY_CODE2] : []}
                  onChange={this.handleCountryCode2}
                  onInputChange={this.handleCountryCode2}
                  emptyLabel={false}
                  highlightOnlyResult
                />
              </Col>
              <h3>/</h3>
              <Col sm="4">
                <Typeahead
                  placeholder="3 Character"
                  options={this.getUniqueList('COUNTRY_CODE3')}
                  type="COUNTRY_CODE3"
                  id="COUNTRY_CODE3"
                  selected={this.state.COUNTRY_CODE3.length > 0 ? [this.state.COUNTRY_CODE3] : []}
                  onChange={this.handleCountryCode3}
                  onInputChange={this.handleCountryCode3}
                  emptyLabel={false}
                  highlightOnlyResult
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Country Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="5">
                <Form.Control type="COUNTRY_NAME" value={this.state.COUNTRY_NAME} onChange={this.handleCountryName} />
              </Col>
              <Col sm="3">
                <Form.Control as="select" onChange={this.handleLangCode} value={this.state.LANGUAGE_CODE}>
                  {!this.state.countryCodeExists && (
                    <option key="defaultValueLangCodeCountry" value="">
                      Language Code
                    </option>
                  )}
                  {this.state.countryCodeExists && (
                    <option key="valueLangCodeCountry" value="">
                      {this.state.LANGUAGE_CODE}
                    </option>
                  )}
                  {this.getUniqueList('LANGUAGE_CODE').map(e => {
                    return (
                      <option key={this.props.database + e} value={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
              <Col sm="1">
                <Button
                  className="plusRow"
                  variant="outline-primary"
                  size="sm"
                  onClick={() => this.handleCountryNameRow('+')}
                >
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </Col>
            </Form.Group>

            {this.state.countryNameList.map((elem, i) => {
              return (
                <Form.Group as={Row} controlId="formPlaintextScope">
                  <Form.Label column sm="3"></Form.Label>
                  <Col sm="5">
                    <Form.Control
                      type="COUNTRY_NAME"
                      value={elem.COUNTRY_NAME}
                      onChange={e => this.handleCountryNameList(e, i)}
                    />
                  </Col>
                  <Col sm="3">
                    <Form.Control
                      as="select"
                      value={elem.LANGUAGE_CODE}
                      onChange={e => this.handleCountryLangList(e, i)}
                    >
                      <option key="defaultValueLangCodeCountry" value="">
                        Language Code
                      </option>
                      {this.getUniqueList('LANGUAGE_CODE').map(e => {
                        return (
                          <option key={this.props.database + e} value={e}>
                            {e}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Col>
                  <Col sm="1">
                    <Button
                      className="minusRow"
                      variant="outline-danger"
                      size="sm"
                      onClick={() => this.handleCountryNameRow(i)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                  </Col>
                </Form.Group>
              )
            })}

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Country Number:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="COUNTRY_NUMBER"
                  value={this.state.COUNTRY_NUMBER}
                  onChange={this.handleCountryNumber}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="3">
                Country Index:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="COUNTRY_INDEX"
                  value={this.state.COUNTRY_INDEX}
                  onChange={this.handleCountryIndex}
                />
              </Col>
              <Col sm="1">
                <Button
                  className="plusRow"
                  variant="outline-primary"
                  size="sm"
                  onClick={() => this.handleCountryIndexRow('+')}
                >
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </Col>
            </Form.Group>

            {this.state.countryIndexList.map((countryIndex, i) => {
              return (
                <Form.Group as={Row} controlId="formPlaintextScope">
                  <Form.Label column sm="3"></Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="COUNTRY_INDEX"
                      value={countryIndex}
                      onChange={e => this.handleCountryIndexList(e, i)}
                    />
                  </Col>
                  <Col sm="1">
                    <Button
                      className="minusRow"
                      variant="outline-danger"
                      size="sm"
                      onClick={() => this.handleCountryIndexRow(i)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                  </Col>
                </Form.Group>
              )
            })}
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    cities: state.loadData.cities,
    countries: state.loadData.countries,
    index: state.loadData.countryIndexes,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postCountry, deleteCountry, saveCountry, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryMenu)
