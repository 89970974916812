import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { saveCity, deleteCity } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'
import { by639_1 } from 'iso-language-codes'

class EditCityMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editCity !== this.props.editCity) {
      this.setState({
        ...nextProps.editCity
      })
    }
  }

  // componentDidUpdate(prevProps){
  //     console.log(this.props.editCity)
  //     if (JSON.stringify(this.props.editCity) !== JSON.stringify(prevProps.editCity)) {
  //         console.log(this.props.editCity)
  //         this.setState({
  //             ...this.props.editCity
  //         })
  //     }
  // }

  handleCityCode = event => {
    let value = this.state.CITY_CODE
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    console.log(value)
    this.setState({
      CITY_CODE: value.toUpperCase()
    })
  }

  handleCityName = event => {
    let value = event.target.value
    console.log(value)
    this.setState({
      CITY_NAME: value
    })
  }

  handleCountryCode2 = event => {
    let value = event.target.value.toUpperCase()
    console.log(value)
    let countryName = this.props.countries.find(
      e => this.state.LANGUAGE_CODE === e.LANGUAGE_CODE && value === e.COUNTRY_CODE2
    )
    if (!countryName) countryName = this.props.countries.find(e => value === e.COUNTRY_CODE2)
    this.setState({
      COUNTRY_CODE2: value,
      COUNTRY_NAME: countryName.COUNTRY_NAME
    })
  }

  handleCountryName = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_NAME: value
    })
  }

  handleCountryNum = event => {
    const re = /^[0-9\b]+$/
    let value = this.state.COUNTRY_NUMBER
    if (event.target.value === '' || re.test(event.target.value)) value = event.target.value
    console.log(value)
    this.setState({
      COUNTRY_NUMBER: value
    })
  }

  handleTimeZone = event => {
    let value = this.state.TIME_ZONE
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        TIME_ZONE: value
      })
    }
  }

  handleDaylightSaving = () => {
    let flag = null
    if (this.state.DAYLIGHT_SAVING === 'N') {
      flag = 'Y'
    } else if (this.state.DAYLIGHT_SAVING === 'Y') {
      flag = 'N'
    }

    console.log('DAYLIGHT SAVING', flag)
    this.setState({
      DAYLIGHT_SAVING: flag
    })
  }

  handleDaylightSavingMin = event => {
    const re = /^[0-9\b]+$/
    let value = this.state.DAYLIGHT_SAVING_MINUTES
    if (event.target.value === '' || re.test(event.target.value)) value = event.target.value
    console.log(value)
    this.setState({
      DAYLIGHT_SAVING_MINUTES: value
    })
  }

  handleLangCode = event => {
    const value = event.target.value.toUpperCase()
    console.log(value)
    let countryName = this.props.countries.find(
      e => value === e.LANGUAGE_CODE && this.state.COUNTRY_CODE2 === e.COUNTRY_CODE2
    )
    if (!countryName) countryName = this.props.countries.find(e => this.state.COUNTRY_CODE2 === e.COUNTRY_CODE2)
    this.setState({
      LANGUAGE_CODE: value,
      COUNTRY_NAME: countryName.COUNTRY_NAME
    })
  }

  configExists = () => {
    for (let config of this.props.properties) {
      if (config.SECTION_NAME === this.state.SECTION_NAME && config.PROPERTY_NAME === this.state.PROPERTY_NAME)
        return true
    }
    return false
  }

  isChanged = () => {
    let prevProp = this.props.editCity
    return (
      (this.state.CITY_CODE !== prevProp.CITY_CODE ||
        this.state.TIME_ZONE !== prevProp.TIME_ZONE ||
        this.state.DAYLIGHT_SAVING !== prevProp.DAYLIGHT_SAVING ||
        this.state.DAYLIGHT_SAVING_MINUTES !== prevProp.DAYLIGHT_SAVING_MINUTES ||
        this.state.LANGUAGE_CODE !== prevProp.LANGUAGE_CODE ||
        this.state.CITY_NAME !== prevProp.CITY_NAME ||
        this.state.COUNTRY_NAME !== prevProp.COUNTRY_NAME ||
        this.state.COUNTRY_CODE3 !== prevProp.COUNTRY_CODE3 ||
        this.state.COUNTRY_CODE2 !== prevProp.COUNTRY_CODE2 ||
        (!!this.state.COUNTRY_NUMBER ? this.state.COUNTRY_NUMBER.toString() : this.state.COUNTRY_NUMBER) !==
          (!!prevProp.COUNTRY_NUMBER ? prevProp.COUNTRY_NUMBER.toString() : prevProp.COUNTRY_NUMBER) ||
        (!!this.state.DAYLIGHT_SAVING_MINUTES
          ? this.state.DAYLIGHT_SAVING_MINUTES.toString()
          : this.state.DAYLIGHT_SAVING_MINUTES) !==
          (!!prevProp.DAYLIGHT_SAVING_MINUTES
            ? prevProp.DAYLIGHT_SAVING_MINUTES.toString()
            : prevProp.DAYLIGHT_SAVING_MINUTES) ||
        this.state.LANGUAGE_CODE !== prevProp.LANGUAGE_CODE) &&
      this.state.CITY_NAME.length > 0 &&
      this.state.COUNTRY_NAME.length > 0 &&
      this.state.COUNTRY_CODE3.length > 0
    )
  }

  getUniqueList = category => {
    let list = []
    if (category === 'CITY_CODE') {
      list = this.props.cities.map(elem => {
        return elem.CITY_CODE
      })
    } else if (category === 'COUNTRY_CODE2') {
      list = this.props.countries.map(elem => {
        return JSON.stringify({ COUNTRY_CODE2: elem.COUNTRY_CODE2, COUNTRY_CODE3: elem.COUNTRY_CODE3 })
      })
    } else if (category === 'COUNTRY_CODE3') {
      list = this.props.countries.map(elem => {
        return elem.COUNTRY_CODE3
      })
    } else if (category === 'TIME_ZONE') {
      list = this.props.cities.map(elem => {
        return elem.TIME_ZONE
      })
    } else if (category === 'LANGUAGE_CODE') {
      list = Object.keys(by639_1).map(elem => {
        return elem.toUpperCase()
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  saveConfig = () => {
    const req = { ...this.state }
    console.log(req)
    this.props.saveCity(req)
    this.resetForm()
    this.props.closeModal()
  }

  deleteConfig = () => {
    const onlyCity =
      this.props.cities.filter(e => {
        return e.CITY_CODE === this.props.editCity.CITY_CODE
      }).length === 1
    const req = { ...this.props.editCity, onlyCity: onlyCity }
    console.log(req)
    this.props.deleteCity(req)
    this.resetForm()
    this.props.closeModal()
  }

  render() {
    let footer = (
      <Modal.Footer>
        <Button variant="danger" onClick={this.deleteConfig}>
          Delete
        </Button>
        <Button variant="success" onClick={this.saveConfig} disabled>
          Save
        </Button>
      </Modal.Footer>
    )

    if (this.isChanged()) {
      footer = (
        <Modal.Footer>
          <Button variant="danger" onClick={this.deleteConfig}>
            Delete
          </Button>
          <Button variant="success" onClick={this.saveConfig}>
            Save
          </Button>
        </Modal.Footer>
      )
    }

    const isView = this.props.text.includes('View')
    if (isView) {
      footer = null
    }

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                City Code<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('CITY_CODE')}
                  type="CITY_CODE"
                  id="CITY_CODE"
                  selected={[this.state.CITY_CODE]}
                  onChange={this.handleCityCode}
                  onInputChange={this.handleCityCode}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                City Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="CITY_NAME"
                  value={this.state.CITY_NAME}
                  onChange={this.handleCityName}
                  disabled={isView}
                />
              </Col>
              <Col sm="2">
                <Form.Control as="select" onChange={this.handleLangCode} value={this.state.LANGUAGE_CODE} disabled>
                  {this.getUniqueList('LANGUAGE_CODE').map(e => {
                    return (
                      <option key={this.props.database + e} value={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Country Code<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  onChange={this.handleCountryCode2}
                  value={this.state.COUNTRY_CODE2}
                  disabled={isView}
                >
                  {this.getUniqueList('COUNTRY_CODE2').map(e => {
                    return (
                      <option key={this.props.database + e} value={JSON.parse(e).COUNTRY_CODE2}>
                        {JSON.parse(e).COUNTRY_CODE2 + '/' + JSON.parse(e).COUNTRY_CODE3}
                      </option>
                    )
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Country Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="COUNTRY_NAME"
                  value={this.state.COUNTRY_NAME}
                  onChange={this.handleCountryName}
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Time Zone:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('TIME_ZONE')}
                  type="TIME_ZONE"
                  id="TIME_ZONE"
                  selected={[this.state.TIME_ZONE]}
                  onChange={this.handleTimeZone}
                  onInputChange={this.handleTimeZone}
                  emptyLabel={false}
                  highlightOnlyResult
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Daylight Saving:
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  type="switch"
                  id="custom-switch4"
                  label=""
                  checked={this.state.DAYLIGHT_SAVING === 'Y'}
                  onClick={this.handleDaylightSaving}
                  disabled={isView}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Daylight Saving Minutes:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="DAYLIGHT_SAVING_MINUTES"
                  value={this.state.DAYLIGHT_SAVING_MINUTES}
                  onChange={this.handleDaylightSavingMin}
                  disabled={isView}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {footer}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    cities: state.loadData.cities,
    countries: state.loadData.countries,
    editCity: state.manageModal.editCity,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveCity, deleteCity, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCityMenu)
