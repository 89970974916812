import { combineReducers } from 'redux'
import loadData from './loadDataReducer'
import filterData from './filterDataReducer'
import changeTable from './changeTableReducer'
import manageModal from './manageModalReducer'
import editConfigAccess from './editConfigAccessReducer'
import accountLogin from './loginInfoReducer'
import postData from './postDataReducer'
import updateResponses from './responseReducer'

// export default combineReducers({
//   loadData,
//   filterData,
//   changeTable,
//   manageModal,
//   editConfigAccess,
//   accountLogin,
//   postData,
//   updateResponses
// })

const appReducer = combineReducers({
  loadData,
  filterData,
  changeTable,
  manageModal,
  editConfigAccess,
  accountLogin,
  postData,
  updateResponses
})

const DESTROY_SESSION = 'DESTROY_SESSION'

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if(action.type === DESTROY_SESSION)
    state = undefined;

  return appReducer(state, action)
}

export default rootReducer
