import {GRANT_ACCESS, REMOVE_ACCESS, COMMIT_GRANT_ACCESS, CLEAR_ACCESS, COMMIT_REMOVE_ACCESS } from "../constants/action-types";

export function grantAccess(payload){
    return { type: GRANT_ACCESS, payload}
}

export function removeAccess(payload){
    return { type: REMOVE_ACCESS, payload}
}

export function commitGrantAccess(payload){
    return { type: COMMIT_GRANT_ACCESS, payload}
}

export function commitRemoveAccess(payload){
    return { type: COMMIT_REMOVE_ACCESS, payload}
}

export function clearAccess(){
    return { type: CLEAR_ACCESS}
}
