import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { postProperty } from 'redux/actions/postData'
import { closeModal } from 'redux/actions/manageModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalHeader from 'react-bootstrap/ModalHeader'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typeahead } from 'react-bootstrap-typeahead'

class PropertiesMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      SECTION_NAME: '',
      PROPERTY_NAME: '',
      PROPERTY_VALUE: '',
      CLIENT_TYPE: ''
    }
    this.baseState = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)
  }

  handleSectionName = event => {
    let value = this.state.SECTION_NAME
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        SECTION_NAME: value
      })
    }
  }

  handlePropertyName = event => {
    let value = this.state.PROPERTY_NAME
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }

    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        PROPERTY_NAME: value
      })
    }
  }

  handlePropertyValue = event => {
    const value = event.target.value
    console.log(value)
    this.setState({
      PROPERTY_VALUE: value
    })
  }

  handleClientType = event => {
    let value = this.state.SECTION_NAME
    if (Array.isArray(event) && event.length > 0) {
      value = event[0]
    } else if (!Array.isArray(event)) {
      value = event
    }
    if ((Array.isArray(event) && event.length > 0) || !Array.isArray(event)) {
      console.log(value)
      this.setState({
        CLIENT_TYPE: value
      })
    }
  }

  addConfig = () => {
    const req = { ...this.state, isNewPropertiesTable: this.props.isNewPropertiesTable }
    console.log(req)
    this.props.postProperty(req)
    console.log('PROPERTY ADDED')
    this.resetForm()
    this.props.closeModal()
  }

  configExists = () => {
    for (let config of this.props.properties) {
      if (this.props.isNewPropertiesTable) {
        if (
          config.SECTION_NAME === this.state.SECTION_NAME &&
          config.PROPERTY_NAME === this.state.PROPERTY_NAME &&
          config.CLIENT_TYPE === this.state.CLIENT_TYPE
        )
          return true
      } else {
        if (config.SECTION_NAME === this.state.SECTION_NAME && config.PROPERTY_NAME === this.state.PROPERTY_NAME)
          return true
      }
    }
    return false
  }

  getUniqueList = category => {
    let list = []
    if (category === 'SECTION_NAME') {
      list = this.props.properties.map(elem => {
        return elem.SECTION_NAME
      })
    }
    if (category === 'PROPERTY_NAME') {
      list = this.props.properties.map(elem => {
        return elem.PROPERTY_NAME
      })
    }
    if (category === 'CLIENT_TYPE') {
      list = this.props.properties.map(elem => {
        return elem.CLIENT_TYPE
      })
    }
    list = list.filter(e => e !== '' && !!e).sort()
    const listUnique = [...new Set(list)]

    return listUnique
  }

  render() {
    let isAdd = this.state.SECTION_NAME.length > 0 && this.state.PROPERTY_NAME.length > 0 && !this.configExists()
    if (this.props.isNewPropertiesTable) isAdd = isAdd && this.state.CLIENT_TYPE.length > 0

    return (
      <Modal {...this.props} size="lg">
        <ModalHeader closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.text}</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Section Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('SECTION_NAME')}
                  type="SECTION_NAME"
                  id="SECTION_NAME"
                  selected={[this.state.SECTION_NAME]}
                  value={this.state.SECTION_NAME}
                  onChange={this.handleSectionName}
                  onInputChange={this.handleSectionName}
                  emptyLabel={false}
                  highlightOnlyResult
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Property Name<p className="asterisk"> * </p>:
              </Form.Label>
              <Col sm="8">
                <Typeahead
                  options={this.getUniqueList('PROPERTY_NAME')}
                  type="PROPERTY_NAME"
                  id="PROPERTY_NAME"
                  selected={[this.state.PROPERTY_NAME]}
                  value={this.state.PROPERTY_NAME}
                  onChange={this.handlePropertyName}
                  onInputChange={this.handlePropertyName}
                  emptyLabel={false}
                  highlightOnlyResult
                />
              </Col>
            </Form.Group>

            {this.props.isNewPropertiesTable && (
              <Form.Group as={Row} controlId="formPlaintextScope">
                <Form.Label column sm="4">
                  Client Type<p className="asterisk"> * </p>:
                </Form.Label>
                <Col sm="8">
                  <Typeahead
                    options={this.getUniqueList('CLIENT_TYPE')}
                    type="CLIENT_TYPE"
                    id="CLIENT_TYPE"
                    selected={[this.state.CLIENT_TYPE]}
                    onChange={this.handleClientType}
                    onInputChange={this.handleClientType}
                    emptyLabel={false}
                    highlightOnlyResult
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} controlId="formPlaintextScope">
              <Form.Label column sm="4">
                Property Value:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="PROPERTY_VALUE"
                  value={this.state.PROPERTY_VALUE}
                  onChange={this.handlePropertyValue}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={this.addConfig} disabled={!isAdd}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    properties: state.loadData.properties,
    isAdmin: state.accountLogin.isAdmin,
    database: state.accountLogin.database,
    isNewPropertiesTable: state.loadData.isNewPropertiesTable
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postProperty, closeModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesMenu)
