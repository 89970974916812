import {
  CONFIGDATA_LOADED,
  CONFIGGROUPS_LOADED,
  CONFIGSCOPES_LOADED,
  STATE_DEF_LOADED,
  STATE_DESC_LOADED,
  PROPERTIES_LOADED,
  PROFILES_LOADED,
  USERS_LOADED,
  ACCESS_KEYS_LOADED,
  CITIES_LOADED,
  COUNTRIES_LOADED,
  COUNTRY_INDEXES_LOADED,
  RESET_ERROR,
  SET_ERROR
} from '../constants/action-types'

const initialState = {
  configs: [],
  groups: [],
  scopes: [],
  stateDef: [],
  stateDesc: [],
  properties: [],
  profiles: [],
  countries: [],
  countryIndexes: [],
  cities: [],
  users: [],
  accessKeys: [],
  isNewPropertiesTable: false,
  error: null
}

function loadData(state = initialState, action) {
  if (!!action.payload) {
    if (!!action.payload.error) {
      console.log('Error:', action.payload.error.message)
      return Object.assign({}, state, {
        error: action.payload.error
      })
    } else {
      if (action.type === CONFIGDATA_LOADED) {
        action.payload.map(element => {
          if (Array.isArray(element.SCOPE_NAME)) {
            element.SCOPE_NAME = element.SCOPE_NAME[0]
          }
          return element
        })
        return Object.assign({}, state, {
          configs: action.payload
        })
      }
      if (action.type === CONFIGGROUPS_LOADED) {
        return Object.assign({}, state, {
          groups: action.payload
        })
      }
      if (action.type === CONFIGSCOPES_LOADED) {
        return Object.assign({}, state, {
          scopes: action.payload
        })
      }
      if (action.type === STATE_DEF_LOADED) {
        return Object.assign({}, state, {
          stateDef: action.payload
        })
      }
      if (action.type === STATE_DESC_LOADED) {
        return Object.assign({}, state, {
          stateDesc: action.payload
        })
      }
      if (action.type === PROPERTIES_LOADED) {
        let isNewPropertiesTable = false
        if (action.payload.length > 0) {
          isNewPropertiesTable = !!action.payload[0].CLIENT_TYPE
        }
        return Object.assign({}, state, {
          properties: action.payload,
          isNewPropertiesTable: isNewPropertiesTable
        })
      }
      if (action.type === PROFILES_LOADED) {
        return Object.assign({}, state, {
          profiles: action.payload
        })
      }
      if (action.type === USERS_LOADED) {
        action.payload.map(element => {
          if (Array.isArray(element.PROFILE_ID)) {
            element.PROFILE_ID = element.PROFILE_ID[0]
          }
          return element
        })
        return Object.assign({}, state, {
          users: action.payload
        })
      }
      if (action.type === ACCESS_KEYS_LOADED) {
        action.payload.map(element => {
          if (Array.isArray(element.GROUP_ID)) {
            element.GROUP_ID = element.GROUP_ID[0]
          }
          return element
        })
        return Object.assign({}, state, {
          accessKeys: action.payload
        })
      }
      if (action.type === CITIES_LOADED) {
        action.payload.map(element => {
          if (Array.isArray(element.CITY_CODE)) {
            element.CITY_CODE = element.CITY_CODE[0]
          }
          return element
        })
        return Object.assign({}, state, {
          cities: action.payload
        })
      }
      if (action.type === COUNTRIES_LOADED) {
        action.payload.map(element => {
          if (Array.isArray(element.COUNTRY_CODE2)) {
            element.COUNTRY_CODE2 = element.COUNTRY_CODE2[0]
          }
          return element
        })
        return Object.assign({}, state, {
          countries: action.payload
        })
      }
      if (action.type === COUNTRY_INDEXES_LOADED) {
        return Object.assign({}, state, {
          countryIndexes: action.payload
        })
      }
    }
  }

  if (action.type === RESET_ERROR) {
    return Object.assign({}, state, {
      error: null
    })
  }

  if (action.type === SET_ERROR) {
    return Object.assign({}, state, {
      error: action.payload
    })
  }

  return state
}

export default loadData
