import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import CityTable from './CityTable'
import CountryTable from './CountryTable'
import CountryIndexTable from './CountryIndexTable'
import { changeCategory } from 'redux/actions/changeTable'

class RegionalTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      regionalTableKey: 'CityTab'
    }
  }

  handleTabClick = event => {
    const key = event.toString()
    console.log(key)
    if (key === 'CityTab')
      this.props.changeCategory([
        'City Name',
        'City Code',
        'Country Name',
        'Country Code 2',
        'Country Code 3',
        'Time Zone',
        'Daylight Saving',
        'Daylight Saving Minutes',
        'Language Code',
        'Any'
      ])
    else if (key === 'CountryTab')
      this.props.changeCategory([
        'Country Name',
        'Country Code 2',
        'Country Code 3',
        'Country Number',
        'Language Code',
        'Any'
      ])
    else if (key === 'CountryIndexTab') this.props.changeCategory(['Country Code 2', 'Country Index', 'Any'])
    this.setState({
      regionalTableKey: key
    })
  }

  render() {
    return (
      <div className="Table">
        <Tabs id="regionalTabs" activeKey={this.state.regionalTableKey} onSelect={k => this.handleTabClick(k)}>
          <Tab key={this.props.database + 'CityTab'} eventKey={'CityTab'} title={'Cities'}>
            <CityTable />
          </Tab>
          <Tab key={this.props.database + 'CountryTab'} eventKey={'CountryTab'} title={'Countries'}>
            <CountryTable />
          </Tab>
          <Tab key={this.props.database + 'CountryIndexTab'} eventKey={'CountryIndexTab'} title={'Country Index'}>
            <CountryIndexTable />
          </Tab>
        </Tabs>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeCategory }, dispatch)
}
export default connect(
  null,
  mapDispatchToProps
)(RegionalTable)
