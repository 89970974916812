import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import AES from "crypto-js/aes"
import { closeModal } from "redux/actions/manageModal"
import { login, isAdmin } from "redux/actions/loginInfo"
import authentication from "../../services/authentication"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

var CryptoJS = require("crypto-js")

const ERROR_DB_UNDEFINED = "Database is undefined"

class LoginMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      USER_ID: localStorage.getItem("userId"),
      PASSWORD: "",
      submitClicked: false,
      error: ERROR_DB_UNDEFINED,
    }
  }

  handleUserID = (event) => {
    console.log(event.currentTarget.id)
    const userId = event.target.value
    this.setState({
      USER_ID: userId,
    })
  }

  handlePassword = (event) => {
    const password = event.target.value
    this.setState({
      PASSWORD: password,
    })
  }

  handleKeyUp = (event) => {
    if (event.key === "Enter") {
      this.submitLogin().then((res) => {
        console.log(res)
      })
    }
  }

  handleOnChange = (event) => {
    console.log(event.currentTarget.value)
    this.props.history.push(event.currentTarget.value)
  }

  submitLogin = async () => {
    localStorage.removeItem("userId")
    localStorage.setItem("userId", this.state.USER_ID)
    this.setState({ submitClicked: true })
    const { signIn } = authentication()
    const req = {
      username: this.state.USER_ID,
      password: this.state.PASSWORD,
      ...this.props.database,
    }

    const valid = await signIn(req)
    let user = valid.userRecord
    if (user) {
      if (Array.isArray(user.PROFILE_ID)) {
        user.PROFILE_ID = user.PROFILE_ID[0]
      }
      if (user.USER_ADMIN === "true") {
        this.props.isAdmin()
      }
      this.props.login({ PROFILE_ID: user.PROFILE_ID, USER_ID: user })
    } else {
      if (valid.error)
        this.setState({
          PASSWORD: "",
          submitClicked: false,
          error: valid.error.message,
        })
      else
        this.setState({
          submitClicked: false,
          error: "Failed to Fetch. Please check connection to server.",
        })
    }
  }

  // onHidePopup = () => {
  //   console.log()
  // }

  componentDidMount() {
    if (this.props.users.length !== 0 && this.state.submitClicked) {
      this.props.closeModal()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.users.length !== 0 && this.state.submitClicked) {
      this.props.closeModal()
    }
    // Set Database undefined error message
    if (
      this.props.database.database &&
      this.props.database.sql &&
      this.state.error === ERROR_DB_UNDEFINED
    ) {
      this.setState({ error: null })
    }
  }

  render() {
    const modalFooter = (
      <Modal.Footer
        className={this.state.error ? "justify-content-between" : ""}
      >
        {this.state.error ? <p className="asterisk">{this.state.error}</p> : ""}
        {this.props.users.length === 0 && this.state.submitClicked ? (
          <Button variant="dark" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        ) : this.props.database.database && this.props.database.sql ? (
          <Button variant="dark" type="submit" onClick={this.submitLogin}>
            Submit
          </Button>
        ) : (
          <Button variant="dark" disabled>
            Submit
          </Button>
        )}
      </Modal.Footer>
    )

    const DBList = []
    const currentDB = window.location.pathname.split("/").pop()
    window.config.alias.forEach((data, index) => {
      DBList.push(
        <option key={index} value={data.alias}>
          {data.alias + " (" + data.database + ")"}
        </option>
      )
    })

    const DBSelectionList = (
      <Form.Group as={Row} controlId="formPlaintextDBName">
        <Form.Label column sm="2">
          Select a DB:
        </Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            onChange={this.handleOnChange}
            value={currentDB}
          >
            <option value="">Select a Database...</option>
            {DBList}
          </Form.Control>
        </Col>
      </Form.Group>
    )

    return (
      <Modal show={this.props.show} onHide={() => {}} size="lg">
        <Modal.Header>
          <Modal.Title>{this.props.text}</Modal.Title>
        </Modal.Header>
        <Modal.Body size="sm">
          <Form onSubmit={this.submitLogin}>
            {window.config.enableDBSelectionOnLogin ? DBSelectionList : ""}
            <Form.Group as={Row} controlId="formPlaintextUserId">
              <Form.Label column sm="2">
                User ID:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  value={this.state.USER_ID}
                  onChange={this.handleUserID}
                  onKeyUp={this.handleKeyUp}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Form.Label column sm="2">
                Password:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="PASSWORD"
                  value={this.state.PASSWORD}
                  onChange={this.handlePassword}
                  onKeyUp={this.handleKeyUp}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        {modalFooter}
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.loadData.users,
    database: state.accountLogin.database,
    currentModal: state.manageModal.currentModal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal, login, isAdmin }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginMenu)
