import { CHANGE_TABLE, CHANGE_CATEGORY } from "../constants/action-types";

const initialState = {
    tableName: 'Scope Configurations',
    configTableShow: true,
    stateDefTableShow: false,
    regionalTableShow:false, 
    propertiesTableShow: false,
    categories: ['Config Key', 'Scope Name', 'Scope Type', 'Value', 'Description', 'Any']
 
};

function changeTable (state = initialState, action) {
    if (action.type === CHANGE_TABLE) {
        if (action.payload === 'Scope Configurations')
            return {
                ...state,
                tableName: action.payload, 
                configTableShow: true,
                stateDefTableShow: false,
                regionalTableShow:false,
                propertiesTableShow: false,
                categories: ['Config Key', 'Scope Name', 'Scope Type', 'Value', 'Description', 'Any']   
            };
        else if (action.payload === 'State Configurations') {
            return {
                ...state,
                tableName: action.payload, 
                configTableShow: false,
                stateDefTableShow: true,
                regionalTableShow: false,
                propertiesTableShow: false,
                categories: ['State ID', 'Business Reference Number', 'Is Fatal', 'KAC Required', 'Stamping Required', 'Use For Rep', 'Rep Category', 'State Category', 'Any']
            };
        } 
        else if (action.payload === 'Regions') {
            return {
                ...state,
                tableName: action.payload, 
                configTableShow: false,
                stateDefTableShow: false,
                regionalTableShow: true,
                propertiesTableShow: false,
                categories: ['City Name', 'City Code', 'Country Name', 'Country Code 2', 'Country Code 3', 'Country Number', 'Time Zone', 'Daylight Saving', 'Daylight Saving Minutes', 'Language Code', 'Any']
            };
        }
        else if (action.payload === 'Properties') {
            return {
                ...state,
                tableName: action.payload, 
                configTableShow: false,
                stateDefTableShow: false,
                regionalTableShow: false,
                propertiesTableShow: true,
                categories: ['Section Name', 'Property Name', 'Property Value', 'Any']  
            };
        } 
    }
    if (action.type === CHANGE_CATEGORY) {
        return {
            ...state,
            categories: action.payload
        }
    }

    return state;
};

export default changeTable;
