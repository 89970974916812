import ReactJson from 'react-json-view'
import React from 'react'

export function isEmpty(obj) {
  if(obj === 0) return false
  return (
    obj === undefined ||
    obj === null ||
    obj === '' ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0)
  )
}

export function isNotEmpty(obj) {
  return !isEmpty(obj)
}

export function jsonViewer(data) {
  const isValidJson = validateJson(data)
  let view = ''
  if (isValidJson) {
    view = (<ReactJson src={JSON.parse(data)} collapsed={true} />)
  }
  return { view, isValidJson }
}

export function validateJson(value) {
  try {
    const jsonData = JSON.parse(value)
    if (typeof jsonData === 'boolean' || typeof jsonData === 'number') {
      return false
    } else {
      return true
    }
  } catch (e) {
    return false
  }
}
